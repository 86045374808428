import React, {useState} from 'react';
import {intl} from 'i18n';
import messages from './displayMessages';
import {CareerSearchBar} from '../CareerSearchBar';
import OnboardingPageLayout from './OnboardingPageLayout/OnboardingPageLayout';
import CollapsibleComponent, { CollapsibleSize } from '../CareerCollapsible/CollapsibleComponent';
import { IconSymbol } from '../../../elements';
import CareerTag from '../CareerTag';
import { CareerSubject, CareerSubjectArea } from 'labxchange-client';

export const subjectAreaIconsMapper: Record<string, IconSymbol> = {
    'Business': 'subject-business',
    'Computer science': 'subject-computer-science',
    'English': 'subject-english',
    'Family and Consumer Science': 'subject-family',
    'Foreign Language': 'subject-foreign-language',
    'Math': 'subject-maths',
    'Performing Arts': 'subject-performing-arts',
    'Physical Education': 'subject-physical-education',
    'Science':'subject-science',
    'Social Studies': 'subject-social-studies',
    'Visual Arts': 'subject-visual-arts',
    'Vocational Education':'subject-vocational-education'
};

export interface SubjectsStep {
    currentStep: number;
    subjectAreas?: CareerSubjectArea[];
    selectedSubjects: CareerSubject[];
    onButtonClick: (nextStep: number) => void;
    toggleSubjectSelection: (subject: CareerSubject) => void;
}

export const SubjectsStep: React.FC<SubjectsStep> = ({
    currentStep, subjectAreas=[], selectedSubjects, onButtonClick, toggleSubjectSelection
}) => {
    const [searchQuery, setSearchQuery] = useState<string>('');

    const searchSubjects = (query: string) => {
        setSearchQuery(query);
    };

    const filteredSubjects = (subjects: CareerSubject[]) => {
        if (!searchQuery) return subjects;
        return subjects.filter((subject) =>
            subject.name.toLowerCase().includes(searchQuery.toLowerCase())
        );
    };

    return (
        <OnboardingPageLayout
            title={intl.formatMessage(messages.careerSubjectStepTitle)}
            description={intl.formatMessage(messages.careerSubjectStepDescription)}
            currentStep={currentStep}
            onButtonClick={onButtonClick}
            showContinueAction={selectedSubjects.length !== 0}
        >
            <CareerSearchBar onSubmit={searchSubjects} viewType='other' />
            <div className='subject-area-container'>
                <div className='selected-subjects'>
                    {selectedSubjects.map((subject: CareerSubject) => (
                        <div key={subject.id} className='selected-subject'>
                            {subject.name}
                            <button onClick={() => toggleSubjectSelection(subject)}>&times;</button>
                        </div>
                    ))}
                </div>

                <div className='subject-sections'>
                    {subjectAreas.map((area) => (
                        <CollapsibleComponent
                            key={area.id}
                            title={area.name}
                            headerIcon={subjectAreaIconsMapper[area.name]}
                            size={CollapsibleSize.large}
                        >
                            <div className='subject-section__list'>
                                {area.subjects && filteredSubjects(area.subjects).map(
                                    (subject) => (
                                        <CareerTag
                                            key={subject.id}
                                            cssClass={`subject-section__list__item ${
                                                selectedSubjects.includes(subject)
                                                    ? 'selected'
                                                    : ''
                                            }`}
                                            onClick={() => toggleSubjectSelection(subject)}
                                        >
                                            {subject.name}
                                        </CareerTag>
                                    )
                                )}
                            </div>
                        </CollapsibleComponent>
                    ))}
                </div>
            </div>
        </OnboardingPageLayout>
    );
};

export default SubjectsStep;
