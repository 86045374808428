import React from 'react';
import { MessageDescriptor } from 'react-intl';
import messages from '../displayMessages';
import { WrappedMessage } from '../../../../utils';
import { CareerStandardLayout } from '../../CareerStandardLayout';
import Banner from '../../Banner/Banner';
import { ROUTES } from 'global/constants';
import { useHistory } from 'react-router-dom';

export interface OnboardingPageLayoutProps {
    title: string;
    description: string;
    pageTitle?: MessageDescriptor;
    children?: React.ReactNode;
    currentStep: number;
    showContinueAction?: boolean;
    onButtonClick: (nextStep: number) => void;
    onSubmit?: () => void;
}

export const OnboardingPageLayout: React.FC<OnboardingPageLayoutProps> = ({
 title,
 description,
 children,
 currentStep,
 showContinueAction,
 onButtonClick,
 onSubmit,
}) => {
    const totalSteps = 5;
    const history = useHistory();

    const onClickSkipSetup = () => {
        history.push(ROUTES.CareerExplorer.HOME);
    };

    const handleNextStep = () => {
        const nextStep = currentStep + 1;
        if (nextStep <= totalSteps)
            onButtonClick(nextStep);
    };

    const getStepperButton = () => {
        if (onSubmit !== undefined) {
            return (
                <button
                    className='skip-step-button'
                    onClick={onSubmit}
                >
                    <WrappedMessage message={messages.submitButtonLabel} />
                </button>
            );
        }

        if (showContinueAction) {
            return (
                <button
                    className='skip-step-button'
                    onClick={handleNextStep}
                >
                    <WrappedMessage message={messages.continueSetupButtonLabel} />
                </button>
            );
        }

        return (
            <button
                className='skip-step-button'
                onClick={handleNextStep}
            >
                <WrappedMessage message={messages.skipStepButtonLabel} />
            </button>
        );
    };

    return (
        <CareerStandardLayout
            mainClassName='career-onboarding-page'
            pageTitle={messages.onboardingPageTitle}
            headerComponent={
                <Banner
                    title={title}
                    description={description}
                    showStepper={true}
                    currentStep={currentStep}
                    totalSteps={totalSteps}
                />
            }
        >
            <div className='step-content'>
                {children}
            </div>

            <div className='action-buttons-section'>
                <button
                    className='skip-setup-button'
                    onClick={onClickSkipSetup}
                >
                    <WrappedMessage message={messages.skipSetupButtonLabel} />
                </button>
                {getStepperButton()}
            </div>
        </CareerStandardLayout>
    );
};

export default OnboardingPageLayout;
