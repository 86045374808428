import React from 'react';
import { intl } from 'i18n';

import messages from '../../displayMessages';

export const EmptySearchResults = () => {
  return (
    <div className='curriculum-empty-results'>
      <img src='/assets/images/magnifier.svg' alt={intl.formatMessage(messages.curriculumEmptySearchResultsImageAlt)} />
      <h2 className='empty-results-heading'>{intl.formatMessage(messages.curriculumEmptySearchResultsHeading)}</h2>
      <p className='empty-results-subheading'>{intl.formatMessage(messages.curriculumEmptySearchResultsSubHeading)}</p>
    </div>
  );
};
