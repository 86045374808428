import * as React from 'react';
import { useSelector } from 'react-redux';
import { intl } from 'i18n';
import classNames from 'classnames';

import { AssetFeedbacksApi } from 'global/api';
import { Icon, Modal } from 'elements';
import { Button, Spinner, Tag, TextBox } from 'ui/components';
import { WrappedMessage } from 'utils';
import { getUserEmail } from 'auth/selectors';
import messages from '../displayMessages';

export enum IS_CONTENT_USEFUL_MODULE_PAGE {
  YES = 'Yes',
  SOMEWHAT = 'Somewhat',
  NO = 'No',
}

export const WHY_TAGS_MODULE_PAGE = [
  'Extension Learning', 'Requires Prerequisite Knowledge', 'Covers part of the Core Concept',
  'Links to other subjects', 'Requires Additional Context', 'Supplementary Resource',
  'Supports Application', 'Summary', 'Other',
];

interface FeedbackModalModulePageProps {
  assetId: string;
  curriculumSlug: string;
  module: number;
  onClose: () => void;
  onSubmit: () => void;
  coreConceptsHeading?: string;
}

export const FeedbackModalModulePage: React.FC<FeedbackModalModulePageProps> = ({
  assetId, onClose, onSubmit, curriculumSlug, module,
  coreConceptsHeading = intl.formatMessage(messages.feedbackModalCoreConceptsLabel),
}) => {
  const [isContentUseful, setIsContentUseful] = React.useState<string>('');
  const [loading, setLoading] = React.useState<boolean>(false);
  const userEmail = useSelector(getUserEmail);
  const [selectedTags, setSelectedTags] = React.useState<string[]>([]);
  const [tellUsMoreFeedback, setTellUsMoreFeedback] = React.useState<string>('');
  const otherTagSelected = selectedTags.includes('Other');

  let submitDisabled = !isContentUseful;
  if (isContentUseful === IS_CONTENT_USEFUL_MODULE_PAGE.SOMEWHAT) {
    submitDisabled = !selectedTags.length || (otherTagSelected && !tellUsMoreFeedback);
  }

  const registerFeedback = async () => {
    const response = await AssetFeedbacksApi.feedbacksCreate({
      data: {
        asset: assetId,
        curriculum: curriculumSlug,
        userEmail,
        isContentUseful: isContentUseful === IS_CONTENT_USEFUL_MODULE_PAGE.YES,
        modules: [module],
        tags: selectedTags,
        tellUsMoreFeedback,
        isModulePageFeedback: true,
      },
    });
    return response;
  };

  const onClickSubmitButton = async () => {
    setLoading(true);
    await registerFeedback();
    setLoading(false);
    onSubmit();
    onClose();
  };

  const clearState = () => {
    setSelectedTags([]);
    setTellUsMoreFeedback('');
  };

  const YesNoContentFeedbackBody = () => {
    return (
      <>
        <div className='fb-mdl-section'>
          <span className='font-xxs-lt'>
            <WrappedMessage message={messages.feedbackModalPleaseTellUsMorOptionalLabel} />
          </span>
          <div className='please-tell-us-more-opt'>
            <TextBox
              value={tellUsMoreFeedback}
              label={
                  <WrappedMessage
                      message={messages.feedbackModalPleaseTellUsMoreLabel}
                  />
              }
              hideLabel={true}
              onChange={(currentValue) => setTellUsMoreFeedback(currentValue)}
              placeholder={intl.formatMessage(messages.feedbackModalPleaseTellUsMoreLabel)}
            />
          </div>
        </div>
      </>
    );
  };

  const toggleTag = (tag: string) => {
    setSelectedTags(prevSelectedTags => {
      if (prevSelectedTags.includes(tag)) {
        return prevSelectedTags.filter(t => t !== tag);
      } else {
        return [...prevSelectedTags, tag];
      }
    });
  };

  const SomewhatUsefulContentFeedbackBody = () => {
    return (
      <div className='fb-mdl-section'>
        <div className='why-title'>
          <span className='font-xxs-lt'>
            {intl.formatMessage(messages.feedbackModalWhy)}
          </span>
          <span className='why-placeholder'>
            {intl.formatMessage(messages.feedbackModalWhyPlaceholder)}
          </span>
        </div>
        <div className='why-tags'>
          {WHY_TAGS_MODULE_PAGE.map((tag: string) => (
            <Tag
              key={tag}
              onClick={() => toggleTag(tag)}
              disableIcon={true}
              active={selectedTags.includes(tag)}
            >
              {tag}
            </Tag>
          ))}
        </div>
        {otherTagSelected && (
          <div className='please-tell-us-more-useful'>
            <TextBox
              value={tellUsMoreFeedback}
              label={
                  <WrappedMessage
                      message={messages.feedbackModalPleaseTellUsMoreLabel}
                  />
              }
              hideLabel={true}
              onChange={(currentValue) => setTellUsMoreFeedback(currentValue)}
              placeholder={intl.formatMessage(messages.feedbackModalPleaseTellUsMoreLabel)}
            />
          </div>
        )}
      </div>
    );
  };

  const modalContent = (
    <div className='feedback-modal-body'>
      <h3 className='fb-mdl-title font-m-lt'>
        {intl.formatMessage(messages.feedbackModalTitle)}
      </h3>
      {loading ? <div className='spinner-wrapper'><Spinner /></div> : (
        <>
          <div className='form'>
            <div className='fb-mdl-section'>
              <span className='font-xxs-lt'>
                <WrappedMessage
                  message={messages.feedbackModalIsContentUsefulToCoreConceptsQuestion}
                  values={{ coreConceptsHeading }}
                />
              </span>
              <div className='fb-mdl-buttons'>
                <button
                  className={classNames('fb-mdl-btn',
                    { 'is-active': isContentUseful === IS_CONTENT_USEFUL_MODULE_PAGE.YES },
                  )}
                  aria-label={intl.formatMessage(messages.feedbackModalYesBtnLabel)}
                  onClick={() => {setIsContentUseful(IS_CONTENT_USEFUL_MODULE_PAGE.YES); clearState();}}
                >
                  <Icon name='thumbs-up' />
                  <span>{intl.formatMessage(messages.feedbackModalYesBtnLabel)}</span>
                </button>
                <button
                  className={classNames('fb-mdl-btn somewhat-button',
                    { 'is-active': isContentUseful === IS_CONTENT_USEFUL_MODULE_PAGE.SOMEWHAT },
                  )}
                  aria-label={intl.formatMessage(messages.feedbackModalSomewhatBtnLabel)}
                  onClick={() => {setIsContentUseful(IS_CONTENT_USEFUL_MODULE_PAGE.SOMEWHAT); clearState();}}
                >
                  {/* <Icon name='thumbs-up' /> */}
                  <span>{intl.formatMessage(messages.feedbackModalSomewhatBtnLabel)}</span>
                </button>
                <button
                  className={classNames('fb-mdl-btn',
                    { 'is-active': isContentUseful === IS_CONTENT_USEFUL_MODULE_PAGE.NO },
                  )}
                  aria-label={intl.formatMessage(messages.feedbackModalNoBtnLabel)}
                  onClick={() => {setIsContentUseful(IS_CONTENT_USEFUL_MODULE_PAGE.NO); clearState();}}
                >
                  <Icon name='thumbs-down' />
                  <span>{intl.formatMessage(messages.feedbackModalNoBtnLabel)}</span>
                </button>
              </div>
            </div>
            {(isContentUseful === IS_CONTENT_USEFUL_MODULE_PAGE.YES || isContentUseful === IS_CONTENT_USEFUL_MODULE_PAGE.NO) && YesNoContentFeedbackBody()}
            {isContentUseful === IS_CONTENT_USEFUL_MODULE_PAGE.SOMEWHAT && SomewhatUsefulContentFeedbackBody()}
          </div>
          <div className='fb-mdl-footer'>
            <Button
              className='fb-mdl-submit-btn'
              btnStyle='primary'
              aria-label={messages.feedbackModalSubmitBtnLabel}
              label={messages.feedbackModalSubmitBtnLabel}
              disabled={submitDisabled || loading}
              onClick={onClickSubmitButton}
            />
          </div>
        </>
      )}
    </div>
  );

  return (
    <Modal
      className='feedback-modal'
      size='normal'
      topBarStyle='white'
      showBackButton={false}
      onRequestClose={onClose}
      showTopBar={true}
      content={modalContent}
    />
  );
};

export default FeedbackModalModulePage;
