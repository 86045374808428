import { defineMessages } from 'react-intl';

const messages = defineMessages({
    pageTitle: {
        id:'pageTitleUserProfile',
        defaultMessage: 'User {name}',
        description: 'HTML title for the user profile page.',
    },
    errorSavingProfileChanges: {
        id:'errorSavingProfileChanges',
        defaultMessage: 'The change to your profile could not be saved. Please try again later.',
        description: 'Error message shown when the system cannot make changes to a user\'s profile.',
    },
    errorSavingAvailableToMentor: {
        id:'errorSavingAvailableToMentor',
        defaultMessage: 'The change to your mentorship availability count not be saved. Please try again later.',
        description: 'Error message shown when the system cannot make changes to a user\'s mentorship availability.',
    },
    userProfileNotFound: {
        id:'userProfileNotFound',
        defaultMessage: 'Could not find the user profile',
        description: 'Text when the user profile is not found',
    },
    userProfileVisible: {
        id:'userProfileVisible',
        defaultMessage: 'Only visible to logged in users',
        description: 'Text for user profile visibility setting',
    },
    messagesEnabled: {
        id:'messagesEnabled',
        defaultMessage: 'Other users can message me',
        description: 'Text for direct messages enabled/disabled setting',
    },
    userBioAbout: {
        id:'userBioAbout',
        defaultMessage: 'About {name}',
        description: 'Displayed on user profile bio section',
    },
    userInterests: {
        id:'userInterests',
        defaultMessage: 'Interests',
        description: 'Displayed on user interests section',
    },
    userSubjects: {
        id:'userSubjects',
        defaultMessage: 'Subjects',
        description: 'Displayed on user interests section',
    },
    userAreaOfStudy: {
        id:'userAreaOfStudy',
        defaultMessage: 'Areas of study',
        description: 'Displayed on user interests section',
    },
    userExperience: {
        id:'userExperience',
        defaultMessage: 'Experience',
        description: 'Displayed on user experience section',
    },
    experienceVisible: {
        id:'experienceVisible',
        defaultMessage: 'Visible to others',
        description: 'Text for experience visibility setting',
    },
    experienceVisibleAria: {
        id:'experienceVisibleAria',
        defaultMessage: 'Experience visible to others',
        description: 'Aria label for experience visibility setting',
    },
    userSubjectsAdd: {
        id:'userSubjectsAdd',
        defaultMessage: 'Add subjects',
        description: 'Label for the subjects area select box.',
    },
    userInterestsAdd: {
        id:'userInterestsAdd',
        defaultMessage: 'Add interests',
        description: 'Label for the interests area select box.',
    },
    userEnterKeywords: {
        id:'userEnterKeywords',
        defaultMessage: 'Enter keywords',
        description: 'Label for the interests area select box.',
    },
    separateWithCommas: {
        id:'separateWithCommas',
        defaultMessage: 'Separate with commas',
        description: 'Help text for interests area.',
    },
    interestsVisible: {
        id:'interestsVisible',
        defaultMessage: 'Visible to others',
        description: 'Text for interests visibility setting',
    },
    interestsVisibleAria: {
        id:'interestsVisibleAria',
        defaultMessage: 'Interests visible to others',
        description: 'Aria label for interests visibility setting',
    },
    userProfession: {
        id:'userProfession',
        defaultMessage: 'Profession',
        description: 'Heading for user profession section',
    },
    userEducation: {
        id:'userEducation',
        defaultMessage: 'Education',
        description: 'Heading for user education section',
    },
    userAwards: {
        id:'userAwards',
        defaultMessage: 'Awards',
        description: 'Heading for user awards section',
    },
    userDetails: {
        id:'userDetails',
        defaultMessage: 'Details',
        description: 'Heading for user details section',
    },
    detailsVisible: {
        id:'detailsVisible',
        defaultMessage: 'Visible',
        description: 'Text for details visibility setting',
    },
    dateOfBirth: {
        id:'dateOfBirth',
        defaultMessage: 'Date of Birth',
        description: 'Displayed for date of birth field',
    },
    gender: {
        id:'gender',
        defaultMessage: 'Gender',
        description: 'Displayed for gender field',
    },
    country: {
        id:'country',
        defaultMessage: 'Country',
        description: 'Displayed for country field',
    },
    city: {
        id:'city',
        defaultMessage: 'City',
        description: 'Displayed for city field',
    },
    updateButton: {
        id:'updateButton',
        defaultMessage: 'Update',
        description: 'Displayed on update button',
    },
    cancelLink: {
        id:'cancelLink',
        defaultMessage: 'Cancel',
        description: 'Text for cancel edit link',
    },
    deleteLink: {
        id:'deleteLink',
        defaultMessage: 'Delete',
        description: 'Text for delete edit link',
    },
    professionFormTitle: {
        id:'professionFormTitle',
        defaultMessage: 'Your title*',
        description: 'Label for user profession title field',
    },
    professionFormDescription: {
        id:'professionFormDescription',
        defaultMessage: 'Description',
        description: 'Label for user profession description field',
    },
    professionFormCompany: {
        id:'professionFormCompany',
        defaultMessage: 'Company/Institution',
        description: 'Label for user profession company field',
    },
    professionFormCountry: {
        id:'professionFormCountry',
        defaultMessage: 'Country',
        description: 'Label for user profession country field',
    },
    professionFormState: {
        id:'professionFormState',
        defaultMessage: 'State/Province',
        description: 'Label for user profession state field',
    },
    professionFormStartDate: {
        id:'professionFormStartDate',
        defaultMessage: 'Start date',
        description: 'Label for user profession start date field',
    },
    professionFormEndDate: {
        id:'professionFormEndDate',
        defaultMessage: 'End date',
        description: 'Label for user profession end date field',
    },
    professionFormSaveButton: {
        id:'professionFormSaveButton',
        defaultMessage: 'Save profession',
        description: 'Text for user profession save button',
    },
    educationFormTitle: {
        id:'educationFormTitle',
        defaultMessage: 'Credential title*',
        description: 'Label for user education title field',
    },
    educationFormDescription: {
        id:'educationFormDescription',
        defaultMessage: 'Description',
        description: 'Label for user education description field',
    },
    educationFormSchool: {
        id:'educationFormSchool',
        defaultMessage: 'Institution',
        description: 'Label for user education company field',
    },
    educationFormCountry: {
        id:'educationFormCountry',
        defaultMessage: 'Country',
        description: 'Label for user education country field',
    },
    educationFormState: {
        id:'educationFormState',
        defaultMessage: 'State/Province',
        description: 'Label for user education state field',
    },
    educationFormStartDate: {
        id:'educationFormStartDate',
        defaultMessage: 'Start date',
        description: 'Label for user education start date field',
    },
    educationFormEndDate: {
        id:'educationFormEndDate',
        defaultMessage: 'End date',
        description: 'Label for user education end date field',
    },
    educationFormSaveButton: {
        id:'educationFormSaveButton',
        defaultMessage: 'Save credential',
        description: 'Text for user education save button',
    },
    awardFormTitle: {
        id:'awardFormTitle',
        defaultMessage: 'Award title*',
        description: 'Label for user award title field',
    },
    awardFormBy: {
        id:'awardFormBy',
        defaultMessage: 'Awarded by',
        description: `
            User can add their awards in the Profile. Awards can be earned certificates or Titles.
             Awarded-by can be an organization who awarded the title.
        `,
    },
    awardFormYear: {
        id:'awardFormYear',
        defaultMessage: 'Date awarded',
        description: 'Label for user award year field',
    },
    awardFormSaveButton: {
        id:'awardFormSaveButton',
        defaultMessage: 'Save award',
        description: 'Text for user award save button',
    },
    ariaEditBioButton: {
        id:'ariaEditBioButton',
        defaultMessage: 'Edit user bio button',
        description: 'Aria label for edit user bio button.',
    },
    ariaTextAreaEditBio: {
        id:'ariaTextAreaEditBio',
        defaultMessage: 'Edit user bio',
        description: 'Aria label for edit user bio text area.',
    },
    ariaEditBirthDayButton: {
        id:'ariaEditBirthDayButton',
        defaultMessage: 'Edit user birthday button',
        description: 'Aria label for edit user birtday button.',
    },
    ariaInputEditBirthday: {
        id:'ariaInputEditBirthday',
        defaultMessage: 'Edit user birthday',
        description: 'Aria label for edit user birthday input.',
    },
    ariaEditGenderButton: {
        id:'ariaEditGenderButton',
        defaultMessage: 'Edit user gender button',
        description: 'aria label for edit user gender button.',
    },
    ariaEditProfileVisibilityButton: {
        id:'ariaEditProfileVisibilityButton',
        defaultMessage: 'Edit user profile visibility button',
        description: 'aria label for edit user profile visibility button.',
    },
    ariaSelectEditGender: {
        id:'ariaSelectEditGender',
        defaultMessage: 'Edit user gender',
        description: 'Aria label for edit user gender select.',
    },
    ariaSelectEditProfileVisibility: {
        id:'ariaSelectEditProfileVisibility',
        defaultMessage: 'Edit user profile visibility',
        description: 'Aria label for edit user profile visibility select.',
    },
    ariaEditCountryButton: {
        id:'ariaEditCountryButton',
        defaultMessage: 'Edit user country button',
        description: 'Aria label for edit user country button.',
    },
    ariaSelectEditCountry: {
        id:'ariaSelectEditCountry',
        defaultMessage: 'Edit user country',
        description: 'Aria label for edit user country select.',
    },
    ariaEditCityButton: {
        id:'ariaEditCityButton',
        defaultMessage: 'Edit user city button',
        description: 'Aria label for edit user city button.',
    },
    ariaEditProfessionButton: {
        id:'ariaEditProfessionButton',
        defaultMessage: 'Edit user profession button',
        description: 'aria label for edit user profession button.',
    },
    ariaEditEducationButton: {
        id:'ariaEditEducationButton',
        defaultMessage: 'Edit user education button',
        description: 'aria label for edit user education button.',
    },
    ariaEditAwardButton: {
        id:'ariaEditAwardButton',
        defaultMessage: 'Edit user award button',
        description: 'aria label for edit user award button.',
    },
    ariaInputEditCity: {
        id:'ariaInputEditCity',
        defaultMessage: 'Edit user city',
        description: 'Aria label for edit user city input.',
    },
    ariaInputEditProfessionTitle: {
        id:'ariaInputEditProfessionTitle',
        defaultMessage: 'Enter your professional title',
        description: 'Aria label for edit user profession title.',
    },
    ariaInputEditProfessionDescription: {
        id:'ariaInputEditProfessionDescription',
        defaultMessage: 'Enter a brief description',
        description: 'Aria label for edit user profession description.',
    },
    ariaInputEditProfessionCompany: {
        id:'ariaInputEditProfessionCompany',
        defaultMessage: 'Enter company/institution name',
        description: 'Aria label for edit user profession company.',
    },
    ariaInputEditProfessionCountry: {
        id:'ariaInputEditProfessionCountry',
        defaultMessage: 'Enter country',
        description: 'Aria label for edit user profession country.',
    },
    ariaInputEditProfessionState: {
        id:'ariaInputEditProfessionState',
        defaultMessage: 'Enter state/province',
        description: 'Aria label for edit user profession state.',
    },
    ariaInputEditProfessionStartDate: {
        id:'ariaInputEditProfessionStartDate',
        defaultMessage: 'Enter start date',
        description: 'Aria label for edit user profession start date.',
    },
    ariaInputEditProfessionEndDate: {
        id:'ariaInputEditProfessionEndDate',
        defaultMessage: 'Enter end date',
        description: 'Aria label for edit user profession end date.',
    },
    ariaInputEditEducationQualification: {
        id:'ariaInputEditEducationQualification',
        defaultMessage: 'Enter your credential title',
        description: 'Aria label for edit user credential title.',
    },
    ariaInputEditEducationDescription: {
        id:'ariaInputEditEducationDescription',
        defaultMessage: 'Enter a brief description',
        description: 'Aria label for edit user credential description.',
    },
    ariaInputEditEducationSchool: {
        id:'ariaInputEditEducationSchool',
        defaultMessage: 'Enter institution',
        description: 'Aria label for edit user credential institution.',
    },
    ariaInputEditEducationCountry: {
        id:'ariaInputEditEducationCountry',
        defaultMessage: 'Enter country',
        description: 'Aria label for edit user credential country.',
    },
    ariaInputEditEducationState: {
        id:'ariaInputEditEducationState',
        defaultMessage: 'Enter state or province',
        description: 'Aria label for edit user credential state.',
    },
    ariaInputEditEducationStartDate: {
        id:'ariaInputEditEducationStartDate',
        defaultMessage: 'Enter start date',
        description: 'Aria label for edit user credential start date.',
    },
    ariaInputEditEducationEndDate: {
        id:'ariaInputEditEducationEndDate',
        defaultMessage: 'Enter end date',
        description: 'Aria label for edit user education end date.',
    },
    ariaInputEditAwardTitle: {
        id:'ariaInputEditAwardTitle',
        defaultMessage: 'Enter your award title',
        description: 'Aria label for edit user award title.',
    },
    ariaInputEditAwardBy: {
        id:'ariaInputEditAwardBy',
        defaultMessage: 'Awarded by',
        description: 'Aria label for edit user awarded by.',
    },
    ariaInputEditAwardYear: {
        id:'ariaInputEditAwardYear',
        defaultMessage: 'Enter year',
        description: 'Aria label for edit user award year.',
    },
    uploadImageText: {
        id:'uploadImageText',
        defaultMessage: 'Upload an image',
        description: 'Text for uploading image option',
    },
    changeImageText: {
        id:'changeImageText',
        defaultMessage: 'Change image',
        description: 'Text for changing image option',
    },
    removeImageText: {
        id:'removeImageText',
        defaultMessage: 'Remove',
        description: 'Text for removing image option',
    },
    messageButton: {
        id:'messageAUserButton',
        defaultMessage: 'Message {name}',
        description: 'Label for button to click to send a direct message to the user.',
    },
    addProfessionButton: {
        id:'addProfessionButton',
        defaultMessage: 'Add more professional experience',
        description: 'Label for button to click to add a profession.',
    },
    addEducationButton: {
        id:'addEducationButton',
        defaultMessage: 'Add another credential',
        description: 'Label for button to click to add a credential.',
    },
    addAwardButton: {
        id:'addAwardButton',
        defaultMessage: 'Add another award',
        description: 'Label for button to click to add an award.',
    },
    presentDate: {
        id:'presentDate',
        defaultMessage: 'present',
        description: 'Text representation of the present date.',
    },
    presentLabel: {
        id:'presentLabel',
        defaultMessage: 'Present',
        description: 'Dropdown label for an undefined date.',
    },
    monthLabel: {
        id:'monthLabel',
        defaultMessage: 'Month',
        description: 'Dropdown label for an undefined year.',
    },
    yearLabel: {
        id:'yearLabel',
        defaultMessage: 'Year',
        description: 'Dropdown label for an undefined year.',
    },
    userRole: {
        id:'userRole',
        defaultMessage: 'Role',
        description: 'Title of section displaying user role and role change form.',
    },
    confirmEducatorSwitchTitle: {
        id:'confirmEducatorSwitchTitle',
        defaultMessage: 'Are you sure you want to switch to Educator?',
        description: 'Title of confirmation modal asking whether user wants to switch to educator role.',
    },
    confirmEducatorSwitchContent: {
        id:'confirmEducatorSwitchContent',
        defaultMessage: `If you switch to the Educator role, you can create classes.
         Once you are an Educator, you will always have access to both an Educator and a Learner Dashboard,
         and this cannot be reversed.`,
        description: 'Content of confirmation modal asking whether user wants to switch to educator role.',
    },
    switchToEducatorButton: {
        id:'switchToEducatorButton',
        defaultMessage: 'Switch to Educator',
        description: 'Button to click to start process of switching to Educator.',
    },
    switchToEducator: {
        id:'switchToEducator',
        defaultMessage: 'Yes, change to Educator',
        description: 'Label on confirmation dialog button to switch to educator.',
    },
    backToProfile: {
        id:'backToProfile',
        defaultMessage: 'Back to profile',
        description: 'Label on confirmation dialog button to cancel switching to educator.',
    },
    pvEveryone: {
        id:'pvEveryone',
        defaultMessage: 'Make my profile public',
        description: 'Label for profile visibility choice (visible to everyone)',
    },
    pvLabxchange: {
        id:'pvLabxchange',
        defaultMessage: 'LabXchange users only',
        description: 'Label for profile visibility choice (visible only to labxchange users)',
    },
    pvNoOne: {
        id:'pvNoOne',
        defaultMessage: 'Hide my profile',
        description: 'Label for profile visibility choice (visible to no-one)',
    },
    confirmPublishProfile: {
        id:'confirmPublishProfile',
        defaultMessage: 'Are you sure you want your profile to be visible to other LabXchange users? Be cautious about sharing your personal information online.',
        description: 'Warning message when a junior (< 18) switches profile visibility to other labxchange users.',
    },
    profileVisibilityLabel: {
        id:'profileVisibilityLabel',
        defaultMessage: 'Profile visibility',
        description: 'Title of the area that displays the active profile visibility type.',
    },
    errorMissingProfessionTitle: {
        id:'errorMissingProfessionTitle',
        defaultMessage: 'Please fill out your professional title before saving a profession.',
        description: 'Error message shown when the user has not filled out the profession title.',
    },
    errorMissingEducationTitle: {
        id:'errorMissingEducationTitle',
        defaultMessage: 'Please fill out your credential\'s title before saving it.',
        description: 'Error message shown when the user has not filled out the credential title.',
    },
    errorMissingAwardTitle: {
        id:'errorMissingAwardTitle',
        defaultMessage: 'Please fill out your award\'s title before saving it.',
        description: 'Error message shown when the user has not filled out the award title.',
    },
    errorEndDateShouldBeLater: {
        id:'errorEndDateShouldBeLater',
        defaultMessage: 'If you enter an end date, please confirm that it comes after the start date.',
        description: 'Error message shown when the user has filled and end date that comes before the start date.',
    },
    includeInSearchResultsToggle: {
        id:'includeInSearchResultsToggle',
        defaultMessage: 'Include my profile in LabXchange search results',
        description: 'Label on the toggle to toggle whether profile is visible in people search results.',
    },
    pvEveryoneText: {
        id:'pvEveryoneText',
        defaultMessage: 'Everyone',
        description: 'Text for profile visibility (visible to everyone)',
    },
    pvLabxchangeText: {
        id:'pvLabxchangeText',
        defaultMessage: 'LabXchange users only',
        description: 'Text for profile visibility (visible only to labxchange users)',
    },
    pvNoOneText: {
        id:'pvNoOneText',
        defaultMessage: 'Hidden',
        description: 'Text for profile visibility (visible to no-one)',
    },
    notLoggedLXProfileTitle: {
        id: 'notLoggedLXProfileTitle',
        defaultMessage: 'Log in to view this profile',
        description: 'Title of the alert when the user tries to enter to a profile with permissions for LX users without being logged in',
    },
    notLoggedLXProfileBody: {
        id: 'notLoggedLXProfileBody',
        defaultMessage: 'You must have a LabXchange account to access LabXchange user profiles. You can log in or sign up by clicking the buttons below.',
        description: 'Body of the alert when the user tries to enter to a profile with permissions for LX users without being logged in',
    },
    notLoggedProfileEditingTitle: {
        id: 'notLoggedProfileEditingTitle',
        defaultMessage: 'Log in to view your profile',
        description: 'Title of the alert when the user tries to enter to edit his profile without being logged in',
    },
    notLoggedProfileEditingBody: {
        id: 'notLoggedProfileEditingBody',
        defaultMessage: 'You must log in to view your profile. You can log in or sign up by clicking on the buttons below.',
        description: 'Body of the alert when the user tries to enter to edit his profile without being logged in',
    },
    careerProfile: {
        id:'careerProfile',
        defaultMessage: 'Career Profile',
        description: 'Career profile section title on user profile page',
    },
});

export default messages;
