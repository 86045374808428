import React, {useState} from 'react';
import messages from '../../../../displayMessages';
import { intl } from '../../../../../i18n';
import { Icon } from '../../../../../elements';
import { Skill } from 'labxchange-client';
import SideBarParent from '../SideBarForms/SideBarParent';
import CollapsibleComponent from '../../../CareerCollapsible/CollapsibleComponent';
import { LearnSkillCard } from './LearnSkillCard';
import { sanitizeConfigOptions, sanitizeUnsafeHTML } from '@labxchange/util-sanitization';


interface SkillSideBarProps {
    skill: Skill;
    isFullscreen: boolean;
    selectedSkillIndex: number;
    onClose: () => void;
}

const SkillSideBar: React.FC<SkillSideBarProps> = ({ skill, isFullscreen, selectedSkillIndex, onClose}) => {

    const titleDescriptionComponent = (title:string, description:string)  => <div>
        <span
            dangerouslySetInnerHTML={{
                __html: sanitizeUnsafeHTML(description, sanitizeConfigOptions.UnsafeHTMLSimple)
            }}
        />
    </div>;

    const [completionChecked, setCompletionChecked] = useState<boolean>(false);
    const toggleCompletionCheckbox = (newValue: boolean) => {
        setCompletionChecked(newValue);
    };
    return (
       <>
           <SideBarParent
               heading={intl.formatMessage(messages.skillSideBarHeaderTitle, {count: selectedSkillIndex})}
               description={titleDescriptionComponent(skill.title, skill.longDescription)}
               onClose={onClose}
               isOpen={skill !== null}
               isFullScreen={isFullscreen}
               completionLabel={intl.formatMessage(messages.skillAcquiredText)}
               completionChecked={completionChecked}
               toggleCompletionCheckbox={toggleCompletionCheckbox}
           >
               {skill.assetLink &&
                 <a
                   href={skill.assetLink}
                   className='asset-card'
                   target='_blank'
                   rel='noopener noreferrer'
                 >
                   <img
                     alt={intl.formatMessage(messages.altTextForImages)}
                     src={'/assets/images/career-explorer/labxchange-asset-logo.svg'}
                     className='logo'
                   />
                   <div className='asset-card-text'>
                       {intl.formatMessage(messages.skillTasksAssetLinkText, {
                           b: (chunks: React.ReactNode) => <strong>{chunks}</strong>,
                       } as unknown as Record<string, React.ReactNode>)}
                   </div>

                   <div data-testid='openExternalIcon'>
                     <Icon name='link-external-ce' fill='#00AEEF' zoom='1em'></Icon>
                   </div>
                 </a>
               }
               <div className='asset-border'></div>

               <div className='title-container'>
                   <div className='main-content-title'>
                       {intl.formatMessage(messages.skillBreakdownHeading)}
                   </div>
                   <div className='main-content-title-border-bottom'></div>

                   <div className='main-content-description'>
                       {intl.formatMessage(messages.skillBreakdownDescription)}
                   </div>
               </div>

               <div className='collapsibles'>
                   {skill.subskills?.map((subSkill) => (
                       <CollapsibleComponent
                           title={subSkill.title}
                           description={subSkill.description}
                       >
                           <div className='skill-card-holder'>
                               {subSkill.itemIds?.map((asset) => (
                                   <LearnSkillCard
                                       asset={asset}
                                   />
                               ))}
                           </div>
                       </CollapsibleComponent>
                   ))}
               </div>
           </SideBarParent>
       </>
    );
};

export default SkillSideBar;
