// tslint:disable
/**
 * LXBackend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime';
import {
    CareerQualification,
    CareerQualificationFromJSON,
    CareerQualificationToJSON,
} from './';

/**
 * 
 * @export
 * @interface CareerSearchResult
 */
export interface CareerSearchResult {
    /**
     * 
     * @type {string}
     * @memberof CareerSearchResult
     */
    slug: string;
    /**
     * 
     * @type {string}
     * @memberof CareerSearchResult
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof CareerSearchResult
     */
    icon?: string;
    /**
     * 
     * @type {CareerQualification}
     * @memberof CareerSearchResult
     */
    minimumQualification: CareerQualification;
    /**
     * 
     * @type {number}
     * @memberof CareerSearchResult
     */
    entryLevelSalary: number;
    /**
     * 
     * @type {number}
     * @memberof CareerSearchResult
     */
    midLevelSalary: number;
    /**
     * 
     * @type {number}
     * @memberof CareerSearchResult
     */
    seniorLevelSalary: number;
    /**
     * 
     * @type {boolean}
     * @memberof CareerSearchResult
     */
    isPublished: boolean;
    /**
     * 
     * @type {string}
     * @memberof CareerSearchResult
     */
    description?: string;
    /**
     * 
     * @type {any}
     * @memberof CareerSearchResult
     */
    alternativeNames?: any;
    /**
     * 
     * @type {string}
     * @memberof CareerSearchResult
     */
    pronunciation?: string;
    /**
     * 
     * @type {string}
     * @memberof CareerSearchResult
     */
    references?: string;
    /**
     * 
     * @type {string}
     * @memberof CareerSearchResult
     */
    subheading?: string;
}

export function CareerSearchResultFromJSON(json: any): CareerSearchResult {
    return {
        'slug': json['slug'],
        'title': json['title'],
        'icon': !exists(json, 'icon') ? undefined : json['icon'],
        'minimumQualification': CareerQualificationFromJSON(json['minimum_qualification']),
        'entryLevelSalary': json['entry_level_salary'],
        'midLevelSalary': json['mid_level_salary'],
        'seniorLevelSalary': json['senior_level_salary'],
        'isPublished': json['is_published'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'alternativeNames': !exists(json, 'alternative_names') ? undefined : (json['alternative_names']),
        'pronunciation': !exists(json, 'pronunciation') ? undefined : json['pronunciation'],
        'references': !exists(json, 'references') ? undefined : json['references'],
        'subheading': !exists(json, 'subheading') ? undefined : json['subheading'],
    };
}

export function CareerSearchResultToJSON(value?: CareerSearchResult): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'slug': value.slug,
        'title': value.title,
        'icon': value.icon,
        'minimum_qualification': CareerQualificationToJSON(value.minimumQualification),
        'entry_level_salary': value.entryLevelSalary,
        'mid_level_salary': value.midLevelSalary,
        'senior_level_salary': value.seniorLevelSalary,
        'is_published': value.isPublished,
        'description': value.description,
        'alternative_names': (value.alternativeNames),
        'pronunciation': value.pronunciation,
        'references': value.references,
        'subheading': value.subheading,
    };
}


