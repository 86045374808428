import { defineMessages } from 'react-intl';

const messages = defineMessages({
    careerDefaultPageTitle: {
        id:'careerDefaultPageTitle',
        defaultMessage: 'Career Explorer',
        description: 'Default value for the HTML title attribute',
    },
    careerDetailsPageTitle: {
        id:'careerDetailsPageTitle',
        defaultMessage: 'Career Details - Career Explorer',
        description: 'Title for Details page.',
    },
    careerPageTitlePlaceholder: {
        id:'careerPageTitlePlaceholder',
        defaultMessage: '{title} - Career Explorer',
        description: 'Placeholder for the value of the HTML title attribute',
    },
    careerUiHeaderNavBarBrand: {
        id:'careerUiHeaderNavBarBrand',
        defaultMessage: 'Career Explorer',
        description: 'Title for Navbar.',
    },
    careerUiNavSearch: {
        id:'careerUiNavSearch',
        defaultMessage: 'Search careers',
        description: 'Placeholder text for the career explorer search box.',
    },
    careerCardComingSoonText: {
        id:'careerCardComingSoonText',
        defaultMessage: '- Coming soon! -',
        description: 'Text to display when a career is in draft status.',
    },
    careerCategoryCardDummyDescription: {
        id:'careerCategoryCardDummyDescription',
        defaultMessage: 'This is career category description',
        description: 'Text to display when a career is in draft status.',
    },
    careerResultsText: {
        id: 'careerResultsText',
        defaultMessage: '{count} result{plural}',
        description: 'Text to display for search results.',
    },
    careerCardMinQualification: {
        id: 'careerCardMinQualification',
        defaultMessage: 'Min. Qualification: ',
        description: 'Text to display for minimum qualification.',
    },
    careerCardSalaryRange: {
        id: 'careerCardSalaryRange',
        defaultMessage: 'Salary Range: ',
        description: 'Text to display for salary range.',
    },
    careerSearchPlaceholder: {
        id: 'careerSearchPlaceholder',
        defaultMessage: 'Search',
        description: 'Placeholder for career search box.',
    },
    careerNavHeaderTitle: {
        id: 'careerHeaderTitle',
        defaultMessage: 'Find your dream career',
        description: 'Title for the header.',
    },
    careerNavHeaderSubtitle: {
        id: 'careerNavHeaderSubtitle',
        defaultMessage: 'Search by interest, impact, industry or any keyword or browse all careers below. You can also use the advanced filters to customise your career options.',
        description: 'Subtitle for the header.',
    },
    categoryNavHeaderSubtitle: {
        id: 'careerHeaderSubtitle',
        defaultMessage: 'Browse all career below',
        description: 'Subtitle for the header.',
    },
    careerShowingResultsLabel: {
        id: 'careerShowingResultsLabel',
        defaultMessage: 'Showing results for',
        description: 'Label for search results.',
    },
    careerNoResultsHeading: {
        id: 'careerNoResultsHeading',
        defaultMessage: 'No results found',
        description: 'Heading for no search results.',
    },
    careerNoResultsSubheading: {
        id: 'careerNoResultsSubheading',
        defaultMessage: 'Try searching for something else.',
        description: 'Subheading for no search results.',
    },
    careerTopRecommendations: {
        id: 'careerTopRecommendations',
        defaultMessage: 'Top recommendations',
        description: 'Title for top recommendations.',
    },
    careerLoadMoreButton: {
        id: 'careerLoadMoreButton',
        defaultMessage: 'Load More',
        description: 'Button to load more careers.',
    },
    careerMinQualification: {
        id: 'careerMinQualification',
        defaultMessage: 'Min qualification',
        description: 'Text to display for minimum qualification.',
    },
    careerSalaryRange: {
        id: 'careerSalaryRange',
        defaultMessage: 'Salary range',
        description: 'Text to display for salary range.',
    },
    careerWorkplace: {
        id: 'careerWorkplace',
        defaultMessage: 'Workplace',
        description: 'Text to display for career workplace.',
    },
    careerRequirements: {
        id: 'careerRequirements',
        defaultMessage: 'Requirements',
        description: 'Text to display for career requirements.',
    },
    careerQualifications: {
        id: 'careerQualifications',
        defaultMessage: 'Qualifications',
        description: 'Text to display for career qualifications.',
    },
    careerHardSkills: {
        id: 'careerHardSkills',
        defaultMessage: 'Hard Skills',
        description: 'Text to display for career hard skills.',
    },
    careerSoftSkills: {
        id: 'careerSoftSkills',
        defaultMessage: 'Soft Skills',
        description: 'Text to display for career soft skills.',
    },
    careerWhatToDoNext: {
        id: 'careerWhatToDoNext',
        defaultMessage: 'What to do next?',
        description: 'Text to display for what to do next.',
    },
    careerRelatedCareers: {
        id: 'careerRelatedCareers',
        defaultMessage: 'Related Careers',
        description: 'Text for related careers.',
    },
    careerSideNavOverview: {
        id: 'careerSideNavOverview',
        defaultMessage: 'Overview',
        description: 'Text for the overview section in the side nav.',
    },
    careerSideNavProgression: {
        id: 'careerSideNavProgression',
        defaultMessage: 'Career progression',
        description: 'Text for the progression section in the side nav.',
    },
    careerSideNavDayInLife: {
        id: 'careerSideNavDayInLife',
        defaultMessage: 'Day in the life',
        description: 'Text for the day in the life section in the side nav.',
    },
    careerSideNavHardSkills: {
        id: 'careerSideNavHardSkills',
        defaultMessage: 'Hard skills',
        description: 'Text for the hard skills section in the side nav.',
    },
    careerSideNavSoftSkills: {
        id: 'careerSideNavSoftSkills',
        defaultMessage: 'Soft skills',
        description: 'Text for the soft skills section in the side nav.',
    },
    careerSideNavWhatToDoNext: {
        id: 'careerSideNavWhatToDoNext',
        defaultMessage: 'What to do next?',
        description: 'Text for the what to do next section in the side nav.',
    },
    careerSideNavRelatedCareers: {
        id: 'careerSideNavRelatedCareers',
        defaultMessage: 'Related careers',
        description: 'Title for related careers section in the side nav.',
    },
    careerSuggestedAssetsExploreLearnings: {
        id: 'careerSuggestedAssetsExploreLearnings',
        defaultMessage: 'Explore learnings',
        description: 'Text for the explore learnings button in suggested assets section.',
    },
    careerActivityTitleCardIntro: {
        id: 'careerActivityTitleCardIntro',
        defaultMessage: 'Day in life of a',
        description: 'Text for the title card intro.',
    },
    careerActivityTitleCardScroll: {
        id: 'careerActivityTitleCardScroll',
        defaultMessage: 'Scroll',
        description: 'Text for the scroll text.',
    },
    careerPronunciationAmericanText: {
        id: 'careerPronunciationAmericanText',
        defaultMessage: 'In American English it sounds like',
        description: 'Text for showing how the pronunciation will sound like in American english.',
    },
    careerAlternativeNamesText: {
        id: 'careerAlternativeNamesText',
        defaultMessage: 'Also known as',
        description: 'Text for alternative names of a career name.',
    },
    careerIntroSectionHeading: {
        id: 'careeeIntoSectionHeading',
        defaultMessage: 'Introduction',
        description: 'Text for career introduction section heading.',
    },
    careerOverviewSectionHeading: {
        id: 'careerOverviewSectionHeading',
        defaultMessage: 'Overview',
        description: 'Text for career overview section heading.',
    },
    CareerDutiesHeadingText: {
        id: 'CareerDutiesHeadingText',
        defaultMessage: 'Duties',
        description: 'Text for career duties section heading.',
    },
    CareerDutiesIntroText: {
        id: 'CareerDutiesIntroText',
        defaultMessage: 'Explore the most frequently performed duties of this career and try them out yourself.',
        description: 'Text for career duties introduction section.',
    },
    careerChallengeCardCTAText: {
        id: 'careerChallengeCardCTAText',
        defaultMessage: 'Practice this on LabXchange',
        description: 'Text for challenge asset CTA.',
    },
    careerSkillCardCTAText: {
        id: 'careerSkillCardCTAText',
        defaultMessage: 'Learn this on LabXchange',
        description: 'Text for skill asset CTA.',
    },
    careerTasksMainHeading: {
        id: 'careerTasksMainHeading',
        defaultMessage: 'Let\'s take a closer look',
        description: 'Text for defining heading of task',
    },
    dutyTasksAssetLinkText: {
        id: 'dutyTasksAssetLinkText',
        defaultMessage: 'Start learning this duty on <b>LabXchange</b>.',
        description: 'Text to show with duty asset link',
    },
    skillTasksAssetLinkText: {
        id: 'skillTasksAssetLinkText',
        defaultMessage: 'Start learning this skill on <b>LabXchange</b>.',
        description: 'Text to show with skill asset link',
    },
    skillBreakdownHeading:{
        id: 'skillBreakdownHeading',
        defaultMessage: 'Skill breakdown',
        description: 'Text to add as sub-skill breakdown heading',
    },
    skillBreakdownDescription:{
        id: 'skillBreakdownDescription',
        defaultMessage: 'In order to learn this skill, you will need to learn the following tools, knowledge and methods.',
        description: 'Text to describe what skill breakdown refers to in platform',
    },
    careerTasksDescription: {
        id: 'careerTasksDescription',
        defaultMessage: 'Here are the 5 most frequent tasks you would do on a daily basis followed by a fun challenge to test your skills!',
        description: 'Text for types kind of skills needed for the career',
    },
    careerChallengeHeading: {
        id: 'careerChallengeHeading',
        defaultMessage: 'Try it yourself.',
        description: 'Text for indicating the test for skills challenge',
    },
    relatedCareersHeadingText: {
        id: 'relatedCareersHeadingText',
        defaultMessage: 'Related careers',
        description: 'Text for indicating the heading for related career section',
    },
    showLessNamesText: {
        id: 'showLessNamesText',
        defaultMessage: 'Show Less',
        description: 'Text for toggle button to show user you can collapse section by showing less text',
    },
    showAllNamesText: {
        id: 'showAllNamesText',
        defaultMessage: 'Show {count} more',
        description: 'Text for toggle button to there are some more names to show',
    },
    altTextForChallengeCard: {
        id: 'altTextForChallengeCard',
        defaultMessage: 'Challenge task image',
        description: 'Alt Text for challenge card image for accessibility',
    },
    altTextForImages: {
        id: 'altTextForImages',
        defaultMessage: 'Information icon or image',
        description: 'Alt Text for image for accessibility',
    },
    altTextForIllustration: {
        id: 'altTextForIllustration',
        defaultMessage: 'Career illustration',
        description: 'Alt Text for illustration image for accessibility',
    },
    readMoreBasicSummary: {
        id: 'readMoreBasicSummary',
        defaultMessage: 'Read more',
        description: 'Text to show you can read more about this section',
    },
    outlookSectionHeading: {
        id: 'outlookSectionHeading',
        defaultMessage: 'Career outlook in the US',
        description: 'Career outlook section heading.',
    },
    outlookDemandTitle: {
        id: 'outlookDemandTitle',
        defaultMessage: 'Demand',
        description: 'Career outlook demand card title.',
    },
    outlookJobOpeningsTitle: {
        id: 'outlookJobOpeningsTitle',
        defaultMessage: 'Job openings',
        description: 'Career outlook job openings card title.',
    },
    outlookQouteTextPart1: {
        id: 'outlookQouteTextPart1',
        defaultMessage: 'You may be thinking,',
        description: 'Career outlook qouted text part one. Note: please consider the part one and two together while translating.',
    },
    outlookQouteTextPart2: {
        id: 'outlookQouteTextPart2',
        defaultMessage: '“why is there such a high demand for {careerTitle}s?”',
        description: 'Career outlook qouted text part two. Note: please consider the part one and two together while translating.',
    },
    salarySectionHeading: {
        id: 'salarySectionHeading',
        defaultMessage: 'Salary in the US',
        description: 'Career salary section heading.',
    },
    salaryFooterTextPart1: {
        id: 'salaryFooterTextPart1',
        defaultMessage: 'It’s not just about salary!',
        description: 'Career salary section footer text part 1.',
    },
    salaryFooterTextPart2: {
        id: 'salaryFooterTextPart2',
        defaultMessage: 'Consider benefits like healthcare, PTO (vacation, sick leave), retirement plans, stock options, bonuses, overtime eligibility, flexible schedules, and perks like on-site amenities. These extras can make a big difference in your career choice!',
        description: 'Career salary section footer text part 2.',
    },
    averageSalaryLabel: {
        id: 'averageSalaryLabel',
        defaultMessage: 'Average salary',
        description: 'Average salary label',
    },
    averageSalaryPerYearLabel: {
        id: 'averageSalaryPerYearLabel',
        defaultMessage: 'per year',
        description: 'Average salary per year label',
    },
    salarySliderHelpText: {
        id: 'averageSalarySliderHelpText',
        defaultMessage: 'Adjust the slider to see how the salary range increases with years of experience.',
        description: 'Average salary slider help text',
    },
    salarySliderEntryLevelText: {
        id: 'salarySliderEntryLevelText',
        defaultMessage: 'Entry',
        description: 'Salary slider entry level label',
    },
    salarySliderMidLevelText: {
        id: 'salarySliderMidLevelText',
        defaultMessage: 'Mid-level',
        description: 'Salary slider mid-level label',
    },
    salarySliderSeniorLevelText: {
        id: 'salarySliderSeniorLevelText',
        defaultMessage: 'Senior',
        description: 'Salary slider senior level label',
    },
    salarySliderEntryLevelYearText: {
        id: 'salarySliderEntryLevelYearText',
        defaultMessage: '>1 year',
        description: 'Salary slider entry level label',
    },
    salarySliderMidLevelYearText: {
        id: 'salarySliderMidLevelYearText',
        defaultMessage: '5 years',
        description: 'Salary slider entry level label',
    },
    salarySliderSeniorLevelYearText: {
        id: 'salarySliderSeniorLevelYearText',
        defaultMessage: '9+ years',
        description: 'Salary slider entry level label',
    },
    salaryEntryLevelLabel: {
        id: 'salaryEntryLevelLabel',
        defaultMessage: 'Entry-level',
        description: 'Salary card entry level label',
    },
    salarySeniorLevelLabel: {
        id: 'salarySeniorLevelLabel',
        defaultMessage: 'Senior level',
        description: 'Salary card senior level label',
    },
    projectHighlightSectionLabel: {
        id: 'projectHighlightSectionLabel',
        defaultMessage: 'PROJECT HIGHLIGHT',
        description: 'Project highlight section label',
    },
    careerDescriptionLearnMore: {
        id: 'careerDescriptionLearnMore',
        defaultMessage: 'Learn more',
        description: 'text to indicate there is more description to read',
    },
    fullScreenButtonTitle: {
        id: 'fullScreenButtonTitle',
        defaultMessage: 'Full screen',
        description: 'Full screen button title',
    },
    projectHighlightLearnMoreLinkLabel: {
        id: 'projectHighlightLearnMoreLinkLabel',
        defaultMessage: 'Learn more',
        description: 'Project highlight learn more link label',
    },
    skillsDescriptionText: {
        id: 'skillsDescriptionText',
        defaultMessage: 'You learn skills in a school setting or from experience on the job.',
        description: 'Description text explaining what are skills',
    },
    showAllSkillsText: {
        id: 'showAllSkillsText',
        defaultMessage: 'View all skills',
        description: 'Text to show view all skills',
    },
    collapseSkillsText: {
        id: 'collapseSkillsText',
        defaultMessage: 'Collapse skills',
        description: 'Description text explaining what are skills',
    },
    abilitiesDescriptionText: {
        id: 'abilitiesDescriptionText',
        defaultMessage: 'Abilities are natural or what you are born with. These can’t be learnt but can be improved with practice, or in the case of impairments, with treatment and technology.',
        description: 'Description text explaining what are abilities',
    },
    dutySideBarHeaderTitle: {
        id: 'dutySideBarHeaderTitle',
        defaultMessage: 'Duty #{count} details',
        description: 'Heading text for duties sidebar',
    },
    skillSideBarHeaderTitle: {
        id: 'skillSideBarHeaderTitle',
        defaultMessage: 'Skill #{count} details',
        description: 'Heading text for skills sidebar',
    },
    skillsTitleText: {
        id: 'skillsTitleText',
        defaultMessage: 'Skills',
        description: 'Heading text for skills',
    },
    abilityTitleText: {
        id: 'abilityTitleText',
        defaultMessage: 'Abilities',
        description: 'Heading text for ability',
    },
    skillAcquiredText: {
        id: 'skillAcquiredText',
        defaultMessage: 'I’ve learned this skill',
        description: 'checkbox text to confirm skill is acquired',
    },
    abilityAcquiredText: {
        id: 'abilityAcquiredText',
        defaultMessage: 'I have this ability',
        description: 'checkbox text to confirm the user has ability',
    },
    skillCountLabel: {
        id: 'skillCountLabel',
        defaultMessage: 'Skill #{count}',
        description: 'skill count label to show with skill card',
    },
    skillLearnMore: {
        id: 'skillLearnMore',
        defaultMessage: 'Learn more',
        description: 'text to display on skill card for redirecting to show more about skill',
    },
    careerOverviewDemandCardLabel: {
        id: 'careerOverviewDemandCardLabel',
        defaultMessage: 'Demand',
        description: 'Career overview demand card label.',
    },
    careerOverviewSalaryCardLabel: {
        id: 'careerOverviewSalaryCardLabel',
        defaultMessage: 'Average salary',
        description: 'Career overview average salary card label',
    },
    careerOverviewSkillsCardLabel: {
        id: 'careerOverviewSkillsCardLabel',
        defaultMessage: 'Skills needed',
        description: 'Career overview skills card label',
    },
    careerOverviewRelatedCareersCardLabel: {
        id: 'careerOverviewRelatedCareersCardLabel',
        defaultMessage: 'Related careers',
        description: 'Career overview related careers card label',
    },
    careerOverviewEducationCardLabel: {
        id: 'careerOverviewEducationCardLabel',
        defaultMessage: 'Minimum education required',
        description: 'Career overview education card label',
    },
    careerOverviewDutyCardLabel: {
        id: 'careerOverviewDutyCardLabel',
        defaultMessage: 'Most frequent duty',
        description: 'Career overview duty card label',
    },
    sourcesSectionHeadingText: {
        id: 'sourcesSectionHeadingText',
        defaultMessage: 'Sources',
        description: 'Text for indicating the heading for sources section',
    },
    sourcesSectionDescriptionText: {
        id: 'sourcesSectionDescriptionText',
        defaultMessage: 'We have strict sourcing guidelines and rely on a panel of industry experts to validate this information. We only use high-quality, credible sources to ensure content accuracy and integrity.',
        description: 'Sources section description text.',
    },
    referencesLabelText: {
        id: 'sourceReferencesLabelText',
        defaultMessage: 'References',
        description: 'Sources section references collapsible label text.',
    },
    missionStatementLabelText: {
        id: 'missionStatementLabelText',
        defaultMessage: 'Global focus, local data',
        description: 'Sources section mission statement label text.',
    },
    missionStatementDescriptionText: {
        id: 'missionStatementDescriptionText',
        defaultMessage: 'Our mission is to provide a career platform that has useful and relatable data for people researching careers all over the world. At the moment, our data is largely focused on careers in the United States, but we are actively working on adding data applicable to more locations soon.',
        description: 'Sources section mission statement description text.',
    },
    updateHistoryLabelText: {
        id: 'updateHistoryLabelText',
        defaultMessage: 'Update history',
        description: 'Career update history collapsible label text.',
    },
    updateHistoryUploadedOnLabelText: {
        id: 'updateHistoryUploadedOnLabelText',
        defaultMessage: 'Uploaded on {date}',
        description: 'Career uploaded on date label text.',
    },
    updateHistoryUpdatedOnLabelText: {
        id: 'updateHistoryUpdatedOnLabelText',
        defaultMessage: 'Last updated {date}',
        description: 'Career updated on date label text.',
    },
});

export default messages;
