import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { intl } from 'i18n';

import { sanitizeConfigOptions } from '@labxchange/util-sanitization';
import { isKeyboardEnterEvent } from 'global/utils';
import {
  Icon, StandardPageLayout, showErrorMessage,
  PillButtonFilters, ALL_FILTER
} from 'ui/components';
import { RichText, CollapsibleWithChevron } from 'elements';
import {
  Module,
  ModuleSlab,
  ModuleSlabDisplayTypeEnum,
  ItemMetadata,
} from 'labxchange-client';
import { ModulesApi } from 'global/api';
import PathwaysSlab from 'subjects/components/page/PathwaysSlab';
import { getCurriculumGroup } from 'auth/selectors';
import { analyticsInstance } from 'tracking';
import { EVENT_NAMES } from 'tracking/constants';
import { detailUrlForEntity } from 'library/utils';
import { ROUTES } from 'global/constants';
import teachPageMessages from 'home/components/TeachPage/displayMessages';

import { GenericSlab, CloselyRelatedSlab } from './ModuleSlabs';
import { CurriculumSidebar } from './CurriculumSidebar';
import { CurriculumModulePageSkeleton } from './CurriculumModulePageSkeleton';
import messages from '../../displayMessages';

const SIDEBAR_BREAKPOINT = 1310;

const SLAB_TITLE_SUBHEADING_MAP = {
  'Simulations & interactive animations': messages.interactivesSlabSubheading,
  'Videos & annotated videos': messages.videosSlabSubheading,
  'Case studies': messages.caseStudiesSlabSubheading,
  'Readings': messages.readingsSlabSubheading,
  'Complete lessons': messages.completeLessonsSlabSubheading,
  'Learning pathways': messages.pathwaysSlabSubheading,
  'Questions': messages.questionsSlabSubheading,
  'Images': messages.imagesSlabSubheading,
};

export const CurriculumModulePage = () => {
  const location = useLocation();
  const pathSegments = location.pathname.split('/');
  const curriculum = pathSegments[pathSegments.length - 4];
  const subject = pathSegments[pathSegments.length - 3];
  const grade = pathSegments[pathSegments.length - 2];
  const moduleSlug = pathSegments[pathSegments.length - 1];
  const version = new URLSearchParams(window.location.search).get('version') || undefined;
  const [isSmallScreen, setIsSmallScreen] = useState<boolean>(window.innerWidth <= SIDEBAR_BREAKPOINT);

  // Limit the number of assets per slab to 4 by default
  const maxAssetsPerSlab = Number(new URLSearchParams(window.location.search).get('max_assets_per_slab')) || 4;
  const [moduleData, setModuleData] = useState<Module | null>(null);
  const [loadingModuleData, setLoadingModuleData] = useState<boolean>(false);
  const [selectedFilters, setSelectedFilters] = useState<string[]>([ALL_FILTER]);
  const [showFixedSidebar, setShowFixedSidebar] = useState(false);
  const userCurriculumGroup = useSelector(getCurriculumGroup);
  const canUserGiveFeedback = userCurriculumGroup === curriculum;

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= SIDEBAR_BREAKPOINT);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    const fetchModuleData = async () => {
      setLoadingModuleData(true);
      try {
        const moduleResponse: Module = await ModulesApi.read({
          id: moduleSlug,
          curriculum,
          subject,
          grade,
          version,
          maxAssetsPerSlab,
        });
        setModuleData(moduleResponse);
      } catch (error) {
        showErrorMessage(intl.formatMessage(messages.curriculumModulePageModuleError));
      }
      setLoadingModuleData(false);
    };

    fetchModuleData();
  } , [curriculum, grade, maxAssetsPerSlab, moduleSlug, subject, version]);

  const openSidebar = () => {
    document.body.style.overflow = 'hidden';
    setShowFixedSidebar(true);
  };

  const closeSidebar = () => {
    document.body.style.overflow = 'auto';
    setShowFixedSidebar(false);
  };

  const handlePathwayClick = (
    pathwayOrItem: ItemMetadata,
    buttonText?: string,
    pathwayId?: string,
    isPathwayItemClick?: boolean,
  ) => {
    const url = isPathwayItemClick
      ? ROUTES.Library.PATHWAY_ITEM_SLUG(pathwayId!, pathwayOrItem.id, pathwayOrItem.relId)
      : detailUrlForEntity(pathwayOrItem);

    analyticsInstance.track(EVENT_NAMES.CurriculumModulePageAssetCardClicked, {
      asset_id: pathwayOrItem.id,
      asset_title: pathwayOrItem.title,
      asset_type: pathwayOrItem.type,
      asset_url: url,
      module_id: moduleData?.id,
      module_slug: moduleData?.slug,
      active_version: moduleData?.activeVersion,
      curriculum_slug: curriculum,
      selected_filters: selectedFilters,
      button_text: buttonText,
      url: window.location.toString(),
    });
    window.open(url, '_blank');
  };

  const renderModuleSlabs = (slabs: ModuleSlab[]) => {
    if (!moduleData) return null;

    // Remove slabs having no items
    let filteredSlabs = slabs.filter(slab => slab.items.length > 0);
    // Filter slabs on basis of selected filters
    filteredSlabs = selectedFilters.includes(ALL_FILTER)
      ? slabs
      : slabs.filter((slab) => selectedFilters.includes(slab.title));

    const slabsContent = filteredSlabs.map((slab) => {
      if (slab.items.length === 0) return null;

      if (slab.displayType === ModuleSlabDisplayTypeEnum.Interactives || slab.displayType === ModuleSlabDisplayTypeEnum.SimpleCards) {
        return (
          <React.Fragment key={slab.title}>
            <GenericSlab
              slab={slab}
              curriculumSlug={curriculum}
              module={moduleData}
              subHeading={intl.formatMessage((SLAB_TITLE_SUBHEADING_MAP as any)[slab.title])}
              showFeedbackButton={canUserGiveFeedback}
              selectedFilters={selectedFilters}
            />
            <div className='slab-horizontal-divider' />
          </React.Fragment>
        );
      } else if (slab.displayType === ModuleSlabDisplayTypeEnum.Pathways) {
        return (
          <React.Fragment key={slab.title}>
            <PathwaysSlab
              heading={slab.title}
              slab={slab}
              showExploreButton={true}
              subHeading={intl.formatMessage((SLAB_TITLE_SUBHEADING_MAP as any)[slab.title])}
              onClickExploreButton={(pathway) => handlePathwayClick(
                pathway, intl.formatMessage(teachPageMessages.pathwayCardExploreButton)
              )}
              onClickPathwayItem={(item, pathwayId) => handlePathwayClick(item, undefined, pathwayId, true)}
              onClickPathwayImage={(pathway) => handlePathwayClick(pathway)}
              showFeedbackButton={canUserGiveFeedback}
              curriculumSlug={curriculum}
              module={moduleData}
            />
            <div className='slab-horizontal-divider' />
          </React.Fragment>
        );
      }

      return null;
    });

    return (
      <div className='module-slabs'>
        {slabsContent}
        {moduleData.siblings.length > 0 && <CloselyRelatedSlab modules={moduleData.siblings} />}
      </div>
    );
  };

  const renderModuleContent = () => {
    if (!moduleData || loadingModuleData) {
      return (
        <div className='module-content'>
          <CurriculumModulePageSkeleton />
        </div>
      );
    }

    return (
      <div className='module-content'>
        <div className='title-core-concept'>
          {isSmallScreen && (
            <button
              tabIndex={0}
              className='curriculum-sidebar-button'
              aria-label='Open sidebar'
              onClick={openSidebar}
              onKeyDown={(e) => isKeyboardEnterEvent(e) && openSidebar()}
            >
              <span>{intl.formatMessage(messages.curriculumSidebarToggle)}</span>
              <Icon name='double-arrow-right' zoom='20' />
            </button>
          )}
          <h1 className='font-m-lt'>{moduleData.name}</h1>
          <CollapsibleWithChevron
            key={moduleData.name}
            title={moduleData.learningObjectivesHeading}
            defaultOpen={false}
            onToggleCallback={(expanded: boolean) => analyticsInstance.track(EVENT_NAMES.CurriculumModulePageCollapsibleClicked, {
              module_id: moduleData.id,
              module_slug: moduleData.slug,
              module_name: moduleData.name,
              active_version: moduleData.activeVersion,
              module_url: ROUTES.Curriculum.MODULE_SLUG(curriculum, subject, grade, moduleData.slug),
              total_learning_objectives: moduleData.learningObjectives.length,
              url: window.location.toString(),
              expanded,
            })}
          >
            {moduleData.learningObjectives.length > 1 ?
              <ol>{moduleData.learningObjectives.map((lo, index) =>
                <li key={index}>
                  <RichText
                      innerHtml={lo}
                    sanitizeConfig={sanitizeConfigOptions.UnsafeHTMLSimple}
                  />
                </li>
              )}
              </ol>
              :
              <RichText
                  innerHtml={moduleData.learningObjectives[0]}
                sanitizeConfig={sanitizeConfigOptions.UnsafeHTMLSimple}
              />
            }
          </CollapsibleWithChevron>
        </div>
        <PillButtonFilters
          filters={moduleData.moduleSlabs.filter(slab => slab.items.length > 0).map((slab) => slab.title)}
          selectedFilters={selectedFilters}
          setSelectedFilters={setSelectedFilters}
          onFilterClick={(filter, updatedFilters) => analyticsInstance.track(EVENT_NAMES.CurriculumModulePageContentTypeFilterClicked, {
            filter_text: filter,
            selected_filters: updatedFilters,
            url: window.location.toString(),
          })}
        />
        {renderModuleSlabs(moduleData.moduleSlabs)}
      </div>
    );
  };

  return (
    <StandardPageLayout
      backgroundStyle='light-header'
      mainClassName='curriculum-module-page'
      pageTitle={messages.curriculumPageTitle}
      pageTitleValues={{ name: moduleData?.name || '' }}
      footerClassName='curriculum-module-page-footer'
    >
      <CurriculumSidebar
        closeSidebar={closeSidebar}
        showFixedSidebar={showFixedSidebar}
      />
      {renderModuleContent()}
    </StandardPageLayout>
  );
};
