import React, { FC, useEffect, useState } from 'react';
import { useLocation, useHistory, NavLink } from 'react-router-dom';
import { intl } from 'i18n';

import { sanitizeConfigOptions } from '@labxchange/util-sanitization';
import { isKeyboardEnterEvent } from 'global/utils';
import {
  Icon, Spinner, StandardPageLayout, showErrorMessage, TextInput,
} from 'ui/components';
import { RichText, CollapsibleWithChevron } from 'elements';
import {
  Module,
  ModuleSlab,
} from 'labxchange-client';
import { ModulesApi } from 'global/api';
import { detailUrlForEntity } from 'library/utils';
import { WrappedMessage } from 'utils';

import messages from '../../displayMessages';
import { CurriculumSidebar } from '../CurriculumModulePage/CurriculumSidebar';

const SIDEBAR_BREAKPOINT = 1310;

export const CurriculumVersionsPage: FC = () => {
  const location = useLocation();
  const history = useHistory();
  const pathSegments = location.pathname.split('/');
  const curriculum = pathSegments[pathSegments.length - 4];
  const subject = pathSegments[pathSegments.length - 3];
  const grade = pathSegments[pathSegments.length - 2];
  const moduleSlug = pathSegments[pathSegments.length - 1];
  const searchV1 = new URLSearchParams(window.location.search).get('v1') || 'v1';
  const searchV2 = new URLSearchParams(window.location.search).get('v2') || 'v2';
  const [isSmallScreen, setIsSmallScreen] = useState<boolean>(window.innerWidth <= SIDEBAR_BREAKPOINT);

  const [v1, setV1] = useState<string | undefined>(searchV1);
  const [v2, setV2] = useState<string | undefined>(searchV2);
  const maxAssetsPerSlab = Number(new URLSearchParams(window.location.search).get('max_assets_per_slab')) || undefined;
  const [loadingVersionsData, setLoadingVersionsData] = useState<boolean>(false);
  const [showFixedSidebar, setShowFixedSidebar] = useState(false);
  const [dataV1, setDataV1] = useState<Module | null>(null);
  const [moduleSlabsV1, setModuleSlabsV1] = useState<ModuleSlab[]>([]);
  const [moduleSlabsV2, setModuleSlabsV2] = useState<ModuleSlab[]>([]);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= SIDEBAR_BREAKPOINT);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const fetchData = async () => {
    setLoadingVersionsData(true);

    const searchParams = new URLSearchParams(location.search);
    searchParams.set('v1', v1 || '');
    searchParams.set('v2', v2 || '');
    history.push({ search: searchParams.toString() });

    try {
      const responseV1: Module = await ModulesApi.read({ id: moduleSlug, curriculum, subject, grade, version: v1, maxAssetsPerSlab });
      setDataV1(responseV1);
      if (responseV1.moduleSlabs) {
        setModuleSlabsV1(responseV1.moduleSlabs);
      }

      const responseV2: Module = await ModulesApi.read({ id: moduleSlug, curriculum, subject, grade, version: v2, maxAssetsPerSlab });
      if (responseV2.moduleSlabs) {
        setModuleSlabsV2(responseV2.moduleSlabs);
      }
    } catch (error) {
      showErrorMessage(intl.formatMessage(messages.curriclumVersionsDataLoadingError));
    }

    setLoadingVersionsData(false);
  };

  useEffect(() => {
    fetchData();
  }, [moduleSlug]);

  const renderSpinner = () => (
    <div
      data-testid='spinner-wrapper'
      className='spinner-wrapper'
    >
        <Spinner />
    </div>
  );

  const openSidebar = () => {
    document.body.style.overflow = 'hidden';
    setShowFixedSidebar(true);
  };

  const closeSidebar = () => {
    document.body.style.overflow = 'auto';
    setShowFixedSidebar(false);
  };

  const renderSlabs = () => {
    const zippedModuleSlabs = moduleSlabsV1.map((element, index) => [element, moduleSlabsV2[index]]);
    return (
      <div className='version-comparison'>
        <div className='version-titles-container' data-testid='version-titles-container'>
          <h2 className='version-title'> {searchV1} </h2>
          <h2 className='version-title'> {searchV2} </h2>
        </div>
        {zippedModuleSlabs.map((slabTuple: ModuleSlab[], index: number) => {
          return (
            <div key={`slab-${index}`}>
              <h2 className='slab-title'> {slabTuple[1].title} </h2>
              <div className='slabs'>
                <div className='module-slab'>
                  {slabTuple[0].items.length ? <ol>
                    {slabTuple[0].items.map((item, idx) => {
                      return (
                        <NavLink key={`item-v1-${idx}`} to={detailUrlForEntity(item.metadata)}>
                          <li> {item.metadata.title} </li>
                        </NavLink>
                      );
                    })}
                  </ol> : <div className='no-items'>{intl.formatMessage(messages.curriclumVersionsPageNoItems)}</div>}
                </div>
                <div className='module-slab'>
                  {slabTuple[1].items.length ? <ol>
                    {slabTuple[1].items.map((item, idx) => {
                      return (
                        <NavLink key={`item-v2-${idx}`} to={detailUrlForEntity(item.metadata)}>
                          <li> {item.metadata.title} </li>
                        </NavLink>
                      );
                    })}
                  </ol> : <div className='no-items'>{intl.formatMessage(messages.curriclumVersionsPageNoItems)}</div>}
                </div>
              </div>
            </div>
          );
        })
        }
      </div>
    );
  };

  const renderModuleContent = () => {
    if (!dataV1) return;
    return (
      <>
        <div className='module-content'>
          <div className='title-core-concept'>
            {isSmallScreen && (
              <button
                tabIndex={0}
                className='curriculum-sidebar-button'
                aria-label='Open sidebar'
                onClick={openSidebar}
                onKeyDown={(e) => isKeyboardEnterEvent(e) && openSidebar()}
              >
                <span>{intl.formatMessage(messages.curriculumSidebarToggle)}</span>
                <Icon name='double-arrow-right' zoom='20' />
              </button>
            )}
            <h1 className='font-m-lt'>{dataV1.name}</h1>
            <CollapsibleWithChevron
              key={dataV1.name}
              title={dataV1.learningObjectivesHeading}
              defaultOpen={true}
            >
              {dataV1.learningObjectives.length > 1 ?
                <ol>{dataV1.learningObjectives.map((lo, index) =>
                  <li key={index}>
                    <RichText
                        innerHtml={lo}
                      sanitizeConfig={sanitizeConfigOptions.UnsafeHTMLSimple}
                    />
                  </li>
                )}
                </ol>
                :
                <RichText
                    innerHtml={dataV1.learningObjectives[0]}
                  sanitizeConfig={sanitizeConfigOptions.UnsafeHTMLSimple}
                />
              }
            </CollapsibleWithChevron>
          </div>
          <div className='version-buttons'>
            <TextInput
              inputKey='version-input-v1'
              type='text'
              className='version-input v1'
              value={v1 || ''}
              onChangeValue={setV1}
              placeholder={messages.curriclumVersionsFirstVersionPlaceholder}
            />
            <TextInput
              inputKey='version-input-v2'
              type='text'
              className='version-input v2'
              value={v2 || ''}
              onChangeValue={setV2}
              placeholder={messages.curriclumVersionsSecondVersionPlaceholder}
            />
            <button
              type='submit'
              onClick={fetchData}
              disabled={!v1 || !v2}
              aria-disabled={!v1 || !v2}
              className='btn btn-primary submit-button'>
              <WrappedMessage message={messages.curriclumVersionsPageFetchButton} />
            </button>
          </div>
          {renderSlabs()}
        </div>
      </>
    );
  };

  return (
    <StandardPageLayout
      backgroundStyle='light-header'
      mainClassName='curriculum-versions-page'
      pageTitle={messages.curriclumVersionsPageTitle}
      pageTitleValues={{ moduleTitle: dataV1?.name || '' }}
      footerClassName='curriculum-versions-page-footer'
    >
      <CurriculumSidebar
        closeSidebar={closeSidebar}
        showFixedSidebar={showFixedSidebar}
      />
      {loadingVersionsData ? renderSpinner() : renderModuleContent()}
    </StandardPageLayout>
  );
};
