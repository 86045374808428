// tslint:disable
/**
 * LXBackend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime';
import {
    APIPermissions,
    APIPermissionsFromJSON,
    APIPermissionsToJSON,
    CareerProfile,
    CareerProfileFromJSON,
    CareerProfileToJSON,
} from './';

/**
 * 
 * @export
 * @interface LoginStatus
 */
export interface LoginStatus {
    /**
     * 
     * @type {string}
     * @memberof LoginStatus
     */
    username: string;
    /**
     * 
     * @type {string}
     * @memberof LoginStatus
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof LoginStatus
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof LoginStatus
     */
    avatarUrl: string;
    /**
     * 
     * @type {APIPermissions}
     * @memberof LoginStatus
     */
    permissions: APIPermissions;
    /**
     * 
     * @type {string}
     * @memberof LoginStatus
     */
    language: string;
    /**
     * 
     * @type {string}
     * @memberof LoginStatus
     */
    onboardingStatus: LoginStatusOnboardingStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof LoginStatus
     */
    dateOfBirth: string;
    /**
     * 
     * @type {string}
     * @memberof LoginStatus
     */
    role: LoginStatusRoleEnum;
    /**
     * 
     * @type {any}
     * @memberof LoginStatus
     */
    cookieConsent: any;
    /**
     * 
     * @type {boolean}
     * @memberof LoginStatus
     */
    hasVerifiedEmail: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof LoginStatus
     */
    needsAuthMigration: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof LoginStatus
     */
    hasClass: boolean;
    /**
     * 
     * @type {string}
     * @memberof LoginStatus
     */
    curriculumGroup?: string;
    /**
     * 
     * @type {CareerProfile}
     * @memberof LoginStatus
     */
    careerProfile?: CareerProfile;
}

export function LoginStatusFromJSON(json: any): LoginStatus {
    return {
        'username': json['username'],
        'email': json['email'],
        'name': json['name'],
        'avatarUrl': json['avatar_url'],
        'permissions': APIPermissionsFromJSON(json['permissions']),
        'language': json['language'],
        'onboardingStatus': json['onboarding_status'],
        'dateOfBirth': json['date_of_birth'],
        'role': json['role'],
        'cookieConsent': (json['cookie_consent']),
        'hasVerifiedEmail': json['has_verified_email'],
        'needsAuthMigration': json['needs_auth_migration'],
        'hasClass': json['has_class'],
        'curriculumGroup': !exists(json, 'curriculum_group') ? undefined : json['curriculum_group'],
        'careerProfile': !exists(json, 'career_profile') ? undefined : CareerProfileFromJSON(json['career_profile']),
    };
}

export function LoginStatusToJSON(value?: LoginStatus): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'username': value.username,
        'email': value.email,
        'name': value.name,
        'avatar_url': value.avatarUrl,
        'permissions': APIPermissionsToJSON(value.permissions),
        'language': value.language,
        'onboarding_status': value.onboardingStatus,
        'date_of_birth': value.dateOfBirth,
        'role': value.role,
        'cookie_consent': (value.cookieConsent),
        'has_verified_email': value.hasVerifiedEmail,
        'needs_auth_migration': value.needsAuthMigration,
        'has_class': value.hasClass,
        'curriculum_group': value.curriculumGroup,
        'career_profile': CareerProfileToJSON(value.careerProfile),
    };
}

/**
* @export
* @enum {string}
*/
export enum LoginStatusOnboardingStatusEnum {
    StepOne = 'step_one',
    Completed = 'completed'
}
/**
* @export
* @enum {string}
*/
export enum LoginStatusRoleEnum {
    Educator = 'educator',
    Learner = 'learner'
}


