import React, {useEffect, useRef, useState} from 'react';
import { Icon } from 'elements';

import messages from '../../../../career-explorer/displayMessages';
import { WrappedMessage } from 'utils';

interface SalaryCardProps {
  salaryLevels: {
    entry: number | undefined,
    mid: number | undefined,
    senior: number | undefined,
  };
}

export const SalaryCard: React.FC<SalaryCardProps> = ({
  salaryLevels,
}) => {
  const [sliderLevel, setSliderLevel] = useState(1);
  const [salary, setSalary] = useState(salaryLevels.entry);
  const [salaryLevel, setSalaryLevel] = useState(messages.salaryEntryLevelLabel);

  const sliderDotsRef = useRef<HTMLDivElement>(null);
  const [tooltipPosition, setTooltipPosition] = useState({ left: 0, top: 0 });

  useEffect(() => {
    if (sliderDotsRef.current) {
      const activeDot = sliderDotsRef.current.querySelector('.slider-dot.active');
      if (activeDot) {
        const { offsetLeft, offsetWidth, offsetTop } = activeDot as HTMLElement;
        setTooltipPosition({
          left: offsetLeft + offsetWidth / 2,
          top: offsetTop - 12,
        });
      }
    }
  }, [sliderLevel]);

  const handleSliderChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const sliderLevelValue = parseInt(event.target.value, 0);
    setSliderLevel(sliderLevelValue);

    switch (sliderLevelValue) {
      case 1:
        setSalaryLevel(messages.salaryEntryLevelLabel);
        return setSalary(salaryLevels.entry);
      case 2:
        setSalaryLevel(messages.salarySliderMidLevelText);
        return setSalary(salaryLevels.mid);
      case 3:
        setSalaryLevel(messages.salarySeniorLevelLabel);
        return setSalary(salaryLevels.senior);
    }
  };

  const sliderToolTipText = (sliderLevelValue: number) => {
    switch (sliderLevelValue) {
      case 1:
        return <WrappedMessage message={messages.salarySliderEntryLevelYearText} />;
      case 2:
        return <WrappedMessage message={messages.salarySliderMidLevelYearText} />;
      case 3:
        return <WrappedMessage message={messages.salarySliderSeniorLevelYearText} />;
      default:
        return '';
    }
  };

  return (
    <div className='salary-card'>
      <div className='header'>
        <h4 className='title'>
          <WrappedMessage message={salaryLevel} />
        </h4>
        <div className='salary-info'>
          <div className='salary-icon'>
            <Icon name='salary' fill='white' zoom='2em'/>
          </div>
          <div className='salary-text'>
            <div className='salary-label'>
              <WrappedMessage message={messages.averageSalaryLabel} />
            </div>
            <strong className='salary-amount'>
              ${salary?.toLocaleString()} <WrappedMessage message={messages.averageSalaryPerYearLabel} />
            </strong>
          </div>
        </div>
      </div>
      <div className='salary-card-divider'></div>
      <div className='content'>
        <div className='content-text'>
          <WrappedMessage message={messages.salarySliderHelpText} />
        </div>
        <div className='slider-container'>
          <div className='slider-dots' ref={sliderDotsRef}>
            {[...Array(3).keys()].map((num) => (
                <div key={num} className={`slider-dot ${sliderLevel === num + 1 ? 'active' : ''}`}></div>
            ))}
          </div>
          <span
              className='slider-tooltip'
              style={{
                left: `${tooltipPosition.left}px`,
                top: `${tooltipPosition.top - 30}px`, // Position tooltip 30px above the dot
              }}
          >
        {sliderToolTipText(sliderLevel)}
      </span>
          <input
            type='range'
            min='1'
            max='3'
            value={sliderLevel}
            onChange={handleSliderChange}
            className='slider'
          />
          <div className='salary-levels'>
            <div className='level entry'>
              <strong className='highlight'>
                <WrappedMessage message={messages.salarySliderEntryLevelText} />
              </strong>
              <WrappedMessage message={messages.salarySliderEntryLevelYearText} />
            </div>
            <div className='level'>
              <strong className='highlight'>
                <WrappedMessage message={messages.salarySliderMidLevelText} />
              </strong>
              <WrappedMessage message={messages.salarySliderMidLevelYearText} />
            </div>
            <div className='level'>
              <strong className='highlight'>
                <WrappedMessage message={messages.salarySliderSeniorLevelText} />
              </strong>
              <WrappedMessage message={messages.salarySliderSeniorLevelYearText} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};


interface CareerSalaryProps {
  entryLevelSalary: number | undefined;
  midLevelSalary: number | undefined;
  seniorLevelSalary: number | undefined;
}

const CareerSalary: React.FC<CareerSalaryProps> = ({
  entryLevelSalary,
  midLevelSalary,
  seniorLevelSalary,
}) => {
  return (
    <div className='career-salary-container'>
      <div className='title-container'>
        <h2 className='title'>
          <WrappedMessage message={messages.salarySectionHeading} />
        </h2>
      </div>
      <SalaryCard salaryLevels={{entry: entryLevelSalary, mid: midLevelSalary, senior: seniorLevelSalary}} />
      <div className='salary-footer-text'>
        <strong className='highlight'>
          <WrappedMessage message={messages.salaryFooterTextPart1} /> {' '}
        </strong>
        <span><WrappedMessage message={messages.salaryFooterTextPart2} /></span>
      </div>
    </div>
  );
};

export default CareerSalary;
