import * as React from 'react';
import { FC, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import ReactCountryFlag from 'react-country-flag';
import Skeleton from 'react-loading-skeleton';
import get from 'lodash/get';

import { CountryMap } from 'elements';
import { CurriculumsList } from 'labxchange-client';
import { Button, StandardPageLayout, showErrorMessage } from 'ui/components';
import { CurriculumsApi } from 'global/api';
import { ROUTES } from 'global/constants';
import { WrappedMessage, getUserCountryCode, COUNTRY_CODE_CURRICULUM_SLUG_MAP } from 'utils';
import { analyticsInstance } from 'tracking';
import { EVENT_NAMES } from 'tracking/constants';

import messages from '../../displayMessages';

export const CurriculumsPage: FC = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [curriculumsList, setCurriculumsList] = useState<CurriculumsList[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const response: CurriculumsList[] = await CurriculumsApi.list();
        setCurriculumsList(response || []);
      } catch (error) {
        setCurriculumsList([]);
        showErrorMessage(
          <WrappedMessage message={messages.curriculumsPageLoadingError} />
        );
      }
      setLoading(false);
    };
    fetchData();
  }, []);

  const curriculumsPageHeader = () => {
    return (
      <header className='curriculums-page-header'>
          <h2 className='curriculums-page-title container'>
            <WrappedMessage message={messages.curriculumsPageHeaderTitle} />
            <span className='curriculums-title-new-pill'>
              <WrappedMessage message={messages.curriculumsPageHeaderPill} />
            </span>
          </h2>
      </header>
    );
  };

  const renderCurriculums = () => {
    const publishedCurriculums: CurriculumsList[] = [];
    const comingSoonCurriculums: CurriculumsList[] = [];

    curriculumsList.forEach((c) => {
      c.isPublic ? publishedCurriculums.push(c) : comingSoonCurriculums.push(c);
    });

    const userCountryCode = getUserCountryCode();
    const userCountryCurriculumSlug = get(COUNTRY_CODE_CURRICULUM_SLUG_MAP, userCountryCode);

    // Sort curriculums based on user's country
    /* istanbul ignore next */
    if (userCountryCode) {
      publishedCurriculums.sort((a, b) => {
        if ((a.country as any).code === userCountryCode) return -1;
        if ((b.country as any).code === userCountryCode) return 1;
        return 0;
      });
    }

    return (
      <>
        <div className='available-curriculums'>
          <h2 className='available-curriculums-heading'>
            <WrappedMessage message={messages.curriculumsPageSectionTitle} />
          </h2>
          <p className='available-curriculum-text'>
            <WrappedMessage message={messages.curriculumsPageSectionParagraph} />
          </p>
          <ul className='available-curriculums-cards' data-testid='available-curriculum-cards'>
            {publishedCurriculums.map((curriculum: CurriculumsList) => (
              <Link
                to={{
                  pathname: curriculum.slug === userCountryCurriculumSlug ?
                    ROUTES.Curriculum.DETAIL_SLUG(userCountryCurriculumSlug) :
                    curriculum.firstModuleLink,
                  state: { from: ROUTES.Curriculum.HOME }
                }}
                className='curriculum-card-link'
                onClick={() => analyticsInstance.track(EVENT_NAMES.CurriculumPageCurriculumCardClicked, {
                  curriculum_slug: curriculum.slug,
                  curriculum_name: curriculum.name,
                  curriculum_country: (curriculum.country as any).name,
                  url: window.location.toString()
                })}
                data-testid='curriculum-card-link'
              >
                <li className='curriculum-card'>
                  <span
                    className='curriculum-country-flag'
                    aria-label={`${(curriculum.country as any).name}'s flag`}>
                    <ReactCountryFlag
                      countryCode={(curriculum.country as any).code}
                      svg
                      style={{ fontSize: '24px' }}
                    />
                  </span>
                  <div className='curriculum-card-map'>
                    <CountryMap
                      countryName={(curriculum.country as any).name}
                      countryCode={(curriculum.country as any).code.toLowerCase()}
                      fill='#99B2C4' />
                  </div>
                  <div className='curriculum-card-details'>
                    <h3 className='curriculum-card-heading'>
                      {curriculum.slug.toUpperCase()} ({(curriculum.country as any).name})
                    </h3>
                    <p className='curriculum-card-name'> {curriculum.name} </p>
                    {curriculum.firstModuleLink && publishedCurriculums.length < 2 &&
                      <Button
                        className='curriculum-card-cta'
                        label={messages.curriculumsPageCardButtonText}
                        btnStyle='normal'
                      />
                    }
                  </div>
                </li>
              </Link>
            ))}
          </ul>
        </div>
        <div className='curriculums-footer'>
          <div className='curriculums-footer-section'>
            <div className='coming-soon-curriculums'>
              <h2 className='coming-soon-heading'>
                <WrappedMessage message={messages.curriculumsPageComingSoonText} />
              </h2>
              <ul className='coming-soon-list'>
                {comingSoonCurriculums.map((curriculum: CurriculumsList) => (
                  <li
                    className='coming-soon-curriculum-item'
                    aria-label={`${(curriculum.country as any).name}'s flag`}
                  >
                    <ReactCountryFlag
                      countryCode={(curriculum.country as any).code}
                      svg
                      style={{ fontSize: '24px' }}
                    />
                    <span className='coming-soon-curriculum-name'>
                      {curriculum.name} ({curriculum.slug.toUpperCase()})
                    </span>
                  </li>
                ))}
              </ul>
            </div>
            <div className='request-curriculum'>
              <h2 className='request-curriculum-title'>
                <WrappedMessage message={messages.curriculumsPageRequestTitle} />
              </h2>
              <p className='request-curriculum-text'>
                <WrappedMessage message={messages.curriculumsPageRequestParagraphText} />
              </p>
              <a
                href='https://forms.gle/rNipAg4cYwbQpeX89'
                target='__blank'
                className='request-curriculum-link'
              >
                <WrappedMessage message={messages.curriculumsPageRequestButtonText} />
              </a>
            </div>
          </div>
        </div>
      </>
    );
  };

  const renderSkeleton = () => {
    return (
      <div data-testid='loading-container'>
        <div className='available-curriculums'>
          <h2 className='available-curriculums-heading'>
            <Skeleton width='100%' height={50} />
          </h2>
          <br/>
          <div className='available-curriculums-card'>
            <div className='curriculum-card'>
              <Skeleton width='100%' height={200} />
            </div>
          </div>
          <br/>
          <div className='available-curriculums-card'>
            <div className='curriculum-card'>
              <Skeleton width='100%' height={200} />
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <StandardPageLayout
        backgroundStyle='light-header'
        mainClassName='curriculums-list-page'
        pageTitle={messages.curriculumPageTitle}
        pageTitleValues={{ name: 'Browse by Curriculum' }}
        headerFeature={curriculumsPageHeader()}
    >
      {(!loading && curriculumsList.length) ? renderCurriculums() : renderSkeleton()}
    </StandardPageLayout>
  );
};
