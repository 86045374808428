/* istanbul ignore file */
import { debounce } from 'lodash';
import bind from 'bind-decorator';
import * as React from 'react';
import { Redirect } from 'react-router';
import { connect } from 'react-redux';
import { SearchApi } from 'global/api';
import { analyticsInstance } from 'tracking';
import { EVENT_NAMES } from 'tracking/constants';

import { Icon } from 'elements';
import { RichText } from 'elements/components/RichText';
import { buildSearchURL } from 'search/utils';
import messages from 'ui/components/displayMessages';
import { intl, localeInfo } from 'i18n';
import {ROUTES} from 'global/constants';
import { RootState } from 'global/state';

import Select, { components, ValueType, OptionsType, OptionProps, IndicatorProps, InputActionMeta } from 'react-select';
import { WrappedMessage, showSearchBarInHero } from 'utils';

interface Option {
    value: string;
    label: string;
}

// Adding language parameter to SearchBox will make sure that
// search from anywhere (execpt people page) on site will use
// the language filter by default
const LANG_SEARCH_PARAM = `Language:${localeInfo.key}`;

// Default delay for the debounce function
const SEARCH_DEBOUNCE_DELAY = 200;

// we need to override these default components to include a
// search icon and highlight the query
const { DropdownIndicator, Option } = components;

interface SearchBoxIconProps {
    onClick: (event: React.MouseEvent<HTMLButtonElement> | React.TouchEvent<HTMLButtonElement>) => void;
    darkBackground: boolean;
}

const SearchBoxIcon: React.FC<SearchBoxIconProps> = ({onClick, darkBackground}) => (
    <button
        title={intl.formatMessage(messages.uiNavSearchButton)}
        className='show-search-box p-0 mr-3 mr-sm-0'
        onClick={onClick}
    >
        <Icon
            name='search-alt'
            zoom='1.5em'
            fill={darkBackground ? 'white' : '#003E6B'}
            className='show-search-box'
        />
    </button>
);

interface SearchBoxFullProps extends React.PropsWithChildren{
    onSelectChange: (selectedOption: ValueType<Option>) => void;
    onInputChange: (inputValue: string, action: InputActionMeta) => void;
    onClick: (event: React.MouseEvent<HTMLButtonElement> | React.TouchEvent<HTMLButtonElement>) => void;
    onClearSearchValue: (event: React.MouseEvent<HTMLButtonElement>) => void;
    onBlur: () => void;
    onKeyDown: (event: React.KeyboardEvent<HTMLElement>) => void;
    inputRef: React.RefObject<HTMLInputElement>;
    selectRef: React.RefObject<Select>;
    searchMessage: string;
    darkBackground: boolean;
    searchSuggestions: OptionsType<Option>;
    searchTerm: string;
    isFocusedOption: boolean;
}

interface CustomDropdownIndicatorProps extends IndicatorProps<any> {
    darkBackground: boolean;
    onClick: (event: React.MouseEvent<HTMLButtonElement> | React.TouchEvent<HTMLButtonElement>) => void;
}

const SearchBoxFull: React.FC<SearchBoxFullProps> = (props: SearchBoxFullProps) => {

    const CustomOption: React.FC<OptionProps<any>> = (optionProps) => {
        if (!optionProps.selectProps.isFocusedOption) {
            optionProps = { ...optionProps, isFocused: false };
        }
        const { innerProps, label, isFocused } = optionProps;
        const regex = new RegExp(`(${props.searchTerm})`, 'gi');
        const highlightedLabel = label.replace(regex, '<strong>$1</strong>');

        return (
            <Option className={`search-option ${isFocused ? 'is-focused' : ''}`} {...optionProps}>
              <div className='search-label' {...innerProps}>
              <Icon
                    name='search-suggestion'
                    className='show-search-box'
                />
                <RichText innerHtml={highlightedLabel} />
              </div>
            </Option>
          );
    };

    const CustomDropdownIndicator = (dropDownProps: CustomDropdownIndicatorProps) => {
        const {darkBackground, onClick, innerProps} = dropDownProps;
        return (
            <DropdownIndicator {...dropDownProps} innerProps={{
                ...innerProps,
                // need to override these events to fire our custom event
                onMouseDown: (e: React.MouseEvent<HTMLButtonElement>) => {
                    e.stopPropagation();
                    e.preventDefault();
                },
                onTouchEnd: (e: React.MouseEvent<HTMLButtonElement>) => {
                    e.stopPropagation();
                    e.preventDefault();
                }
                }}
            >
            <div className='select-indicators'>
                {props.searchTerm && showSearchBarInHero() &&
                    <button className='clear-search-query' onClick={props.onClearSearchValue}>
                        <span className='sr-only'>
                            <WrappedMessage message={messages.clearSearchQueryFromSearchBar} />
                        </span>
                        <Icon name='clear'/>
                    </button>
                }
                <div className='search-icon'>
                    <button
                    title={intl.formatMessage(messages.uiNavSearchButton)}
                    className='input-group-append'
                    onClick={onClick}
                    onTouchEnd={onClick}
                    tabIndex={-1}
                    >
                    <span className='text-header bg-transparent'>
                    <Icon
                        name='search-autocomplete'
                        fill={darkBackground ? '#FFF': '#003E6B'}
                        className='show-search-box control-svg'
                    />
                    </span>
                    </button>
                </div>
            </div>
          </DropdownIndicator>
        );
      };

    const containerClassName = `search-container ${props.darkBackground ? 'search-bar-dark' : 'search-bar-light'} ${props.searchSuggestions.length > 0 ? 'has-data' : ''}`;

    return (
        <div className='search-box-full input-group'>
            <Select
                className={containerClassName}
                classNamePrefix='search-select'
                options={props.searchSuggestions}
                onChange={props.onSelectChange}
                inputValue={ showSearchBarInHero() ? props.searchTerm : undefined}
                onInputChange={props.onInputChange}
                ref={props.selectRef}
                onBlur={props.onBlur}
                onBlurResetsInput={false}
                onKeyDown={props.onKeyDown}
                isFocusedOption={props.isFocusedOption}
                noOptionsMessage={() => null}
                placeholder={props.searchMessage}
                aria-label={props.searchMessage}
                components={{Option: CustomOption,
                    DropdownIndicator: (indicatorProps) => (
                    <CustomDropdownIndicator {...indicatorProps} darkBackground={props.darkBackground} onClick={props.onClick} />),
                }}
            />
        </div>
    );
  };

export interface SearchBoxProps {
    searchUrl: string;
    searchTitleUrl?: string;
    darkBackground: boolean;
    showAlwaysFullSearchBar?: boolean;
    searchParams?: string[];
    searchTerm?: string;
    totalPublicAssetCount: string;
    onSearchCallback?: (searchTerm: string) => void;
}

export interface SearchBoxState {
    // When the user enters search keywords and hits enter, we use this to redirect them to the search page
    goToSearchURL: string;
    isMobileView: boolean;
    showFullSearchBar: boolean;
    searchTerm: string;
    searchSuggestions: OptionsType<Option>;
    isFocusedOption: boolean;
}

@connect((state: RootState) => ({
    totalPublicAssetCount: state.libraryState.totalPublicAssetCount,
}))
export class SearchBox extends React.Component<SearchBoxProps, SearchBoxState> {

    private inputRef: React.RefObject<HTMLInputElement>;
    private selectRef: React.RefObject<Select>;

    // This is a custom break point to fix search bar in header.
    private mediaQuery = window.matchMedia('(max-width: 970px)');

    constructor(props: SearchBoxProps)  {
        super(props);
        this.inputRef = React.createRef();
        this.selectRef = React.createRef();
        this.state = {
            goToSearchURL: '',
            isMobileView: false,
            showFullSearchBar: false,
            searchTerm: props.searchTerm ? props.searchTerm : '',
            searchSuggestions: [],
            isFocusedOption: false,
        };
    }

    private debouncedSearch = debounce(this.sendSearchSuggestionsRequest, SEARCH_DEBOUNCE_DELAY);

    componentWillUnmount() {
        // Clear the debounce function to avoid memory leaks
        this.debouncedSearch.cancel();
    }

    public componentDidMount() {
        this.mediaQuery.addListener(this.setIsMobileView);
        this.setIsMobileView();
    }

    public componentDidUpdate() {
        if (this.state.isMobileView && !showSearchBarInHero() && this.selectRef.current) {
            this.selectRef.current.focus();
        }
    }

    public render() {
        if (this.state.goToSearchURL) {
            return <Redirect to={this.state.goToSearchURL} />;
        }
        return (
            <>
                {(this.state.isMobileView && !showSearchBarInHero() && !this.state.showFullSearchBar && !this.props.showAlwaysFullSearchBar)
                    ? <SearchBoxIcon onClick={this.onClick} darkBackground={this.props.darkBackground} />
                    : <SearchBoxFull
                        onClick={this.onClick}
                        onSelectChange={this.onSelectChange}
                        onInputChange={this.onInputChange}
                        onBlur={this.onBlur}
                        onKeyDown={this.onKeyDown}
                        inputRef={this.inputRef}
                        selectRef={this.selectRef}
                        searchMessage={intl.formatMessage(messages.uiNavSearch, {count: this.props.totalPublicAssetCount})}
                        darkBackground={this.props.darkBackground}
                        searchSuggestions={this.state.searchSuggestions}
                        searchTerm={this.state.searchTerm}
                        isFocusedOption={this.state.isFocusedOption}
                        onClearSearchValue={this.onClearSearchValue}
                    >
                    </SearchBoxFull>
                }
            </>
        );
    }

    @bind private setIsMobileView(): void {
        const isMobileView = this.mediaQuery.matches;
        this.setState({isMobileView, showFullSearchBar: !isMobileView});
    }

    @bind private onKeyDown (event: React.KeyboardEvent<HTMLElement>)  {
        if (event.key === 'ArrowUp' || event.key === 'ArrowDown') {
            this.setState({isFocusedOption: true});
        } else if(event.key === 'Enter') {
            if (!this.state.isFocusedOption && this.state.searchTerm) {
                event.preventDefault();
                this.onSearchQueryEntered(this.state.searchTerm);
                this.clearSearchState();
            }
        }
    }

    @bind private onSelectChange(selectedOption: ValueType<Option>) {
        if (selectedOption && 'label' in selectedOption) {
            this.createSearchSugggestionSelectedEvent(selectedOption.label);
            this.onSearchQueryEntered(selectedOption.label);
            this.clearSearchState();
        }
    }

    @bind private onInputChange(inputValue: string, action : InputActionMeta) {
        // Only update the search term if the user types something.
        // This will prevent clearing the input on blur.
        if (action.action === 'input-change') {
            this.setState({ searchTerm: inputValue });
        }

        // in the first phase, we are only handling this if the user is searching in the library
        const currentPath = window.location.pathname.replace(/\/$/, '');
        const startsWithLibrary = window.location.pathname.startsWith('/library');

        if (inputValue && (startsWithLibrary || [ROUTES.Explore.HOME, ROUTES.Subjects, ROUTES.Teach, ''].includes(currentPath))) {
            if (this.selectRef.current) {
                // by default it focuses first element
                (this.selectRef.current.select as any).getNextFocusedOption = () => null;
            }
            this.debouncedSearch();
        }
    }

    @bind private onClick(event: React.MouseEvent<HTMLButtonElement> | React.TouchEvent<HTMLButtonElement>) {
        event.preventDefault();
        const {searchTerm} = this.state;
       if (searchTerm) {
            this.onSearchQueryEntered(searchTerm);
            this.clearSearchState();
        } else if (this.state.isMobileView && !showSearchBarInHero()) {
            this.setState({showFullSearchBar: !this.state.showFullSearchBar});
        }
        if (this.props.onClickCallback) {
            this.props.onClickCallback(searchTerm);
        }
    }

    @bind private clearSearchState() {
        this.setState({ searchSuggestions:[], showFullSearchBar: false });
    }

    @bind private clearSearchSuggestions() {
        this.setState({
            searchSuggestions: []
        });
    }

    @bind private onBlur() {
        this.createSearchSugggestionsTerminatedEvent();
        this.clearSearchSuggestions();
        this.setState({showFullSearchBar: false});
    }

    @bind private async sendSearchSuggestionsRequest() {
        const { searchTerm } = this.state;

        // Only show suggestions if the search term has at least two characters.
        if (searchTerm.length > 1) {
            try {
                const responseData = await SearchApi.popularQueries({search: searchTerm});
                this.setState({
                    searchSuggestions: responseData.results.map(suggestion => ({
                      value: suggestion.id.toString(),
                      label: suggestion.query
                    }))
                  });
            }
            catch (err) {
                this.clearSearchSuggestions();
            }
        } else {
            this.clearSearchSuggestions();
        }
    }

    @bind private extractReferenceId(searchQuery: string): string | null {
        // Define the pattern for extracting References:id
        const pattern = /References:([^ ]+)/;

        const match = pattern.exec(searchQuery);
        if (match && match[0]) {
          return match[0]; // Return the captured group i.e References:lb:LabXchange:1fb8b9d6:lx_simulation:2
        }
        return null; // Return null if no match is found
    }

    @bind private onSearchQueryEntered(keyword: string) {
        const { searchParams, searchUrl } = this.props;

        // extract Refrences param if user has made query on References i.e References:lb:LabXchange:1fb8b9d6:lx_simulation:2
        const referenceParam = this.extractReferenceId(keyword);

        const moreParams: ReadonlySet<string> = (referenceParam && LANG_SEARCH_PARAM)
            ? new Set([referenceParam, LANG_SEARCH_PARAM])
            : (LANG_SEARCH_PARAM ? new Set([LANG_SEARCH_PARAM]) : new Set());

        if(referenceParam) {
            // remove reference param from search query
            keyword = keyword.replace(referenceParam, '');
        }

        // apply language filter by default except on the people page
        // apply refrence filter as well if found in query
        const updatedSearchParams = searchUrl !== '/people'
            ? (searchParams ? [...searchParams, ...moreParams] : moreParams)
            : searchParams;

        const newURL = buildSearchURL(searchUrl, new Set([keyword]),
            new Set(updatedSearchParams));

        if (this.props.onSearchCallback) {
            this.props.onSearchCallback(keyword);
        }

        this.setState({ goToSearchURL: newURL}, () => {
            // Avoid an infinite loop by clearing this after.
            this.setState({ goToSearchURL: '', searchTerm: keyword });
        });
    }

    @bind private createSearchSugggestionSelectedEvent(suggestion: string) {
        const index = this.state.searchSuggestions.findIndex(option => option.label === suggestion) + 1;
        analyticsInstance.track(
            EVENT_NAMES.AssetSearchSuggestionSelected,
            {
                selectedSuggestion: suggestion,
                searchTerm: this.state.searchTerm,
                selectedSuggestionPosition: index.toString(),
                totalSuggestions: this.state.searchSuggestions.length.toString(),
                pathname: window.location.pathname,
                url: window.location.toString(),
            },
      );
    }

    @bind private createSearchSugggestionsTerminatedEvent() {
        if (this.state.searchTerm) {
            analyticsInstance.track(
                EVENT_NAMES.AssetSearchSuggestionTerminated,
                {
                    searchTerm: this.state.searchTerm,
                    totalSuggestions: this.state.searchSuggestions.length.toString(),
                    url: window.location.toString(),
                },
            );
        }
    }

    @bind private onClearSearchValue() {
        this.setState({searchTerm: ''});
    }
}
