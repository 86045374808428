import React, { useState, useEffect } from 'react';
import { intl } from 'i18n';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { Icon } from 'elements';
import { FeaturedItems, PathwayDetail, ItemMetadata, Module } from 'labxchange-client';
import uiMessages from 'ui/components/displayMessages';
import { ItemsApi } from 'global/api';
import { ROUTES } from 'global/constants';
import { WrappedMessage } from 'utils';
import { showErrorMessage, showSuccessMessage } from 'ui/components/GlobalMessageReporter/dispatch';
import { getLoggedInStatus } from 'auth/selectors';

import { PathwayCard } from './PathwayCard';
import { PathwayCardSkeleton } from './PathwayCardSkeleton';
import { useLayoutSize } from 'utils';
import messages from './displayMessages';

interface PathwaysCarouselProps {
    pathways: FeaturedItems | undefined;
    showExploreButton?: boolean;
    onClickExploreButton?: (pathway: ItemMetadata) => void;
    onClickPathwayImage?: (pathway: ItemMetadata) => void;
    onClickPathwayItem?: (item: ItemMetadata, pathwayId: string) => void;
    showFeedbackButton?: boolean;
    curriculumSlug?: string;
    module?: Module;
}

type PathwaysType = { [key: string]: PathwayDetail };

export const PathwaysCarousel: React.FC<PathwaysCarouselProps> = (props) => {
    const layoutSize = useLayoutSize();
    const isMobileView = layoutSize === 'mobile' || layoutSize === 'small' || layoutSize === 'medium';
    const [currentId, setCurrentId] = useState(0);
    const [loading, setLoading] = useState(false);
    const [pathways, setPathways] = useState<PathwaysType>({});
    const isUserLoggedIn = useSelector(getLoggedInStatus);
    const history = useHistory();
    const [feedbackState, setFeedbackState] = useState<{ [key: string]: boolean }>({});

    useEffect(() => {
        loadAllPathwayDetails();
    }, [props.pathways]);

    const loadAllPathwayDetails = async () => {
        if (!props.pathways) return;
        setLoading(true);
        try {
            const fetchPromises = props.pathways.items.map(
                pathway => ItemsApi.pathway({ id: pathway.metadata.id })
            );
            const pathwayDetailsArray = await Promise.all(fetchPromises);
            const pathwaysMap = pathwayDetailsArray.reduce((acc: PathwaysType, detail, index) => {
                const id = props.pathways?.items[index].metadata.id;
                if (id) acc[id] = detail;
                return acc;
            }, {});

            setLoading(false);
            setPathways(pathwaysMap);
        } catch (error) {
            setLoading(false);
        }
    };

    const onClone = async (pathwayId: string) => {
        if (!isUserLoggedIn) {
            history.push(ROUTES.General.SIGN_UP);
            return;
        }
        try {
            const newPathway = await ItemsApi.pathwayClone({ id: pathwayId });
            history.push(ROUTES.Library.PATHWAY_EDIT_SLUG(newPathway.metadata.id));
            showSuccessMessage(<WrappedMessage message={messages.pathwayEducatorCloneSucceeded} />);
        } catch (err) {
            showErrorMessage(<WrappedMessage message={messages.pathwayEducatorCloneFailed} />, {
                exception: err,
                details: 'Unable to clone Pathway',
            });
        }
    };

    const onView = (pathwayId: string) => {
        history.push(ROUTES.Library.PATHWAY_SLUG(pathwayId));
    };

    const goToNext = () => {
        if (!props.pathways) return;
        const numItems = props.pathways.items.length;
        const incrId = currentId + 1;
        setCurrentId(incrId >= numItems ? 0 : incrId);
    };

    const goToPrev = () => {
        if (!props.pathways) return;
        const numItems = props.pathways.items.length;
        const decrId = currentId - 1;
        setCurrentId(decrId < 0 ? numItems - 1 : decrId);
    };

    const handleFeedbackSubmit = (pathwayId: string) => {
        setFeedbackState((prevState) => ({
            ...prevState,
            [pathwayId]: true,
        }));
    };

    const renderPathwayCards = () => {
        const showSkeleton = !props.pathways || Object.keys(pathways).length === 0 || loading;
        if (showSkeleton) {
            const numSkeletons = isMobileView ? 3 : 1;
            return Array.from({ length: numSkeletons }, (_, index) => (
                <PathwayCardSkeleton key={index} />
            ));
        }

        if (props.pathways) {
            if (isMobileView) {
                return props.pathways.items.map((pathwayItem, index) => (
                    <PathwayCard
                        key={pathwayItem.metadata.id}
                        pathway={pathwayItem}
                        pathwayDetails={pathways[pathwayItem.metadata.id]}
                        onClone={onClone}
                        onView={onView}
                        pathwayIndex={index}
                        showExploreButton={props.showExploreButton}
                        onClickExploreButton={props.onClickExploreButton}
                        onClickPathwayImage={props.onClickPathwayImage}
                        onClickPathwayItem={props.onClickPathwayItem}
                        showFeedbackButton={props.showFeedbackButton}
                        curriculumSlug={props.curriculumSlug}
                        module={props.module}
                        feedbackSubmitted={feedbackState[pathwayItem.metadata.id] || false}
                        onFeedbackSubmit={handleFeedbackSubmit}
                    />
                ));
            }

            const pathway = props.pathways.items[currentId];
            return (
                <PathwayCard
                    key={pathway.metadata.id}
                    pathway={pathway}
                    pathwayDetails={pathways[pathway.metadata.id]}
                    onClone={onClone}
                    onView={onView}
                    showExploreButton={props.showExploreButton}
                    onClickExploreButton={props.onClickExploreButton}
                    onClickPathwayImage={props.onClickPathwayImage}
                    onClickPathwayItem={props.onClickPathwayItem}
                    showFeedbackButton={props.showFeedbackButton}
                    curriculumSlug={props.curriculumSlug}
                    module={props.module}
                    feedbackSubmitted={feedbackState[pathway.metadata.id] || false}
                    onFeedbackSubmit={handleFeedbackSubmit}
                />
            );
        }

        return null;
    };

    return (
        <div className='pathways-carousel'>
            <button
                className='previous-button'
                title={intl.formatMessage(uiMessages.uiPrevious)}
                onKeyDown={goToPrev}
                onClick={goToPrev}
            >
                <Icon name='chevron-left' />
            </button>
            {renderPathwayCards()}
            <button
                className='next-button'
                title={intl.formatMessage(uiMessages.uiNext)}
                onKeyDown={goToNext}
                onClick={goToNext}
            >
                <Icon name='chevron-right' />
            </button>
        </div>
    );
};

export default PathwaysCarousel;
