import React from 'react';
import classNames from 'classnames';
import { intl } from 'i18n';

import { sanitizeUnsafeHTML, sanitizeConfigOptions } from '@labxchange/util-sanitization';
import { Module, ItemResponse } from 'labxchange-client';
import { FeedbackModalModulePage, Icon } from 'ui/components';
import { detailUrlForEntity } from 'library/utils';
import { getItemTypeMeta } from 'items/models';
import { useLayoutSize } from 'utils';
import { analyticsInstance } from 'tracking';
import { EVENT_NAMES } from 'tracking/constants';
import uiMessages from 'ui/components/displayMessages';

interface ModuleAssetCardProps {
  asset: ItemResponse;
  curriculumSlug: string;
  module: Module;
  showFeedbackButton?: boolean;
  selectedFilters?: string[];
}

export const ModuleAssetCard = ({
  asset, showFeedbackButton, curriculumSlug, module, selectedFilters
} : ModuleAssetCardProps) => {
  const isMobile = ['small', 'mobile'].includes(useLayoutSize());

  const assetURL = detailUrlForEntity(asset.metadata);
  const defaultCardImage = getItemTypeMeta(asset.metadata.type)?.defaultCardImage || '/assets/images/default-featured.png';
  const assetStaticImageUrl = asset.metadata.imageUrl || defaultCardImage;
  const assetIdOnS3 = asset.metadata.id.replace(/:/g, '-');
  const assetGifURL = `https://media.labxchange.org/interactive_gifs/${assetIdOnS3}.gif`;

  const [showFeedbackModal, setShowFeedbackModal] = React.useState(false);
  const [feedbackSubmitted, setFeedbackSubmitted] = React.useState(false);
  const [backgroundImage, setBackgroundImage] = React.useState(`url(${assetStaticImageUrl})`);

  const handleMouseEnter = () => {
    const gif = new Image();
    gif.src = assetGifURL;
    gif.onload = () => {
      setBackgroundImage(`url(${assetGifURL})`);
    };
  };

  const handleMouseLeave = () => {
    setBackgroundImage(`url(${assetStaticImageUrl})`);
  };

  const handleCardClick = (e: React.MouseEvent) => {
    e.preventDefault();
    analyticsInstance.track(EVENT_NAMES.CurriculumModulePageAssetCardClicked, {
      asset_id: asset.metadata.id,
      asset_title: asset.metadata.title,
      asset_type: asset.metadata.type,
      asset_url: assetURL,
      module_id: module.id,
      module_slug: module.slug,
      active_version: module.activeVersion,
      curriculum_slug: curriculumSlug,
      selected_filters: selectedFilters,
      url: window.location.toString(),
    });
    window.open(assetURL, '_blank');
  };

  const handleFeedbackClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    e.preventDefault();
    setShowFeedbackModal(true);
  };

  const handleModalClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    e.preventDefault();
  };

  const renderFeedbackButtonModal = () => {
    const IsFeedbackSubmitted = feedbackSubmitted || asset.userAttributes.hasUserSubmittedFeedback;
    return (
      <div className={classNames('module-asset-feedback-btn',
        {
          'mobile-feedback-btn': isMobile,
          'feedback-submitted': IsFeedbackSubmitted,
        }
      )}>
        <button className='unstyled' onClick={handleFeedbackClick} >
            <Icon name={IsFeedbackSubmitted ? 'check' : 'thumbs-up-fab'} zoom='20' />
            <span>
              {intl.formatMessage(IsFeedbackSubmitted ? uiMessages.feedbackButtonSubmittedLabel : uiMessages.feedbackButtonDefaultLabel)}
            </span>
        </button>
        {showFeedbackModal &&
          <div className='feedback-modal-wrapper' onClick={handleModalClick}>
            <FeedbackModalModulePage
              assetId={asset.metadata.id}
              curriculumSlug={curriculumSlug}
              onClose={() => setShowFeedbackModal(false)}
              onSubmit={() => setFeedbackSubmitted(true)}
              module={module.id}
              coreConceptsHeading={module.learningObjectivesHeading.toLowerCase()}
            />
          </div>
        }
      </div>
    );
  };

  const renderSourceInfo = () => {
    const source = asset.metadata.source;
    const sourceOrg = source && source.sourceOrganizations && source.sourceOrganizations[0].organization;
    if (sourceOrg) {
      return (
        <span className='asset-source-org'>
          {sourceOrg.name}
        </span>
      );
    }

    if (asset.metadata.authors.length > 0) {
      return (
        <span className='asset-source-org'>
          {asset.metadata.authors[0].fullName}
        </span>
      );
    }

    return null;
  };

  return (
    <div className='module-asset-card' onClick={handleCardClick}>
      <div
        className='asset-image'
        role='presentation'
        style={{ backgroundImage }}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      />
      <div className='asset-details'>
        <div className='asset-title-feedback'>
          <span className='asset-title'>{asset.metadata.title}</span>
          {(showFeedbackButton && !isMobile) && renderFeedbackButtonModal()}
        </div>
        <div className='description-wrapper'>
          <div className='asset-description'>
            <span>{sanitizeUnsafeHTML(asset.metadata.description, sanitizeConfigOptions.StripHTML)}</span>
          </div>
          {renderSourceInfo()}
        </div>
        {(showFeedbackButton && isMobile) && renderFeedbackButtonModal()}
      </div>
    </div>
  );
};
