// tslint:disable
/**
 * LXBackend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime';
/**
 * 
 * @export
 * @interface ImpactOption
 */
export interface ImpactOption {
    /**
     * 
     * @type {number}
     * @memberof ImpactOption
     */
    readonly id?: number;
    /**
     * 
     * @type {string}
     * @memberof ImpactOption
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof ImpactOption
     */
    description: string;
}

export function ImpactOptionFromJSON(json: any): ImpactOption {
    return {
        'id': !exists(json, 'id') ? undefined : json['id'],
        'title': json['title'],
        'description': json['description'],
    };
}

export function ImpactOptionToJSON(value?: ImpactOption): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'title': value.title,
        'description': value.description,
    };
}


