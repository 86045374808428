import React from 'react';
import OnboardingPageLayout from './OnboardingPageLayout/OnboardingPageLayout';
import { intl } from 'i18n';
import messages from './displayMessages';
import CareerTag from '../CareerTag';
import { Icon } from 'elements';
import { WrappedMessage } from 'utils';
import { isKeyboardEnterEvent } from 'global/utils';
import { InterestsHobbiesOption } from 'labxchange-client';

export interface InterestsInputFieldProps {
    onSubmit: (value: string) => void;
}

export const InterestsInputField: React.FC<InterestsInputFieldProps> = (props) => {
    const inputRef = React.useRef<HTMLInputElement>(null);

    const handleSubmit = () => {
        if (inputRef.current && inputRef.current.value) {
            props.onSubmit(inputRef.current.value);
            inputRef.current.value = '';
        }
    };

    return (
        <div className='interests-input-field'>
            <input
                type='text'
                id='interests-input'
                className='input-field-element'
                placeholder={intl.formatMessage(messages.careerInterestsInputPlaceholder)}
                onKeyDown={(e) => isKeyboardEnterEvent(e) && handleSubmit()}
                ref={inputRef}
            />
            <div className='instructions'>
                <Icon name='info' zoom='16px' fill='#316387' />
                <span className='instructions-label'>
                    <WrappedMessage message={messages.careerInterestsInputInstructions} />
                </span>
            </div>
        </div>
    );
};

export interface InterestsStepProps {
    currentStep: number;
    interestsOptions?: InterestsHobbiesOption[];
    selectedInterests: string[];
    onButtonClick: (nextStep: number) => void;
    toggleInterests: (interests: string[]) => void;
}

export const InterestsStep: React.FC<InterestsStepProps> = ({
    currentStep, interestsOptions=[], selectedInterests, onButtonClick, toggleInterests
}) => {
    const onTagClick = (tag: React.ReactNode, addTag: boolean) => {
        if (addTag) {
            return toggleInterests([...selectedInterests, tag.toString()]);
        }

        return toggleInterests([...selectedInterests.filter((interest) => interest !== tag.toString())]);
    };

    const onSubmit = (value: string) => {
        const interests = value.split(',').map(interest => interest.trim()).filter(interest => interest.trim());
        return toggleInterests([...selectedInterests, ...interests]);
    };

    const getInterests = () => {
        let interests = interestsOptions;
        if (selectedInterests) {
            interests = [...interests.filter((inerest, i) => selectedInterests.indexOf(inerest.title) < 0)];
        }

        return interests.filter((__, i) => i < 6);
    };

    return (
        <OnboardingPageLayout
            title={intl.formatMessage(messages.careerInterestsOnboardingStepTitle)}
            description={intl.formatMessage(messages.careerInterestsOnboardingStepDescription)}
            currentStep={currentStep}
            onButtonClick={onButtonClick}
            showContinueAction={selectedInterests.length > 0}
        >
            <div className='career-interests-step'>
                <div className='career-interests'>
                    {getInterests().map((interest, i) =>
                        <CareerTag key={i} onClick={onTagClick} cssClass='career-interests__interest-button-style'>
                            {interest.title}
                        </CareerTag>
                    )}
                </div>
                <InterestsInputField onSubmit={onSubmit} />
                {selectedInterests.length > 0 &&
                    <div className='career-interests selected'>
                        {selectedInterests.map((interest, i) =>
                            <CareerTag key={i} active={true} onClick={onTagClick}>
                                {interest}
                            </CareerTag>
                        )}
                    </div>
                }
            </div>
        </OnboardingPageLayout>
    );
};

export default InterestsStep;
