import React, { useState } from 'react';
import { EdgeProps, Node, ReactFlowProvider } from 'reactflow';

import CustomEdge from './CustomEdge';
import CustomNode from './CustomNode';
import BaseFlowMap from '../BaseFlowMap';
import messages from './displayMessages';
import {useLayoutSize, WrappedMessage} from 'utils';
import useFullscreen from 'career-explorer/utils';
import SideBarParent from '../SideBarForms/SideBarParent';
import { Icon, IconSymbol } from 'elements';
import SidePanel from './SidePanel';
import Guide from './Guide';

type IconColors = {
    [key: string]: {
        [key: string]: string,
    };
};

export const iconColors: IconColors = {
    iconColor: {
        you: '#FAAC48',
        typical: '#33B7A7',
        alternative: '#7188C7',
    },
    iconBackground: {
        you: '#FEF3E5',
        typical: '#E2F5F3',
        alternative: '#EBEEF7',
    },
};

const PathwayHeader = () => {

    return (
        <>
            <h2>
                <WrappedMessage message={messages.educationPathwaySectionTitle} />
            </h2>
            <div className='header-items'>
                <div className='header-item'>
                    <div className='circle typical'></div>
                    <div className='label'>
                        <WrappedMessage message={messages.typicalPathTitle}/>
                    </div>
                </div>
                <div className='header-item'>
                    <div className='circle alternative'></div>
                    <div className='label'>
                    <WrappedMessage message={messages.alternativePathTitle}/>
                    </div>
                </div>
                <div className='header-item'>
                    <div className='circle you'></div>
                    <div className='label'>
                    <WrappedMessage message={messages.currentPathTitle}/>
                    </div>
                </div>
            </div>
        </>
    );
};

const edgeTypes = {
    default: CustomEdge,
};

const nodeTypes = {
    default: CustomNode,
};

interface EducationPathwayProps {
    pathwayConfig: {
        nodes: Node[];
        edges: EdgeProps[];
    };
}

interface DataRow {
    title: string;
    subTitle: string;
    iconName: IconSymbol;
}

export interface NodeDataProps {
    nodeName: IconSymbol;
    nodeType: string;
    label: string;
    description: string;
    completionLabel: string;
    dataRows: DataRow[];
    guide: any;
    otherCareers: any;
}

export const EducationPathway: React.FC<EducationPathwayProps> = ({ pathwayConfig }) => {
    const isMobile = ['small', 'mobile'].includes(useLayoutSize());
    const [selectedNode, setSelectedNode] = useState<NodeDataProps | null>(pathwayConfig?.nodes[0]?.data);
    const [showSideBar, setShowSideBar] = useState<boolean>(!isMobile);
    const [viewGuide, setViewGuide] = useState<boolean>(false);
    const [completionChecked, setCompletionChecked] = useState<boolean>(false);
    const { isFullscreen } = useFullscreen();
    const degrees = ['associate', 'bachelors', 'masters'];

    const handleNodeClick = (node: NodeDataProps) => {
        setViewGuide(false);
        setShowSideBar(true);
        setSelectedNode(node);
        setCompletionChecked(false);
    };

    const onCloseSideBar = () => {
        setShowSideBar(false);
        setSelectedNode(null);
        setViewGuide(false);
    };

    const toggleCompletionCheckbox = (newValue: boolean) => {
        setCompletionChecked(newValue);
    };

    const onViewGuide = (event: React.MouseEvent) => {
        event.preventDefault();
        setViewGuide(true);
    };

    const getNodeHeading = (nodeData: NodeDataProps) => {
        if (viewGuide) {
            return 'Guide';
        }
        return degrees.indexOf(nodeData.nodeName) < 0 ? nodeData.label : `${nodeData.label}'s degree`;
    };

    const getIcon = (iconName: IconSymbol, nodeType: string) => {
        const iconColor = iconColors.iconColor[nodeType];
        const iconBackground = iconColors.iconBackground[nodeType];
        return (
            <div className='side-panel__icon' style={{backgroundColor: iconBackground}}>
                <Icon name={iconName} zoom='24px' fill={iconColor} />
            </div>
        );
    };

    if (!pathwayConfig.nodes) {
        return null;
    }

    return (
        <div className='education-pathway-container'>
            <PathwayHeader />
            <ReactFlowProvider>
            <BaseFlowMap
                flowMapProps={{
                    nodes: pathwayConfig.nodes.map((node: any) => ({
                        ...node,
                        className: node.data.nodeType,
                        selected: node.data.nodeName === selectedNode?.nodeName
                    })),
                    edges: pathwayConfig.edges,
                    edgeTypes,
                    nodeTypes,
                    fitView: true,
                    panOnScroll: false,
                    zoomOnScroll: false,
                    zoomOnPinch: false,
                    maxZoom: 1,
                    onNodeClick: (_, node) => handleNodeClick(node.data),
                }}
            >
                {selectedNode &&
                    <SideBarParent
                        heading={getNodeHeading(selectedNode)}
                        description={viewGuide && selectedNode.guide.details ? selectedNode.guide.details.description : selectedNode.description}
                        completionLabel={selectedNode.completionLabel}
                        onClose={onCloseSideBar}
                        isOpen={showSideBar}
                        isFullScreen={isFullscreen}
                        iconComponent={getIcon(selectedNode.nodeName, selectedNode.nodeType)}
                        className={selectedNode.nodeType}
                        completionChecked={completionChecked}
                        toggleCompletionCheckbox={toggleCompletionCheckbox}
                    >
                        {viewGuide && selectedNode.guide ?
                            <Guide guideDetails={selectedNode.guide.details} />
                            :
                            <SidePanel nodeData={selectedNode} onViewGuide={onViewGuide} />
                        }
                    </SideBarParent>
                }
            </BaseFlowMap>
            </ReactFlowProvider>
        </div>
    );
};
