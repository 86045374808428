// tslint:disable
/**
 * LXBackend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime';
import {
    CareerSubcategory,
    CareerSubcategoryFromJSON,
    CareerSubcategoryToJSON,
    Qualification,
    QualificationFromJSON,
    QualificationToJSON,
} from './';

/**
 * 
 * @export
 * @interface CareerList
 */
export interface CareerList {
    /**
     * A unique human-readable name, e.g., 'ent-specialist'
     * @type {string}
     * @memberof CareerList
     */
    slug: string;
    /**
     * 
     * @type {string}
     * @memberof CareerList
     */
    title: string;
    /**
     * SVG icon for the career.
     * @type {string}
     * @memberof CareerList
     */
    readonly icon?: string;
    /**
     * 
     * @type {Array<CareerSubcategory>}
     * @memberof CareerList
     */
    subcategories: Array<CareerSubcategory>;
    /**
     * 
     * @type {Qualification}
     * @memberof CareerList
     */
    minimumQualification: Qualification;
    /**
     * 
     * @type {number}
     * @memberof CareerList
     */
    entryLevelSalary?: number;
    /**
     * 
     * @type {number}
     * @memberof CareerList
     */
    midLevelSalary?: number;
    /**
     * 
     * @type {number}
     * @memberof CareerList
     */
    seniorLevelSalary?: number;
    /**
     * Whether the career is published or not.
     * @type {boolean}
     * @memberof CareerList
     */
    isPublished?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CareerList
     */
    description: string;
    /**
     * A list of alternative names by which this career is known.
     * @type {Array<string>}
     * @memberof CareerList
     */
    alternativeNames: Array<string>;
    /**
     * A breakdown of the pronunciation of the career title.
     * @type {string}
     * @memberof CareerList
     */
    pronunciation: string;
    /**
     * Editors Note: Please ensure that given HTML content follows WCAG 2.1 guidelines.Following HTML tags can be used:<br>&lt;i&gt; and &lt;em&gt;  for italics<br>&lt;b&gt; and &lt;strong&gt; for bold<br>&lt;sup&gt; for superscript<br>&lt;sub&gt; for subscript<br>&lt;a&gt; for link<br>&lt;h&gt;, &lt;p&gt;, &lt;ul&gt;, &lt;ol&gt; and &lt;li&gt; for text headings, paragraphs and lists.
     * @type {string}
     * @memberof CareerList
     */
    references?: string;
    /**
     * 
     * @type {string}
     * @memberof CareerList
     */
    subheading: string;
}

export function CareerListFromJSON(json: any): CareerList {
    return {
        'slug': json['slug'],
        'title': json['title'],
        'icon': !exists(json, 'icon') ? undefined : json['icon'],
        'subcategories': (json['subcategories'] as Array<any>).map(CareerSubcategoryFromJSON),
        'minimumQualification': QualificationFromJSON(json['minimum_qualification']),
        'entryLevelSalary': !exists(json, 'entry_level_salary') ? undefined : json['entry_level_salary'],
        'midLevelSalary': !exists(json, 'mid_level_salary') ? undefined : json['mid_level_salary'],
        'seniorLevelSalary': !exists(json, 'senior_level_salary') ? undefined : json['senior_level_salary'],
        'isPublished': !exists(json, 'is_published') ? undefined : json['is_published'],
        'description': json['description'],
        'alternativeNames': json['alternative_names'],
        'pronunciation': json['pronunciation'],
        'references': !exists(json, 'references') ? undefined : json['references'],
        'subheading': json['subheading'],
    };
}

export function CareerListToJSON(value?: CareerList): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'slug': value.slug,
        'title': value.title,
        'subcategories': (value.subcategories as Array<any>).map(CareerSubcategoryToJSON),
        'minimum_qualification': QualificationToJSON(value.minimumQualification),
        'entry_level_salary': value.entryLevelSalary,
        'mid_level_salary': value.midLevelSalary,
        'senior_level_salary': value.seniorLevelSalary,
        'is_published': value.isPublished,
        'description': value.description,
        'alternative_names': value.alternativeNames,
        'pronunciation': value.pronunciation,
        'references': value.references,
        'subheading': value.subheading,
    };
}


