import React from 'react';
import Skeleton from 'react-loading-skeleton';

export const ModuleAssetCardSkeleton = () => (
  <div className='module-asset-card module-asset-card-skeleton'>
    <Skeleton className='asset-image' />
    <div className='asset-details'>
      <div className='asset-title-feedback'>
        <Skeleton height={30} width={250} />
      </div>
      <div className='description-wrapper'>
        <div className='asset-description'>
          <Skeleton height={20} width='70%' />
        </div>
        <div className='asset-source-org'>
          <Skeleton height={20} width={100} />
        </div>
      </div>
    </div>
  </div>
);

export default ModuleAssetCardSkeleton;
