import * as React from 'react';
import { connect } from 'react-redux';
import get from 'lodash/get';

import { getLoggedInStatus, getLoginErrorStatus, getRole } from 'auth/selectors';
import { LxConfig, ROUTES } from 'global/constants';
import { RootState } from 'global/state';
import { random } from 'global/utils';

import { Category, ExploreContent } from 'explore/components';
import { SignInLink} from 'elements';
import { Button, StandardPageLayout } from 'ui/components';
import uiMessages from 'ui/components/displayMessages';
import classRoomMessages from '../../../classrooms/components/ClassroomsDashboardContent/displayMessages';
import { WrappedMessage, getUserCountryCode, COUNTRY_CODE_CURRICULUM_SLUG_MAP } from 'utils';
import { intl } from 'i18n';

import messages from '../displayMessages';
import { PartnerProps, PartnerSection } from './Partners';
import { PromoVideo } from './PromoVideo';
import { TestimonialProps, TestimonialSection } from './Testimonials';

import { HarvardUniversityLogo } from '../../../ui/components/HarvardUniversityLogo';
import amgenFoundation from 'assets/images/amgen-foundation.png';
import joinUsHexLeft from 'assets/images/join-hex-left.svg';
import joinUsHexRight from 'assets/images/join-hex-right.svg';

import { Link } from 'react-router-dom';
import { history } from 'global/history';
import { authLocalStorageManager } from 'auth/utils';
import { analyticsInstance } from 'tracking';
import { EVENT_NAMES } from 'tracking/constants';
import * as LoginActions from 'auth/actions';
import { CurriculumHeroSection } from '../CurriculumHeroSection';


const partners: PartnerProps[] = [
    {
        name: 'PhET',
        img: '/assets/images/partners/phet.svg',
        url: '/org/PhET',
    },
    {
        name: 'Khan Academy',
        img: '/assets/images/partners/khan-academy-new.svg',
        url: '/org/KhanAcademy',
    },
    {
        name: 'The Jackson Laboratory',
        img: '/assets/images/partners/jackson-labs.svg',
        url: '/org/jacksonlaboratory',
    },
    {
        name: 'Howard Hughes Medical Institute',
        img: '/assets/images/partners/hhmi.png',
        url: '/org/HHMIBiointeractive',
    },
    {
        name: 'Learning for Justice',
        img: '/assets/images/partners/learning-for-justice.svg',
        url: '/org/learningforjustice',
    },
    {
        name: 'Our World in Data',
        img: '/assets/images/partners/our-world-in-data.svg',
        url: '/org/OurWorldInData',
    },
    {
        name: 'HardvardX',
        img: '/assets/images/partners/harvardx.png',
        url: '/org/harvardx',
    },
    {
        name: 'FairOpportunityX',
        img: '/assets/images/partners/fair-opportunity-x.svg',
        url: '/org/FairOpportunityX',
    },
];

const testimonials: TestimonialProps[] = [
    {
        quote: `Universities hold the potential to contribute to global
            betterment by fostering collaboration, challenging conventions, and
            inspiring creativity and innovation. As a digital space that
            invites common effort and brings together scholars from around the
            world, LabXchange is an evolutionary step not only for online
            learning but for education more broadly.`,
        attribution: `Drew Gilpin Faust, President Emerita, Harvard University`,
        img: '/assets/images/testimonials/drew-faust.jpg',
    }, {
        quote: `Everyone needs science, and science needs everyone. At a time
            of remarkable scientific progress, we’re excited by the potential
            of LabXchange to educate and inspire both students and lifelong
            learners of all ages.`,
        attribution: `Robert Bradway, Chairman and Chief Executive Officer, Amgen`,
        img: '/assets/images/testimonials/robert-bradway.jpg',
    }, {
        quote: `I can differentiate for my students on LabXchange by
            personalizing pathways, giving them choice and empowering
            them to learn science at their own pace. In diverse
            classes, this allows me to support students where they are
            and helps everyone meet the learning goals.`,
        attribution: `Mary Liu, Science Teacher, Weston High School`,
        img: '/assets/images/testimonials/mary-liu.jpg',
    }, {
        quote: `LabXchange addresses a major need in the
            educational community - how to find, share, and curate
            great educational resources which are currently
            scattered. The time has come for this platform.`,
        attribution: `Dr. Ron Vale, Founder, President, and Chairman of the
                     Board, iBiology`,
        img: '/assets/images/testimonials/ron-vale.jpg',
    }, {
        quote: `We are a tech-savvy generation, so it’s great to have
            interactive tools like LabXchange that help expose us to new lab
            techniques. It should be incorporated into all biology classes.`,
        attribution: `Ashanti Malcolm-Bates, High School Student`,
        img: '/assets/images/testimonials/ashanti-malcolm-bates.jpg',
    },
];

const featuredCategory: Category = {
    nameMessage: messages.featuredItemsHeading,
    slug: 'weekly',
    subCategories: [],
};

interface HomeContentProps {
    isUserLoggedIn: boolean;
    testimonialIdx: number;
    userRole: null | string;
}

enum HomeCards {
    Type1 = 1,
    Type2 = 2,
    Type3 = 3
}

export const HomeContent: React.FunctionComponent<HomeContentProps> = (props) => {
    const [activeCard, setActiveCard] = React.useState(HomeCards.Type2);
    const setActive = (cardNum: any) => setActiveCard(cardNum || HomeCards.Type2);
    const userCountryCode = getUserCountryCode();
    const userCountryCurriculumSlug = get(COUNTRY_CODE_CURRICULUM_SLUG_MAP, userCountryCode);

    const handleJoinClass = () => {
        analyticsInstance.track(EVENT_NAMES.HeroJoinClassButtonClicked, { role: props.userRole, url: window.location.toString() });
        if (props.isUserLoggedIn) {
            history.push(ROUTES.Dashboard.LEARNER_CLASSROOMS);
        } else {
            // save this in localstorage as we want to show different copy to the user
            // who starts the signup process using the "Join a Class" button.
            authLocalStorageManager.signupUsingJoinClassButton = true;
            authLocalStorageManager.authRedirectTo = window.location.pathname;
            history.push(ROUTES.General.SIGN_UP);
        }
    };

    return (
        <>
            <div className='interactive-homepage'>
                {userCountryCurriculumSlug ?
                    <CurriculumHeroSection
                        curriculumSlug={userCountryCurriculumSlug}
                        imageSrc={`/assets/images/${userCountryCurriculumSlug}-curriculum-hero.svg`}
                        countryCode={userCountryCode}
                    /> :
                    <div id='hero' className='section navy-gradient'>
                        <div className='interactive-cards-group'>
                            <Link
                                to='/library/items/lb:LabXchange:db98301b:lx_simulation:1?fullscreen=true'
                                className={`interactive-card ${activeCard === HomeCards.Type1 && 'active'}`}
                                onMouseEnter={() => setActive(HomeCards.Type1)}
                                onMouseLeave={() => setActive(HomeCards.Type2)}
                                aria-label={'Start Restriction Fragment Analysis interactive'}
                            >
                                <span className='interactive-card-tag'>
                                    <WrappedMessage message={messages.heroInteractiveCardLabelInteractive}/>
                                </span>
                            </Link>
                            <Link
                                to='/library/items/lb:LabXchange:9548bee3:lx_simulation:1?fullscreen=true'
                                className={`interactive-card ${activeCard === HomeCards.Type2 && 'active'}`}
                                onMouseEnter={() => setActive(HomeCards.Type2)}
                                onMouseLeave={() => setActive(HomeCards.Type2)}
                                aria-label={'Start Gel Electrophoresis simulation'}
                            >
                                <span className='interactive-card-tag'>
                                    <WrappedMessage message={messages.heroInteractiveCardLabelSimulation}/>
                                </span>
                            </Link>
                            <Link
                                to='/library/items/lb:LabXchange:662bea3e:lx_simulation:1?fullscreen=true'
                                className={`interactive-card ${activeCard === HomeCards.Type3 && 'active'}`}
                                onMouseEnter={() => setActive(HomeCards.Type3)}
                                onMouseLeave={() => setActive(HomeCards.Type2)}
                                aria-label={'Start Purifying Protein by Column Chromatography simulation'}
                            >
                                <span className='interactive-card-tag'>
                                    <WrappedMessage message={messages.heroInteractiveCardLabelSimulation}/>
                                </span>
                            </Link>
                        </div>
                        <div className='logo'>
                            <h1><WrappedMessage message={messages.heroInteractiveHeading}/></h1>
                            <p><WrappedMessage message={messages.heroInteractiveText}/></p>
                            <div className='hero-buttons'>
                                <Button label={messages.heroInteractiveEducatorsButton}
                                        href={ROUTES.Teach}
                                        className='hero-interactive-button'
                                        btnStyle='normal'
                                        icon='arrow-right-thin'
                                        iconPosition='right'
                                        onClick={() => analyticsInstance.track(EVENT_NAMES.HeroEducatorsButtonClicked, { role: props.userRole, url: window.location.toString() })}
                                        />
                                <Button label={messages.heroInteractiveLearnersButton}
                                        href={'/subjects'}
                                        className='hero-interactive-button'
                                        btnStyle='normal'
                                        icon='arrow-right-thin'
                                        iconPosition='right'
                                        onClick={() => analyticsInstance.track(EVENT_NAMES.HeroLearnersButtonClicked, { role: props.userRole, url: window.location.toString() })}
                                        />
                            </div>
                            <Button className='join-a-class' label={classRoomMessages.joinClassroomCardTitle}
                                    btnStyle='outline' onClick={handleJoinClass}/>
                        </div>
                    </div>
                }
            </div>
            {!props.isUserLoggedIn &&
              <div className='mobile-signup-card' data-testid='mobile-signup-card'>
                <h2 className='heading'> <WrappedMessage message={uiMessages.freeAccountCreateMessage}/> </h2>
                <Button
                  label={uiMessages.uiSignUp}
                  className='btn signup-button'
                  btnStyle='outline'
                  onClick={() => {LoginActions.redirectToLxSignUp();}}
                ></Button>
                <Button
                  label={uiMessages.uiSignIn}
                  className='btn signin-button'
                  btnStyle='link'
                  onClick={() => {LoginActions.redirectToLogin();}}
                ></Button>
              </div>
            }
            <div className='footer-harvard-panel section'>
                <HarvardUniversityLogo width={214} height={54}/>
            </div>
            <div id='partners' className='section light'>
                <PartnerSection partners={partners} partnersUrl={ROUTES.Organizations.COLLABORATORS}/>
            </div>
            <div id='promo-video' className='section pale'>
                <div className='video'>
                    <PromoVideo
                        videoId={LxConfig.HomepagePromoVideoId}
                        videoImageUrl={LxConfig.HomepagePromoVideoImageUrl}
                        videoImageTitle={messages.promoVideoIsLoading}
                    />
                </div>
                <div className='video-description'>
                    <h2><WrappedMessage message={messages.promoVideoHeading}/></h2>
                    <div className='heading-underline'></div>
                    <p>
                        <WrappedMessage message={messages.promoVideoText} values={{linebreak: <br/>}}/>
                    </p>
                </div>
            </div>

            <div id='how-it-works' className='section navy'>
                <h2><WrappedMessage message={messages.howItWorksHeading}/></h2>
                <div className='heading-underline'></div>
                <div className='how-it-works-row'>
                    <div className='how-it-works-col'>
                        <img src='/assets/images/icons/home-explore.svg' alt=''/>
                        <h3><WrappedMessage message={messages.howItWorksExploreHeading}/></h3>
                        <p><WrappedMessage message={messages.howItWorksExploreText}/></p>
                    </div>
                    <div className='how-it-works-col'>
                        <img src='/assets/images/icons/home-create.svg' alt=''/>
                        <h3><WrappedMessage message={messages.howItWorksCreateHeading}/></h3>
                        <p><WrappedMessage message={messages.howItWorksCreateText}/></p>
                    </div>
                    <div className='how-it-works-col'>
                        <img src='/assets/images/icons/home-share.svg' alt=''/>
                        <h3><WrappedMessage message={messages.howItWorksShareHeading}/></h3>
                        <p><WrappedMessage message={messages.howItWorksShareText}/></p>
                    </div>
                </div>
            </div>
            <div id='featured-content' className='section light'>
                <ExploreContent category={featuredCategory} page='home' hideSidebar/>
            </div>
            <div id='testimonials' className='section navy'>
                <TestimonialSection testimonials={testimonials} firstIdx={props.testimonialIdx}/>
            </div>
            <div id='sponsor' className='section light'>
                <h2><WrappedMessage message={messages.sponsorHeading}/></h2>
                <img src={amgenFoundation} alt={intl.formatMessage(messages.sponsorAltTag)}/>
                <p><WrappedMessage message={messages.sponsorText1}/></p>
                <p><WrappedMessage message={messages.sponsorText2}/></p>
                <Button
                    label={messages.interactiveSponsorButtonLabel}
                    href={ROUTES.Organizations.PROFILE_SLUG('AmgenFoundation')}
                    btnStyle='outline'
                />
            </div>
            <div id='join-us' className='section navy-gradient'>
                <div className='background left'>
                    <img src={joinUsHexLeft} alt=''/>
                </div>
                <div className='join-text'>
                    <h2><WrappedMessage message={messages.joinUsHeading}/></h2>
                    <SignInLink to={ROUTES.Explore.HOME}
                                signIn={ROUTES.General.SIGN_UP}
                                className='btn btn-outline-primary section-button'
                                isUserLoggedIn={props.isUserLoggedIn}>
                        <WrappedMessage message={uiMessages.uiSignUp}/>
                    </SignInLink>
                </div>
                <div className='background right'>
                    <img src={joinUsHexRight} alt=''/>
                </div>
            </div>
        </>
    );
};

interface HomeProps {
    isUserLoggedIn: boolean;
    loginError?: null | string;
    userRole: null | string;
}

const PrivateHome: React.FunctionComponent<HomeProps> = (
    {isUserLoggedIn, loginError, userRole}) =>
    (
        <StandardPageLayout
            backgroundStyle='light-header'
            mainClassName='home-page'
        >
            {loginError ? (
                <div className='alert alert-warning'>
                    <WrappedMessage message={messages.apploginError} values={{loginError}}/>
                </div>
            ) : null}
            {/* Randomly choose a testimonial to display first */}
            <HomeContent isUserLoggedIn={isUserLoggedIn} userRole={userRole}
                         testimonialIdx={Math.floor(random(Date.now()) * testimonials.length)}/>
        </StandardPageLayout>
    );

export const Home = connect<HomeProps, RootState>(
    (state: RootState): HomeProps => {
        return {
            isUserLoggedIn: getLoggedInStatus(state),
            loginError: getLoginErrorStatus(state),
            userRole: getRole(state),
        };
    },
)(PrivateHome);

export const About: React.FunctionComponent = (props) => {
    window.location.href = ROUTES.General.ABOUT_SITE;
    return null;
};

export const Faq: React.FunctionComponent = (props) => {
    window.location.href = ROUTES.General.FAQ_SITE;
    return null;
};
