// tslint:disable
/**
 * LXBackend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime';
import {
    CareerSubject,
    CareerSubjectFromJSON,
    CareerSubjectToJSON,
    ImpactOption,
    ImpactOptionFromJSON,
    ImpactOptionToJSON,
    InspirationOption,
    InspirationOptionFromJSON,
    InspirationOptionToJSON,
    Qualification,
    QualificationFromJSON,
    QualificationToJSON,
} from './';

/**
 * 
 * @export
 * @interface CareerProfile
 */
export interface CareerProfile {
    /**
     * 
     * @type {Array<CareerSubject>}
     * @memberof CareerProfile
     */
    favouriteSubjects: Array<CareerSubject>;
    /**
     * 
     * @type {string}
     * @memberof CareerProfile
     */
    interests?: string;
    /**
     * 
     * @type {Array<ImpactOption>}
     * @memberof CareerProfile
     */
    impact: Array<ImpactOption>;
    /**
     * 
     * @type {Array<InspirationOption>}
     * @memberof CareerProfile
     */
    inspirations: Array<InspirationOption>;
    /**
     * 
     * @type {string}
     * @memberof CareerProfile
     */
    educationPlan?: CareerProfileEducationPlanEnum;
    /**
     * 
     * @type {Qualification}
     * @memberof CareerProfile
     */
    minimumQualification?: Qualification;
}

export function CareerProfileFromJSON(json: any): CareerProfile {
    return {
        'favouriteSubjects': (json['favourite_subjects'] as Array<any>).map(CareerSubjectFromJSON),
        'interests': !exists(json, 'interests') ? undefined : json['interests'],
        'impact': (json['impact'] as Array<any>).map(ImpactOptionFromJSON),
        'inspirations': (json['inspirations'] as Array<any>).map(InspirationOptionFromJSON),
        'educationPlan': !exists(json, 'education_plan') ? undefined : json['education_plan'],
        'minimumQualification': !exists(json, 'minimum_qualification') ? undefined : QualificationFromJSON(json['minimum_qualification']),
    };
}

export function CareerProfileToJSON(value?: CareerProfile): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'favourite_subjects': (value.favouriteSubjects as Array<any>).map(CareerSubjectToJSON),
        'interests': value.interests,
        'impact': (value.impact as Array<any>).map(ImpactOptionToJSON),
        'inspirations': (value.inspirations as Array<any>).map(InspirationOptionToJSON),
        'education_plan': value.educationPlan,
        'minimum_qualification': QualificationToJSON(value.minimumQualification),
    };
}

/**
* @export
* @enum {string}
*/
export enum CareerProfileEducationPlanEnum {
    WontFinishHighSchool = 'I won’t finish high school',
    WantToStartWorkingImmediately = 'I want to start working immediately',
    WantToGoToVocationalSchool = 'I want to go to vocational school',
    WantToGetAnAssociateDegree = 'I want to get an associate degree',
    WantToGetABachelorsDegree = 'I want to get a bachelor’s degree',
    WantToGetAGraduateDegree = 'I want to get a graduate degree',
    WantToGetAProfessionalDegree = 'I want to get a professional degree',
    DontKnowYet = 'I don’t know yet'
}


