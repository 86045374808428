// Declare event names to be used across the project for our custom analytics
export const EVENT_NAMES = {
    AssetSearchSuggestionTerminated: 'asset_search_suggestion terminated',
    AssetSearchSuggestionSelected: 'asset_search_suggestion selected',
    AccountActivationInvalidLink: 'account_activation invalid_link_opened',
    AccountActivationResendEmail: 'account_activation resend_email_clicked',
    AccountActivationSkipped: 'account_activation skip_clicked',
    AccountActivationSuccess: 'account_activation success',
    EmailSignUpFormSubmitted: 'email_sign_up form_submitted',
    OnboardingBirthdayUpdateFailure: 'onboarding birthday_update_failed',
    OnboardingBirthdayUpdateSuccess: 'onboarding birthday_update_success',
    OnboardingCheckYourInboxClicked: 'onboarding check_your_inbox_clicked',
    OnboardingEmailActivationSkip: 'onboarding email_activation_skipped',
    OnboardingEmailActivationSuccess: 'onboarding email_activation_success',
    OnboardingRoleSelectionSkip: 'onboarding role_selection_skipped',
    OnboardingRoleSelectionSuccess: 'onboarding role_selection_success',
    OnboardingJoinClassViewed: 'onboarding join_class_viewed',
    OnboardingJoinClassSkiped: 'onboarding join_class_skipped',
    OnboardingRequestToJoinClassButtonClicked: 'onboarding request_to_join_clicked',
    OnboardingSubjectsSelected: 'onboarding subjects_selected',
    OnboardingSubjectsSkipped: 'onboarding subjects_skipped',
    PageViewed: 'page viewed',
    PasswordAuthenticationSubmission: 'password_auth submitted',
    PathwayCompletedSuccessfully: 'pathway completed',
    ProviderSignInRedirectClicked: 'provider_sign_in_redirect clicked',
    RegistrationGateVisited: 'registration_gate visited',
    RegistrationGateLoginClicked: 'registration_gate login_clicked',
    RegistrationGateSignupClicked: 'registration_gate signup_clicked',
    SignInEmailFormSubmitClicked: 'sign_in_email_form submit_clicked',
    SignInEmailFormSubmitRedirected: 'sign_in_email_form submit_redirected',
    SignInEmailFormSubmitSuccess: 'sign_in_email_form submit_success',
    SignInEmailFormSubmitFailure: 'sign_in_email_form submit_failure',
    SignInHelpdeskClicked: 'sign_in_helpdesk clicked',
    SignInModalClosed: 'sign_in_modal closed',
    SignInModalSignupClicked: 'sign_in_modal_sign_up clicked',
    SignInModalSubscribeChecked: 'sign_in_modal_subscribe checked',
    SignInModalSubscribeUnchecked: 'sign_in_modal_subscribe unchecked',
    SignInPasswordFormSubmitClicked: 'sign_in_password_form submit_clicked',
    SignInPasswordFormSubmitSuccess: 'sign_in_password_form submit_success',
    SignInPasswordFormSubmitFailure: 'sign_in_password_form submit_failure',
    SignInRecoverPasswordClicked: 'sign_in_recover_password clicked',
    SignInSSOProviderClicked: 'sign_in_sso_provider clicked',
    SignUpSSOProviderClicked: 'sign_up_sso_provider clicked',
    ToggleMoreFilterTabsClicked: 'toggle_more_filter_tabs clicked',
    PolicyUpdatesAcceptClicked: 'terms_and_policy_updates_accept clicked',

    // Header click events
    HeaderLabXLogoClicked: 'header_labxchange_logo clicked',
    ExplorePageHeaderButtonClicked: 'explore_header_button clicked',
    SubjectsPageHeaderButtonClicked: 'subjects_header_button clicked',
    LibraryPageHeaderButtonClicked: 'library_header_button clicked',
    SearchBarHeaderFormFocused: 'search_bar_header_form focused',
    SearchBarHeaderIconClicked: 'search_bar_header_icon clicked',
    DashboardPageHeaderButtonClicked: 'dashboard_header_button clicked',
    CommunityPageHeaderButtonClicked: 'community_header_button clicked',
    LocaleSwitcherHeaderButtonClicked: 'locale_switcher_header_button clicked',
    SetLanguageButtonClicked: 'set_language_button clicked',
    NotificationsHeaderDropDownExpanded: 'notifications_header_dropdown expanded',
    MessagesHeaderIconClicked: 'messages_header_icon clicked',
    AboutPageHeaderButtonClicked: 'about_header_button clicked',
    SignInButtonClicked: 'sign_in_header_button clicked',
    SignUpButtonClicked: 'sign_up_header_button clicked',
    MyClassesHeaderButtonClicked: 'my_classes_header_button clicked',
    MyContentHeaderButtonClicked: 'my_content_header_button clicked',
    EducatorsHeaderDropdownExpanded: 'educators_header_dropdown expanded',
    LearnersHeaderDropdownExpanded: 'learners_header_dropdown expanded',
    EducatorsHeaderDropdownExploreClicked: 'educators_header_dropdown_explore clicked',
    EducatorsHeaderDropdownCreateClassClicked: 'educators_header_dropdown_create_class clicked',
    EducatorsHeaderDropdownCreateContentClicked: 'educators_header_dropdown_create_content clicked',
    LearnersHeaderDropdownCreateContentClicked: 'learners_header_dropdown_create_content clicked',
    LearnersHeaderDropdownJoinClassClicked: 'learners_header_dropdown_join_class clicked',
    CreateHeaderButtonClicked: 'create_header_button clicked',
    NotificationsHeaderButtonClicked: 'notifications_header_button clicked',
    LogoutHeaderButtonClicked: 'logout_header_button clicked',
    ProfileDropdownHeaderExpanded: 'profile_dropdown_header expanded',
    ProfileDropdownMyProfileClicked: 'profile_dropdown_my_profile clicked',
    ProfileDropdownSettingsClicked: 'profile_dropdown_settings clicked',
    ProfileDropdownLogoutClicked: 'profile_dropdown_logout clicked',
    MyProfileHeaderButtonClicked: 'my_profile_header_button clicked',
    MyProfileHeaderAvatarClicked: 'my_profile_header_avatar clicked',

    // Acquisition initiatives events
    InitiativeRedirectSuccess: 'acquisition_initiative redirect_success',
    InitiativeRedirectFailure: 'acquisition_initiative redirect_failure',

    // subject page
    SubjectsPageSideBarItemClicked: 'subjects_page_side_bar_item clicked',
    SubjectsPageSideBarMoreClicked: 'subjects_page_side_bar_more clicked',
    SubjectsPageFlagshipLinkClicked: 'subjects_page_subject_flagship_link clicked',
    SubjectsPageTopicLinkClicked: 'subjects_page_subject_topic_link clicked',
    SubjectsPageTopicMoreClicked: 'subjects_page_subject_topic_more clicked',
    SubjectsPageTopicLessClicked: 'subjects_page_subject_topic_less clicked',
    SubjectsPageTopicAllClicked: 'subjects_page_subject_topic_all_topics clicked',
    SubjectsPageOpenLibraryClicked: 'subjects_page_open_library clicked',
    SubjectsPageCurriculaButtonClicked: 'subjects_page_curricula_button clicked',

    // Homepage featured section
    HomeFeaturedSectionRoleChanged: 'home_featured_section_role changed',
    HomeFeaturedSectionSubjectChanged: 'home_featured_section_subject changed',
    HomeFeaturedSectionMoreSubjectsClicked: 'home_featured_section_more_subjects clicked',
    HomeFeaturedSectionAssetCardClicked: 'home_featured_section_asset_card clicked',
    HomeFeaturedSectionMoreAssetsClicked: 'home_featured_section_more_assets clicked',

    // Users self identificaton
    HeroEducatorsButtonClicked: 'hero_educators_button clicked',
    HeroLearnersButtonClicked: 'hero_learners_button clicked',
    HeroJoinClassButtonClicked: 'hero_join_class_button clicked',

    // Footer item events
    FooterItemClicked: 'footer_item clicked',
    FooterLibraryClicked: 'footer_library clicked',
    FooterMyClassesClicked: 'footer_my_classes clicked',
    FooterMyContentClicked: 'footer_my_content clicked',
    FooterMessagesClicked: 'footer_messages clicked',
    FooterCommunityClicked: 'footer_community clicked',
    FooterPeopleClicked: 'footer_people clicked',
    FooterAboutClicked: 'footer_about clicked',
    FooterExploreClicked: 'footer_explore clicked',
    FooterBlogClicked: 'footer_blog clicked',
    FooterNewsClicked: 'footer_news clicked',
    FooterCollaboratorsClicked: 'footer_collaborators clicked',
    FooterTermsOfServiceClicked: 'footer_terms_of_service clicked',
    FooterPrivacyLinkClicked: 'footer_privacy_link clicked',
    FooterHelpCenterClicked: 'footer_help_center clicked',
    FooterTutorialsClicked: 'footer_tutorials clicked',
    FooterGetStartedClicked: 'footer_get_started clicked',
    FooterTeacherResourcesClicked: 'footer_teacher_resources clicked',
    FooterAccessibilityClicked: 'footer_accessibility clicked',
    FooterContactUsClicked: 'footer_contact_us clicked',
    FooterShareStoryClicked: 'footer_share_story clicked',
    FooterEventsClicked: 'footer_events clicked',
    FooterDiscussionClicked: 'footer_discussion clicked',
    FooterFacebookClicked: 'footer_facebook clicked',
    FooterXClicked: 'footer_x clicked',
    FooterInstagramClicked: 'footer_instagram clicked',
    FooterLinkedInClicked: 'footer_linkedin clicked',
    FooterPinterestClicked: 'footer_pinterest clicked',
    FooterYouTubeClicked: 'footer_youtube clicked',
    FooterSubscribeInputFocused: 'footer_subscribe_input focused',
    FooterSubscribeButtonClicked: 'footer_subscribe_button clicked',
    LocaleSwitcherFooterButtonClicked: 'locale_switcher_footer_button clicked',
    FooterSetLanguageButtonClicked: 'footer_set_language_button clicked',
    FooterPrivacyChoicesClicked: 'footer_privacy_choices clicked',

    // Asset Resource Event
    AssetResourceClicked: 'asset_resource clicked',

    // Asset Actions Buttons
    AssetActionHeaderButtonClicked: 'asset_action_header_button clicked',
    ReportButtonClicked: 'report_button clicked',

    // Query String Source Duplication
    QueryStringSourceDuplication: 'query_string source_duplication',

    // New Curriculum Flow Events
    CurriculumHeroFindResourcesButtonClicked: 'curriculum_hero_find_resources_button clicked',
    CurriculumHeroExploreAllCurriculaButtonClicked: 'curriculum_hero_explore_all_curricula_button clicked',
    CurriculumHeaderButtonClicked: 'curriculum_header_button clicked',
    CurriculumPageCurriculumCardClicked: 'curriculum_page_curriculum_card clicked',
    CurriculumDetailPageSubModuleClicked: 'curriculum_detail_page_sub_module clicked',
    CurriculumModulePageSideBarSubModuleClicked: 'curriculum_module_page_side_bar_sub_module clicked',
    CurriculumModulePageCollapsibleClicked: 'curriculum_module_page_collapsible clicked',
    CurriculumModulePageAssetCardClicked: 'curriculum_module_page_asset_card clicked',
    CurriculumModulePageCloselyRelatedCardClicked: 'curriculum_module_page_closely_related_card clicked',
    CurriculumModulePageContentTypeFilterClicked: 'curriculum_module_page_content_type_filter clicked',
    CurriculumMobileSidebarButtonClicked: 'curriculum_mobile_sidebar_button clicked',
};
