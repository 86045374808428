import React, { useEffect, useState, useRef } from 'react';
import Skeleton from 'react-loading-skeleton';

export const ModulesListSkeleton = () => (
  <div className='modules-list'>
    {Array.from({ length: 5 }).map((_, index) => (
      <div key={index} className='curriculum-module-container'>
        <Skeleton className='curriculum-module-heading' height={14} />
        <ul className='curriculum-module-list'>
          <li><Skeleton height={45} /></li>
          <li><Skeleton height={45} /></li>
        </ul>
      </div>
    ))}
  </div>
);

export const CurriculumSidebarSkeleton = () => {
  const lastScrollTopRef = useRef(0);
  const [isSidebarAtTop, setIsSidebarAtTop] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.scrollY;
      if (scrollTop > lastScrollTopRef.current) {
        setIsSidebarAtTop(true);
      } else {
        setIsSidebarAtTop(false);
      }
      lastScrollTopRef.current = scrollTop;
    };

    const throttledScroll = () => requestAnimationFrame(handleScroll);

    window.addEventListener('scroll', throttledScroll);
    return () => window.removeEventListener('scroll', throttledScroll);
  }, []);

  return (
    <div
      className='curriculum-sidebar curriculum-sidebar-skeleton'
      style={{ top: isSidebarAtTop ? '0' : '64px', marginTop: isSidebarAtTop ? '0px' : '4px' }}
      data-testid='curriculum-sidebar-skeleton'
    >
      <div className='sidebar-content'>
        <div className='curriculum-info'>
          <div className='curriculum-country-flag'>
            <Skeleton circle width={62} height={62} />
          </div>
          <div className='curriculum-title-subheading'>
            <Skeleton className='curriculum-title' width={100} height={18} />
            <Skeleton className='curriculum-subheading' width={120} height={14} />
          </div>
        </div>
        <div className='curriculum-dropdowns-search'>
          <div className='module-search-bar-wrapper'>
            <Skeleton height={46} />
          </div>
          <div className='curriculum-dropdowns'>
            <Skeleton height={50} />
            <Skeleton height={50} />
          </div>
        </div>
        <ModulesListSkeleton />
      </div>
    </div>
  );
};
