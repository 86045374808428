import React from 'react';
import { StandardPageLayout } from 'ui/components';
import {
    WrappedMessage,
    useLayoutSize,
    getUserCountryCode,
    COUNTRY_CODE_CURRICULUM_SLUG_MAP,
} from 'utils';
import get from 'lodash/get';

import { ExploreContent, FeaturedItems, SectionSlab, SectionSlabAssetTypeEnum } from 'labxchange-client';
import UserHeader from './UserHeader';
import messages from './displayMessages';

import { useSelector } from 'react-redux';
import { getLoggedInStatus, getLoginErrorStatus } from 'auth/selectors';
import { getSubjectContentTypes } from 'subjects/components/page/utils';
import { ROUTES } from 'global/constants';
import ContentTypesSection from 'subjects/components/page/ContentTypesSection';
import classNames from 'classnames';
import { SourcesContainer, SourcesContainerSkeleton } from './SourcesContainer';
import { QuickLinks, QuickLinksSkeleton } from './QuickLinks';
import { SuccessStories, SuccessStoriesSkelton } from './SuccessStories';
import { PopularSearchQueryContainer, PopularSearchQueryContainerSkeleton } from './PopularSearchQueryContainer';
import { showErrorMessage } from 'ui/components/GlobalMessageReporter/actions';
import { ExploreContentsApi } from 'global/api';
import { ValueType } from 'react-select';
import { Option } from './types';
import { history } from 'global/history';
import { TopicsContainer, TopicsContainerSkeleton } from './TopicsContainer';
import PathwaysCarouselContainer from './PathwaysCarouselContainer';
import Skeleton from 'react-loading-skeleton';
import { PathwayCardSkeleton } from './PathwayCardSkeleton';
import { SingleSlabSection } from './SingleSlabSection';
import { CurriculumHeroSection } from '../CurriculumHeroSection';

export const TeachPage = () => {
    const layoutSize = useLayoutSize();
    const [selectedSubject, setSelectedSubject] = React.useState('biology');
    const [subjectTopics, setSubjectTopics] = React.useState<string[]>([]);
    const isUserLoggedIn = useSelector(getLoggedInStatus);
    const loginError = useSelector(getLoginErrorStatus);
    const isMobile = layoutSize === 'mobile' || layoutSize === 'small';
    const [interactiveSlabs, setInteractiveSlabs] = React.useState<SectionSlab[]>([]);
    const [pathwaysSlug, setPathwaysSlug] = React.useState<string | undefined>(undefined);
    const [hasRaceAndScienceSection, setHasRaceAndScienceSection] = React.useState<boolean>(false);
    const [loading, setLoading] = React.useState(true);
    const userCountryCode = getUserCountryCode();
    const userCountryCurriculumSlug = get(COUNTRY_CODE_CURRICULUM_SLUG_MAP, userCountryCode);

    React.useEffect(() => {
        fetchTeachPageContent();
    }, [selectedSubject]);

    const onSubjectTabSelection = (selectedOption: string) => {
        if (selectedOption) {
            setSelectedSubject(selectedOption.toLowerCase());
        }
    };

    const fetchTeachPageContent = async () => {
        setSubjectTopics([]);
        setPathwaysSlug(undefined);
        setInteractiveSlabs([]);
        try {
            const response: ExploreContent = await ExploreContentsApi.contentsSlugsOnly({id: `teach-${selectedSubject}-subject`});
            if (response.exploreSections) {
                const firstSection = response.exploreSections[0];
                if (firstSection && firstSection.sectionSlabs) {
                    const interactives = firstSection.sectionSlabs
                        .filter(slab => slab.assetType === SectionSlabAssetTypeEnum.Interactives)
                        .sort((slab1, slab2) => slab1.order - slab2.order);
                    setSubjectTopics(interactives.map(slab => slab.title));
                    setInteractiveSlabs(interactives);
                    const pathway = firstSection.sectionSlabs.find(slab => slab.assetType === SectionSlabAssetTypeEnum.Pathways);
                    setPathwaysSlug(pathway?.slug);
                }

                const raceAndScienceSectionResponse = response.exploreSections.find(section => section.slug === 'race-and-science');
                if (raceAndScienceSectionResponse)
                    setHasRaceAndScienceSection(true);
                else setHasRaceAndScienceSection(false);
            }
            // this will be true on only first load
            if (loading) setLoading(false);
        } catch (err: any) {
            // 404 means no matching items, and so is not an error.
            if (err.status !== 404) {
                showErrorMessage(<WrappedMessage message={messages.fetchExploreContentError}/>, {exception: err});
            }
        }
    };

    const onSubjectSelection = (selectedOption: ValueType<Option>) => {
        if (selectedOption && 'label' in selectedOption) {
            if (selectedOption.value === 'allSubjects') {
                history.replace(ROUTES.Library.HOME);
            } else {
                setSelectedSubject(selectedOption.value);
            }
        }
    };

    const allEducatorsResourcesLink = (
        <a className='underline-link' href={ROUTES.General.TEACHER_RESOURCES} target='_blank' rel='noopener noreferrer'>
            <WrappedMessage message={messages.allEducatorResources}/>
        </a>
    );

    return (
        <StandardPageLayout
            backgroundStyle='light-header'
            mainClassName='user-home-page'
            headerFeature={
                userCountryCurriculumSlug ?
                    <CurriculumHeroSection
                        curriculumSlug={userCountryCurriculumSlug}
                        imageSrc={`/assets/images/${userCountryCurriculumSlug}-curriculum-hero.svg`}
                        countryCode={userCountryCode}
                    /> :
                    <UserHeader
                        selectedSubject={selectedSubject}
                        onSubjectSelection={onSubjectSelection}
                        layoutSize={layoutSize}
                        loading={loading}
                    />
            }
            pageTitle={messages.pageTitle}
        >
        {loginError ? (
            <div className='alert alert-warning'>
                <WrappedMessage message={messages.loginError} values={{loginError}}/>
            </div>
        ) : null}

        <>
            <div className='grades-container section'>
                {loading ? <Skeleton className='grades-info' /> :
                    <div className='grades-info'>
                        <WrappedMessage message={messages.gradesTextForEducatorsPage} />
                    </div>
                }
            </div>
            {!loading && interactiveSlabs.length > 0 ?
                <TopicsContainer
                    isUserLoggedIn={isUserLoggedIn}
                    selectedSubject={selectedSubject}
                    subjectTopics={subjectTopics}
                    interactiveSlabs={interactiveSlabs}
                    onSubjectTabSelection={onSubjectTabSelection}
                />
                :
                <TopicsContainerSkeleton />
            }

            <div className='content-type-container section scroll-section'>
                <ContentTypesSection
                    contentTypes={getSubjectContentTypes(selectedSubject)}
                    loading={loading}
                />
            </div>

            {pathwaysSlug && !loading ?
                <PathwaysCarouselContainer
                pathwaysSlug={pathwaysSlug}
                isMobile={isMobile}
                />
                :
                <div className='pathways-container section scroll-section'>
                    <Skeleton width={isMobile ? 240 : 380} height={42} />
                    <Skeleton className='pathways-desc-skeleton' width={isMobile ? 300 : 580} height={42} />
                    <div className='pathways-carousel'>
                        {Array.from({ length: isMobile ? 3 : 1 }, (_, index) => (
                            <PathwayCardSkeleton key={index} />
                        ))}
                    </div>
                </div>
        }


            {(!loading && hasRaceAndScienceSection) &&
                <SingleSlabSection
                  heading={<WrappedMessage message={messages.raceAndScienceSectionHeading} />}
                  slug='race-and-science'
                />
            }

            {loading ? <QuickLinksSkeleton isUserLoggedin={isUserLoggedIn} layoutSize={layoutSize} /> :
                <div className={classNames('quicklinks-container section', {'last-section' : isUserLoggedIn})}>
                <div className='quicklinks-header'>
                    <h1 className='sentence-case'><WrappedMessage message={messages.quickLinksHeading}/></h1>
                    {(layoutSize === 'desktop' || layoutSize === 'large') && allEducatorsResourcesLink}
                </div>
                <QuickLinks/>
                {(layoutSize === 'medium' || layoutSize === 'mobile') && allEducatorsResourcesLink}
                </div>
            }

            {!isUserLoggedIn &&
            <>
                <div className='sources-container section'>
                    {loading? <SourcesContainerSkeleton /> : <SourcesContainer/>}
                </div>
                <div className='success-stories-container section last-section'>
                    {loading ? <SuccessStoriesSkelton /> :
                        <>
                            <h1><WrappedMessage message={messages.learnerStoriesHeading}/></h1>
                            <SuccessStories />
                        </>
                    }
                </div>
                {loading ?
                    <div className='content-section search-gap'>
                        <PopularSearchQueryContainerSkeleton />
                    </div> :
                    <PopularSearchQueryContainer selectedSubject={selectedSubject}  subjectOptions={[]}/>
                }
            </>
            }
        </>
        </StandardPageLayout>
    );
};
