// tslint:disable
/**
 * LXBackend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime';
import {
    CareerSubjectArea,
    CareerSubjectAreaFromJSON,
    CareerSubjectAreaToJSON,
    EducationQuestion,
    EducationQuestionFromJSON,
    EducationQuestionToJSON,
    ImpactOption,
    ImpactOptionFromJSON,
    ImpactOptionToJSON,
    InspirationOption,
    InspirationOptionFromJSON,
    InspirationOptionToJSON,
    InterestsHobbiesOption,
    InterestsHobbiesOptionFromJSON,
    InterestsHobbiesOptionToJSON,
} from './';

/**
 * 
 * @export
 * @interface CareerOnboarding
 */
export interface CareerOnboarding {
    /**
     * 
     * @type {Array<CareerSubjectArea>}
     * @memberof CareerOnboarding
     */
    subjectAreas: Array<CareerSubjectArea>;
    /**
     * 
     * @type {Array<InterestsHobbiesOption>}
     * @memberof CareerOnboarding
     */
    interestOptions: Array<InterestsHobbiesOption>;
    /**
     * 
     * @type {Array<ImpactOption>}
     * @memberof CareerOnboarding
     */
    impactOptions: Array<ImpactOption>;
    /**
     * 
     * @type {Array<InspirationOption>}
     * @memberof CareerOnboarding
     */
    inspirationOptions: Array<InspirationOption>;
    /**
     * 
     * @type {Array<EducationQuestion>}
     * @memberof CareerOnboarding
     */
    educationQuestions: Array<EducationQuestion>;
}

export function CareerOnboardingFromJSON(json: any): CareerOnboarding {
    return {
        'subjectAreas': (json['subject_areas'] as Array<any>).map(CareerSubjectAreaFromJSON),
        'interestOptions': (json['interest_options'] as Array<any>).map(InterestsHobbiesOptionFromJSON),
        'impactOptions': (json['impact_options'] as Array<any>).map(ImpactOptionFromJSON),
        'inspirationOptions': (json['inspiration_options'] as Array<any>).map(InspirationOptionFromJSON),
        'educationQuestions': (json['education_questions'] as Array<any>).map(EducationQuestionFromJSON),
    };
}

export function CareerOnboardingToJSON(value?: CareerOnboarding): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'subject_areas': (value.subjectAreas as Array<any>).map(CareerSubjectAreaToJSON),
        'interest_options': (value.interestOptions as Array<any>).map(InterestsHobbiesOptionToJSON),
        'impact_options': (value.impactOptions as Array<any>).map(ImpactOptionToJSON),
        'inspiration_options': (value.inspirationOptions as Array<any>).map(InspirationOptionToJSON),
        'education_questions': (value.educationQuestions as Array<any>).map(EducationQuestionToJSON),
    };
}


