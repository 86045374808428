import React, {useState} from 'react';
import classNames from 'classnames';
import messages from '../../../../displayMessages';
import { intl } from '../../../../../i18n';
import { Icon } from '../../../../../elements';
import { Task } from 'labxchange-client';
import useFullscreen from '../../../../utils';
import {useLayoutSize, WrappedMessage} from '../../../../../utils';
import DutiesSideBar from './DutieSidebar';

interface TaskItemProps {
    taskNumber: number;
    text: string;
    setShowSideBar: (b: boolean) => void;
    setSelectedTask: () => void;
    selectedTask: number;
}

export const TaskItem : React.FC<TaskItemProps> = ({ taskNumber, text, setShowSideBar, setSelectedTask, selectedTask }) => {
    const onClickTask = () => {
        setShowSideBar(true);
        setSelectedTask();
    };

    return (
        <>
            <div className={classNames('task-item', {'color-background': taskNumber === selectedTask})} onClick={() => onClickTask()}>
                <div className={classNames('task-item__number', {'selected-task': taskNumber === selectedTask})}>#{taskNumber}</div>
                <div className={classNames('task-item__text', {'bold-text': taskNumber === selectedTask})}>{text}</div>
                <div data-testid='icon-chevron-right-thin'>
                    <Icon name='arrow-forward' fill={'#002F50'} zoom='1em' />
                </div>
            </div>
        </>
    );
};


interface CareerTaskProps {
    tasks: Task[];
    backgroundImage?: string;
}

const CareerTasks: React.FC<CareerTaskProps> = ({tasks, backgroundImage}) => {
    const  defaultBackground = '/assets/images/career-explorer/task-background.svg';
    const { isFullscreen, toggleFullscreen, elementRef } = useFullscreen();
    const isMobile = ['small', 'mobile'].includes(useLayoutSize());

    const [showSideBar, setShowSideBar] = useState<boolean>(!isMobile);
    const [selectedTask, setSelectedTask] = useState<Task | null>(tasks[0]);
    const [selectedTaskNumber, setSelectedTaskNumber] = useState<number>(1);

    const setTaskStates = (task: Task | null, taskNumber: number) => {
        setSelectedTaskNumber(taskNumber);
        setSelectedTask(task);
    };

    const onCloseSideBar = () => {
        setShowSideBar(false);
        setTaskStates(null, 0);
    };

    return (
        <>
            <div className='career-tasks'>
                {!isFullscreen &&
                  <button className='toggle-screen' onClick={toggleFullscreen} data-testid='enter-full-screen-button'>
                    <Icon name='full-screen'/>
                  </button>
                }
                <div ref={elementRef}>
                    {isFullscreen &&
                      <div className='full-screen-header' onClick={toggleFullscreen}>
                        <Icon name='arrow-back' fill='#00AEEF'/>
                        <h1> <WrappedMessage message={messages.CareerDutiesHeadingText}/> </h1>
                      </div>
                    }
                    <div className={classNames({'full-screen-content': isFullscreen, 'x-screen-content': !isFullscreen})}>
                        {isFullscreen &&
                            <button
                              className='toggle-screen x-toggle-screen'
                              onClick={toggleFullscreen}
                              data-testid='x-full-screen-button'
                            >
                              <Icon name='full-screen-x' />
                            </button>
                        }
                        <div className={classNames('tasks-section', {'tasks-section__sidebar-open': showSideBar})}>
                            <img
                                alt={intl.formatMessage(messages.altTextForImages)}
                                src={backgroundImage || defaultBackground}
                            />
                            <div className='task-list'>
                                {tasks.map((task, index) => (
                                    <TaskItem
                                        key={index}
                                        taskNumber={index + 1}
                                        text={task.longName}
                                        setShowSideBar={setShowSideBar}
                                        setSelectedTask={() => setTaskStates(task, index+1)}
                                        selectedTask={selectedTaskNumber}
                                    />
                                ))}
                            </div>
                        </div>
                        {(selectedTask && showSideBar) &&
                            <DutiesSideBar
                              selectedTask={selectedTask}
                              selectedTaskNumber={selectedTaskNumber}
                              isFullscreen={isFullscreen}
                              showSideBar={showSideBar}
                              onClose={() => onCloseSideBar()}
                            />
                        }
                    </div>
                </div>
            </div>
        </>
    );
};

export default CareerTasks;
