import { BooksApi, ItemsApi, XBlocksApi } from 'global/api';
import { ROUTES } from 'global/constants';
import { ItemType } from 'items/models';
import {
    ChildItem,
    ItemResponse,
    ItemMetadataTypeEnum,
    ItemUserAttributesFromJSON,
    RelatedItemMetadata,
    VideoDataState,
} from 'labxchange-client';
import { showErrorMessage } from 'ui/components/GlobalMessageReporter/dispatch';
import {
    BlockData,
    BlockStudentViewData,
    LoadingState,
} from './components/Block/models';
import { getEmptyItemMetadata } from './models';


/*
 * Helper function to generate the url for an ItemMetadata. Required because
 * not all ItemMetadata types use the same path slug, and this conditionally
 * injects url search params.
 *
 * Warning: this is not a pure function; it sets parameters depending on the
 * value of window.location. This is to support a 'back to' button when
 * navigating to an asset from a page like the mentors dashboard.
 */
export function detailUrlForEntity(item?: {id: string, type: string, relId?: string}, parentPathwayKey?: string, parentType?:ItemMetadataTypeEnum,  fromParentAsset?: boolean): string {
    const location = window.location;
    const urlParams = new URLSearchParams();

    if (!item) {
        return '';
    }

    // Choose the url
    let url;
    if (parentPathwayKey && !fromParentAsset) {
        // this is if it's an item within a pathway
        url = ROUTES.Library.PATHWAY_ITEM_SLUG(parentPathwayKey, item.id, item.relId);
    } else if (item.type === ItemType.Pathway) {
        url = ROUTES.Library.PATHWAY_SLUG(item.id);
    } else if (item.type === ItemType.Book) {
        url =  ROUTES.Library.BOOK_SLUG(item.id);
    } else if (item.type === ItemType.Cluster) {
        url =  ROUTES.Library.CLUSTER_SLUG(item.id);
    } else {
        url = ROUTES.Library.ITEM_SLUG(item.id);
    }

    let source;
    if (
        location.pathname === ROUTES.Community.MENTORS ||
        location.pathname === ROUTES.Community.MENTEES ||
        location.pathname.startsWith(ROUTES.Classrooms.CLASSROOM_SLUG('')) ||
        location.pathname.startsWith(ROUTES.Library.BOOK_SLUG('')) ||
        location.pathname.startsWith(ROUTES.Library.CLUSTER_SLUG(''))
    ) {
        // if we're coming from a recognized source page, set the source
        source = location.pathname;
    } else {
        // Otherwise, keep any existing source around so we can continue
        // tracking it.
        source = new URLSearchParams(location.search).get('source') || undefined;
    }

    if (source === url) {
        source = undefined;
        urlParams.delete('source');
    }

    if (source) {
        if (source.includes('source')) {
            source = source.split('?source')[0];
        }

        urlParams.set('source', source);
    }

    if(fromParentAsset && parentPathwayKey && parentType){
        urlParams.set('assetId', parentPathwayKey);
        urlParams.set('assetType', parentType);
    }

    url += `?${urlParams.toString()}`;
    return url;
}

interface ItemAndBlockData {
    blockData: BlockData;
    itemData: ItemResponse;
    statusCode?: number;
}

/**
 * Helper function to fetch the student_view_data() of an XBlock.
 * It only makes the request if the XBlock type is listed in `ItemTypesWhichNeedStudentViewData`.
 * Otherwise it returns null.
 *
 * @param usageKey: The usageKey of the XBlock.
 */
async function getStudentViewData(usageKey: string): Promise<BlockStudentViewData|null> {
    const response = await XBlocksApi.studentViewData({id: usageKey});
    return JSON.parse(response);
}

interface Options {
    skipItemMetadata: boolean;
}

/**
 * Function to fetch all the data that we need to render the read view of an asset.
 * It make the requests in parallel and returns once all the responses have been received.
 *
 * @param usageKey: The usageKey of the XBlock
 */
export async function getItemAndBlockData(usageKey: string, options?: Options): Promise<ItemAndBlockData> {
    const defaultItemMetadata = {
        metadata: getEmptyItemMetadata(ItemType.Text, new Date()),
        userAttributes: ItemUserAttributesFromJSON({}),
    };

    return Promise.all<BlockStudentViewData | null |{error: any}, ItemResponse>([
        // Browser have limits on the number of concurrent requests in a tab and sometimes
        // all these 4 are not started at the same time. Since requests to LMS take longer call them first.
        getStudentViewData(usageKey).catch(error => {return {error};}),
        (options && options.skipItemMetadata) ? defaultItemMetadata : ItemsApi.read({id: usageKey}),
    ]).then(([studentViewData, itemData]) => {
        if (studentViewData  && 'error' in studentViewData) {
            if (studentViewData && 'error' in studentViewData && studentViewData.error.statusCode === 401) {
                return {
                    blockData: {loadingState: LoadingState.UNAUTHORIZED},
                    itemData: {
                        metadata: getEmptyItemMetadata(ItemType.Text, new Date()),
                        userAttributes: ItemUserAttributesFromJSON({}),
                    },
                };
            }
            return {
                blockData: {loadingState: LoadingState.ERROR},
                itemData: {
                    metadata: getEmptyItemMetadata(ItemType.Text, new Date()),
                    userAttributes: ItemUserAttributesFromJSON({}),
                },
                statusCode: 502,
            };
        }
        const blockData: BlockData = {
            studentViewData: studentViewData ? studentViewData : undefined,
            loadingState: LoadingState.READY,
        };
        return {
            blockData,
            itemData,
        };
    }).catch(err => {
        return {
            blockData: {loadingState: LoadingState.ERROR},
            itemData: {
                metadata: getEmptyItemMetadata(ItemType.Text, new Date()),
                userAttributes: ItemUserAttributesFromJSON({}),
            },
            statusCode: (err.status === 404 || err.status === 401) ? err.status : 500,
        };
    });
}

export interface ItemInMaybePathway {
    item: RelatedItemMetadata;
    pathwayId?: string;
}

/**
 * Function to pull in the item directly before the `currentItem` in a `book`.
 * If the item is not found in the book, or is the first item in the book,
 * then will return undefined. If the previous 'item' is a pathway, then
 * previous item returned will be the last asset from that pathway (hierarchy
 * is flattened).
 */
export const readPreviousItemInBookFor = async (id: string): Promise<ItemInMaybePathway|undefined> => {
    try {
        const item = await BooksApi.relatedBookItem({id, previous: true});
        return { item, pathwayId: item.pathway};
    } catch (err) {
        // 404 means no matching items, and so is not an error.
        if (err.status !== 404) {
            showErrorMessage('Could not fetch book data', {exception: err});
        }
        return;
    }
};

/**
 * Function to pull in the item directly *after* the `currentItem` in a `book`.
 * If the item is not found in the book, then will return undefined. If is last
 * item in book, then 'next item' will be the book itself.
 */
export const readNextItemInBookFor = async (id: string): Promise<RelatedItemMetadata|undefined> => {
    try {
        return await BooksApi.relatedBookItem({ id });
    } catch (err) {
        if (err.status !== 404) {
            showErrorMessage('Could not fetch book data', {exception: err});
        }
        return;
    }
};


/**
 * Function to return the index of the pathway child associated with the given item id and optional relId.
 *
 * This function handles the case where there are multiple instances of a given item in a pathway's child list,
 * differentiated by the relId (relationship ID).
 *
 * Returns -1 if the child is not found.
 */
export const getPathwayChildIndex = (pathwayChildren: ChildItem[], childId: string, relId?: string): number => {
    if (pathwayChildren) {
         return pathwayChildren.findIndex((child) => (
            child.item.metadata.id === childId &&
            (!relId || child.item.metadata.relId === relId)
         ));
    }
    return -1;
};

/**
 * Function to return the pathway child associated with the given item id and optional relId.
 *
 * This function handles the case where there are multiple instances of a given item in a pathway's child list,
 * differentiated by the relId (relationship ID).
 *
 * Returns null if the pathway child is not found.
 */
export function getPathwayChild(pathwayChildren: ChildItem[], childId: string, relId?: string): ChildItem | null {
    const childIndex = getPathwayChildIndex(pathwayChildren, childId, relId);
    return childIndex >= 0 ? pathwayChildren[childIndex] : null;
}

/**
 * Function to get the main class name of a StandardPageLayout depending of
 * if is a expandBlock and the type of the block.
 */
export const getPageMainClass = (expandBlock: boolean, metadataType?: ItemMetadataTypeEnum): string => {
    if (expandBlock && metadataType !== undefined) {
        if (metadataType === ItemMetadataTypeEnum.Assignment) {
            return 'item-page-expanded-background-navy-7';
        }
        else if (metadataType === ItemMetadataTypeEnum.CaseStudy
                  || metadataType === ItemMetadataTypeEnum.TeachingGuide) {
            return 'item-page-expanded-background-white';
        }
        return 'item-page-expanded-background';
    }
    return '';
};

/**
 * Gets the transcript files of an existing video asset.
 * Used to create new video assets or annotated videos from other video assets.
 */
export const getTranscriptFilesFromVideoState = async (videoId: string, videoDataState: VideoDataState) => {
    const newTranscriptState = {} as any;
    let transcriptData;
    try {
        transcriptData = await XBlocksApi.videoTranscriptsSrt({
            id: videoId,
        });
    }
    catch (err) {
        showErrorMessage('Could not fetch transcription data');
        return {};
    }
    for (const transcript of transcriptData) {
        const blob = new File(
            [transcript.transcript],
            'transcript-' + transcript.languageCode + '.srt', {
            type: 'text/plain;charset=utf-8',
        });
        newTranscriptState[transcript.languageCode] = blob;
    }
    return newTranscriptState;
};

/**
 * Function to get the feedback permission details
 */
export const getFeedbackDetails = (groupCurriculumSlug: string | undefined) => {
    const queryParams = new URLSearchParams(window.location.search);
    const queryCurriculumSlug = queryParams.get('curriculum');
    // give preference to queryCurriculumSlug for testing purposes
    const curriculumSlug = queryCurriculumSlug || groupCurriculumSlug;
    const canUserGiveFeedback = groupCurriculumSlug ? true : false;
    return { curriculumSlug, canUserGiveFeedback };
};
