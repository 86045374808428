import React, { useState } from 'react';
import OnboardingPageLayout from './OnboardingPageLayout/OnboardingPageLayout';
import { intl } from 'i18n';
import CareerRadioButton from 'career-explorer/components/CareerRadioButton/CareerRadioButton';
import messages from './displayMessages';
import { EducationQuestion } from 'labxchange-client';

export interface EducationStepProps {
    currentStep: number;
    educationQuestions: EducationQuestion[] | [];
    selectedQuestion: string;
    toggleQuestion: (question: string) => void;
    onButtonClick: (nextStep: number) => void;
    onSubmit: () => void;
}

export const EducationStep: React.FC<EducationStepProps> = ({
    currentStep, educationQuestions, selectedQuestion, toggleQuestion, onButtonClick, onSubmit,
}) => {

    const onRadioSelect = (newValue: number | string) => {
        toggleQuestion(newValue.toString());
    };

    return (
        <OnboardingPageLayout
            title={intl.formatMessage(messages.careerEducationOnboardingStepTitle)}
            description={intl.formatMessage(messages.careerEducationOnboardingStepDescription)}
            currentStep={currentStep}
            onButtonClick={onButtonClick}
            showContinueAction={!!selectedQuestion}
            onSubmit={onSubmit}
        >
            {educationQuestions.map((question) =>
                <CareerRadioButton key={question.value} id={question.value} selected={selectedQuestion === question.value} onClick={onRadioSelect}>
                    {question.label}
                </CareerRadioButton>
            )}
        </OnboardingPageLayout>
    );
};

export default EducationStep;
