// tslint:disable
/**
 * LXBackend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime';
/**
 * 
 * @export
 * @interface EducationQuestion
 */
export interface EducationQuestion {
    /**
     * 
     * @type {string}
     * @memberof EducationQuestion
     */
    readonly value?: string;
    /**
     * 
     * @type {string}
     * @memberof EducationQuestion
     */
    readonly label?: string;
}

export function EducationQuestionFromJSON(json: any): EducationQuestion {
    return {
        'value': !exists(json, 'value') ? undefined : json['value'],
        'label': !exists(json, 'label') ? undefined : json['label'],
    };
}

export function EducationQuestionToJSON(value?: EducationQuestion): any {
    if (value === undefined) {
        return undefined;
    }
    return {
    };
}


