import React, { useEffect, useState, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import classNames from 'classnames';
import Select, { ValueType } from 'react-select';
import ReactCountryFlag from 'react-country-flag';
import { intl } from 'i18n';

import { CurriculumsApi } from 'global/api';
import { Icon, showErrorMessage } from 'ui/components';
import { isKeyboardEnterEvent } from 'global/utils';
import { analyticsInstance } from 'tracking';
import { EVENT_NAMES } from 'tracking/constants';
import { CurriculumModules, ModuleHierarchy } from 'labxchange-client';
import { ROUTES } from 'global/constants';

import {
  searchModules,
  expandActiveModulePath,
  renderModules,
  createSubjectGradeOptions
} from '../../../utils';
import { SelectDropdownOption } from '../../../types';
import { ModuleSearchBar } from '../../ModuleSearchBar';
import { ModulesListSkeleton, CurriculumSidebarSkeleton } from './CurriculumSidebarSkeleton';
import { EmptySearchResults } from '../../EmptySearchResults';
import messages from '../../../displayMessages';

interface CurriculumSidebarProps {
  showFixedSidebar: boolean;
  closeSidebar: () => void;
}

export const CurriculumSidebar = ({
  showFixedSidebar,
  closeSidebar,
}: CurriculumSidebarProps) => {
  const location = useLocation();
  const pathSegments = location.pathname.split('/');
  const curriculum = pathSegments[pathSegments.length - 4];
  const subject = pathSegments[pathSegments.length - 3];
  const grade = pathSegments[pathSegments.length - 2];
  const module = pathSegments[pathSegments.length - 1];

  const lastScrollTopRef = useRef(0);
  const [isSidebarAtTop, setIsSidebarAtTop] = useState(false);
  const [curriculumData, setCurriculumData] = useState<CurriculumModules | null>(null);
  const [loadingCurriculumData, setLoadingCurriculumData] = useState<boolean>(false);
  const [searchKeyword, setSearchKeyword] = useState<string>('');
  const [filteredModules, setFilteredModules] = useState<ModuleHierarchy[]>([]);
  const [selectedSubject, setSelectedSubject] = React.useState<string>(subject || '');
  const [selectedGrade, setSelectedGrade] = React.useState<string>(grade || '');

  useEffect(() => {
    const fetchCurriculumData = async () => {
      setLoadingCurriculumData(true);
      try {
        const curriculumResponse: CurriculumModules = await CurriculumsApi.read(
          {
            id: curriculum,
            subject: selectedSubject,
            grade: selectedGrade,
          }
        );
        setCurriculumData(curriculumResponse);
      } catch (error) {
        showErrorMessage(intl.formatMessage(messages.curriculumSidebarCurriculumError));
      }
      setLoadingCurriculumData(false);
    };

    fetchCurriculumData();
  }, [selectedSubject, selectedGrade, curriculum]);

  useEffect(() => {
    if (!curriculumData?.modules) return;

    if (!searchKeyword) {
      const expandedModules = expandActiveModulePath(curriculumData.modules, module);
      setFilteredModules([...expandedModules]);
    } else {
      const filteredResults: any = searchModules(curriculumData.modules, searchKeyword);
      setFilteredModules([...filteredResults]);
    }
  }, [searchKeyword, curriculumData, module]);

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.scrollY;
      if (scrollTop > lastScrollTopRef.current) {
        setIsSidebarAtTop(true);
      } else {
        setIsSidebarAtTop(false);
      }
      lastScrollTopRef.current = scrollTop;
    };

    const throttledScroll = () => requestAnimationFrame(handleScroll);

    window.addEventListener('scroll', throttledScroll);
    return () => window.removeEventListener('scroll', throttledScroll);
  }, []);

  const onClickSidebarModule = (sidebarModule: any) => {
    analyticsInstance.track(EVENT_NAMES.CurriculumModulePageSideBarSubModuleClicked, {
      module_id: sidebarModule.id,
      module_slug: sidebarModule.slug,
      module_name: sidebarModule.name,
      module_url: ROUTES.Curriculum.MODULE_SLUG(curriculum, subject, grade, sidebarModule.slug),
      search_keyword: searchKeyword,
      selected_subject: subject,
      selected_grade: grade,
      curriculum_slug: curriculum,
      url: window.location.toString(),
    });
    closeSidebar();
  };

  const onSubjectChange = (selectedOption: ValueType<SelectDropdownOption>) => {
    if (selectedOption) {
      const selectedSubjectValue = (selectedOption as SelectDropdownOption).value;
      setSelectedSubject(selectedSubjectValue);

      const availableGradeValue = curriculumData?.subjects
        ?.find((s) => s.slug === selectedSubjectValue)
        ?.grades?.[0].slug;
      setSelectedGrade(availableGradeValue || '');
      setSearchKeyword('');
    }
  };

  const onGradeChange = (selectedOption: ValueType<SelectDropdownOption>) => {
    if (selectedOption) {
      const selectedGradeValue = (selectedOption as SelectDropdownOption).value;
      setSelectedGrade(selectedGradeValue);
      setSearchKeyword('');
    }
  };

  const {
    subjectOptArray: subjectOptions,
    gradeOptArray: gradeOptions,
  } = createSubjectGradeOptions(curriculumData?.subjects || [], selectedSubject);
  const subjectDefaultValue = subjectOptions.find((option: any) => option.value === selectedSubject);
  const gradeDefaultValue = gradeOptions.find((option: any) => option.value === selectedGrade);

  if (!curriculumData) return <CurriculumSidebarSkeleton />;

  return (
    <div
      className={classNames('curriculum-sidebar',
        {
          'tablet-fixed': showFixedSidebar,
        }
      )}
      style={{ top: isSidebarAtTop ? '0' : '64px', marginTop: isSidebarAtTop ? '0px' : '4px' }}
    >
      <div className='sidebar-content'>
        <div className='curriculum-info'>
          <div className='curriculum-country-flag'>
            <ReactCountryFlag
              countryCode={(curriculumData.country as any).code}
              svg
              style={{
                width: '34px',
                height: '24px'
              }}
            />
          </div>
          <div className='curriculum-title-subheading'>
            <span className='curriculum-title'>{curriculumData.slug.toUpperCase()}</span>
            <span className='curriculum-subheading'>{(curriculumData.country as any).name}</span>
          </div>
          {showFixedSidebar &&
            <div className='close-sidebar-btn'>
              <button
                tabIndex={0}
                className='close-button'
                aria-label='Close sidebar'
                onClick={closeSidebar}
                data-testid='close-sidebar-btn'
              >
                <Icon name='x' zoom='20' />
              </button>
            </div>
          }
        </div>
        <div className='curriculum-dropdowns-search'>
          <div className='module-search-bar-wrapper'>
            <ModuleSearchBar
              value={searchKeyword}
              onChange={(value: string) => setSearchKeyword(value)}
              placeholder={messages.curriculumSidebarSearchPlaceholder}
            />
          </div>
          <div className='curriculum-dropdowns'>
            <Select
              tabIndex='0'
              className='select-dropdown'
              classNamePrefix='select'
              placeholder={intl.formatMessage(messages.curriculumSidebarSubjectDropdownPlaceholder)}
              aria-label={intl.formatMessage(messages.curriculumSidebarSubjectDropdownAria)}
              options={subjectOptions}
              onChange={onSubjectChange}
              value={subjectDefaultValue}
              isSearchable={false}
            />
            <Select
              tabIndex='0'
              className='select-dropdown'
              classNamePrefix='select'
              placeholder={intl.formatMessage(messages.curriculumSidebarGradeDropdownPlaceholder)}
              aria-label={intl.formatMessage(messages.curriculumSidebarGradeDropdownAria)}
              options={gradeOptions}
              onChange={onGradeChange}
              value={gradeDefaultValue}
              isSearchable={false}
            />
          </div>
        </div>
        <div className='modules-list'>
          {loadingCurriculumData ? <ModulesListSkeleton /> :
            renderModules(
              filteredModules, curriculum, selectedSubject,
              selectedGrade, 1, module, onClickSidebarModule
            )
          }
          {filteredModules.length === 0 && !loadingCurriculumData && <EmptySearchResults />}
        </div>
      </div>
      {showFixedSidebar &&
        <div
          role='button'
          aria-label='Close sidebar'
          tabIndex={0}
          className='sidebar-backdrop'
          onClick={closeSidebar}
          onKeyDown={(e) => isKeyboardEnterEvent(e) && closeSidebar()}
          data-testid='close-sidebar-backdrop'
        />
      }
    </div>
  );
};
