// tslint:disable
/**
 * LXBackend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime';
/**
 * 
 * @export
 * @interface InterestsHobbiesOption
 */
export interface InterestsHobbiesOption {
    /**
     * 
     * @type {string}
     * @memberof InterestsHobbiesOption
     */
    title: string;
}

export function InterestsHobbiesOptionFromJSON(json: any): InterestsHobbiesOption {
    return {
        'title': json['title'],
    };
}

export function InterestsHobbiesOptionToJSON(value?: InterestsHobbiesOption): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'title': value.title,
    };
}


