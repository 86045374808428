import { defineMessages } from 'react-intl';

const messages = defineMessages({
    skipLinkText: {
        id:'skipLinkText',
        defaultMessage: 'Skip to main content',
        description: 'Text for the skip link.',
    },
    defaultPageTitle: {
        id:'defaultPageTitle',
        defaultMessage: 'LabXchange',
        description: 'Default value for the HTML title attribute',
    },
    pageTitlePlaceholder: {
        id:'pageTitlePlaceholder',
        defaultMessage: '{title} - LabXchange',
        description: 'Placeholder for the value of the HTML title attribute',
    },
    showMoreResults: {
        id:'showMoreResults',
        defaultMessage: 'Show more results',
        description: 'Text for the infinite scroll button.',
    },
    srPageNavigationAnnouncement: {
        id:'srPageNavigationAnnouncement',
        defaultMessage: 'Navigated to {pageTitle}',
        description: 'Announcement read out by the screen reader when user navigates to a different page.',
    },
    uiAbout: {
        id:'uiAbout',
        defaultMessage: 'About',
        description: 'Text for the nav link to the about page.',
    },
    uiBrowse: {
        id:'uiBrowse',
        defaultMessage: 'Browse',
        description: 'Text for Browse menu',
    },
    uiError: {
        id:'uiError',
        defaultMessage: 'Error',
        description: 'Title text shown when some sort of error has occurred.',
    },
    uiHeaderNavBarBrand: {
        id:'uiHeaderNavBarBrand',
        defaultMessage: 'LabXchange',
        description: 'Title for Navbar.',
    },
    uiHarvardOnlineLogo: {
        id:'uiHarvardOnlineLogo',
        defaultMessage: 'Harvard Online',
        description: 'Title for Harvard Online logo.',
    },
    uiLoading: {
        id:'uiLoading',
        defaultMessage: 'Loading…',
        description: 'Text shown when content is loading',
    },
    uiLogOut: {
        id:'uiLogOut',
        defaultMessage: 'Log out',
        description: 'Text for log out button in navbar.',
    },
    uiMessagesLinkTitle: {
        id:'uiMessagesLinkTitle',
        defaultMessage: 'Messages',
        description: 'Title attribute for the messages link in the header',
    },
    uiMore: {
        id:'uiMore',
        defaultMessage: 'more',
        description: 'Button/link to expand a block of truncated text to show it in full.',
    },
    uiMoreInfo: {
        id:'uiMoreInfo',
        defaultMessage: 'More information',
        description: 'Label for the link that provides more information',
    },
    uiMyNotifications: {
        id:'uiMyNotifications',
        defaultMessage: 'Notifications',
        description: 'Text for the Notifications nav link.',
    },
    uiNavClasses: {
        id:'uiNavClasses',
        defaultMessage: 'Classes',
        description: 'Text for the Classes forums nav link.',
    },
    uiNavSubjects: {
        id:'uiNavSubjects',
        defaultMessage: 'Subjects',
        description: 'Text for the Subjects nav link.',
    },
    uiNavDiscussions: {
        id:'uiNavDiscussions',
        defaultMessage: 'Discussions',
        description: 'Text for the Discussion forums nav link.',
    },
    uiNavLibrary: {
        id:'uiNavLibrary',
        defaultMessage: 'Library',
        description: 'Text for the Library nav link.',
    },
    uiNavPeople: {
        id:'uiNavPeople',
        defaultMessage: 'People',
        description: 'Text for the People nav link.',
    },
    uiNavSearch: {
        id:'uiNavSearch',
        defaultMessage: 'Search {count}+ learning resources',
        description: 'Placeholder text for the Search box.',
    },
    uiNavUiDemos: {
        id:'uiNavUiDemos',
        defaultMessage: 'UI Demos',
        description: '(for development only)',
    },
    uiNavSearchButton: {
        id:'uiNavSearchButton',
        defaultMessage: 'Search',
        description: 'Label for the search button in the header',
    },
    uiNavMyClasses: {
        id:'uiNavMyClasses',
        defaultMessage: 'My classes',
        description: 'Text for the my classes nav link.',
    },
    uiNavMyContent: {
        id:'uiNavMyContent',
        defaultMessage: 'My content',
        description: 'Text for the my content nav link.',
    },
    uiNavCreateContent: {
        id:'uiNavCreateContent',
        defaultMessage: 'Create',
        description: 'Text for the create content nav link.',
    },
    uiNavCurriculum: {
        id: 'uiNavCurriculum',
        defaultMessage: 'Curriculum',
        description: 'Text for the Curriculum nav link.',
    },
    uiNavNewTag: {
        id:'uiNavNewTag',
        defaultMessage: 'New',
        description: 'Text for the New tag nav link.',
    },
    uiNavCurriculumAria: {
        id:'uiNavCurriculumAria',
        defaultMessage: '{curriculum} Curriculum',
        description: 'Curriculum aria label',
    },
    uiNext: {
        id:'uiNext',
        defaultMessage: 'Next',
        description: 'Label for a button that moves the user to the next item',
    },
    uiOk: {
        id:'uiOk',
        defaultMessage: 'OK',
        description: 'Label for an OK button to agree/dismiss an alert on the screen',
    },
    uiNotificationsButtonTitle: {
        id:'uiNotificationsButtonTitle',
        defaultMessage: 'Notifications',
        description: 'Title attribute for the notifications button in the header',
    },
    uiNotificationsSR: {
        id:'uiNotificationsSR',
        defaultMessage: 'You have {count, number} {count, plural, one {notification} other {notifications}}.',
        description: 'Screen reader text for notifications icon',
    },
    uiProfileDropdown: {
        id:'uiProfileDropdown',
        defaultMessage: 'Profile dropdown',
        description: 'Title for the user profile menu',
    },
    uiPrevious: {
        id:'uiPrevious',
        defaultMessage: 'Previous',
        description: 'Label for a button that moves the user to the previou item',
    },
    uiSave: {
        id:'uiSave',
        defaultMessage: 'Save',
        description: 'A button to save changes',
    },
    uiShowLess: {
        id:'uiShowLess',
        defaultMessage: 'Show less',
        description: 'Button/link to toggle visibility of a block of content',
    },
    uiShowMore: {
        id:'uiShowMore',
        defaultMessage: 'Show more',
        description: 'Button/link to toggle visibility of a block of content',
    },
    uiSidebarLoginMessage: {
        id: 'uiSidebarLoginMessage',
        defaultMessage: 'Welcome back,',
        description: 'Welcome message to display in sidebar on mobile.  The user\'s name is displayed under this message.',
    },
    uiSignIn: {
        id:'uiSignIn',
        defaultMessage: 'Log in',
        description: 'Text for sign in buttons.',
    },
    uiSignUp: {
        id:'uiSignUp',
        defaultMessage: 'Sign up',
        description: 'Text for sign up buttons.',
    },
    uiSuccess: {
        id:'uiSuccess',
        defaultMessage: 'Success',
        description: 'Title text shown when displaying a success message to the user.',
    },
    uiViewProfile: {
        id:'uiViewProfile',
        defaultMessage: 'View profile',
        description: 'Text for button that links to user profile.',
    },
    uiMyProfile: {
        id:'uiMyProfile',
        defaultMessage: 'My profile',
        description: 'Text for my Profile link in user menu in header.',
    },
    uiBookmarks: {
        id:'uiBookmarks',
        defaultMessage: 'Bookmarks',
        description: 'Text for Bookmarks link in user menu in header.',
    },
    uiSettings: {
        id: 'uiSettings',
        defaultMessage: 'Account settings',
        description: 'Text for Settings link in user menu in header.',
    },
    uiWarning: {
        id:'uiWarning',
        defaultMessage: 'Warning',
        description: 'Title text shown when displaying a warning to the user.',
    },
    uiConfirmationButton: {
        id:'uiConfirmationButton',
        defaultMessage: 'Confirm',
        description: 'Confirmation button.',
    },
    uiCancelButton: {
        id:'uiCancelButton',
        defaultMessage: 'Cancel',
        description: 'Cancel button.',
    },
    uiAddButton: {
        id:'uiAddButton',
        defaultMessage: 'Add item',
        description: 'Title of the Add Item card button.',
    },
    uiSelectButton: {
        id:'uiSelectButton',
        defaultMessage: 'Select',
        description: 'Title of the Select button',
    },
    uiUnselectButton: {
        id:'uiUnselectButton',
        defaultMessage: 'Unselect',
        description: 'Title of the unselect button',
    },
    uiCopyrightAgreement: {
        id:'uiCopyrightAgreement',
        defaultMessage: 'I agree to the LabXchange {termsOfService} and {privacyPolicy}.',
        description: 'Text for copyright agreement',
    },
    loginStatusError: {
        id: 'loginStatusError',
        defaultMessage: 'Error updating login status.',
        description: 'Error message when an unknown error is seen when requesting logged in status.',
    },
    loginClassTitle: {
        id:'loginClassTitle',
        defaultMessage: 'Account required',
        description: 'Title text for the login modal',
    },
    uiUnlimited: {
        id: 'uiUnlimited',
        defaultMessage: 'Unlimited',
        description: 'Label for an option choice when a limit is not set (eg. number of attempts: 1, 2, ..., Unlimited)',
    },
    uiAccountRequiredMessage: {
        id: 'uiAccountRequiredMessage',
        defaultMessage: 'You need to log in or create an account to continue. Would you like to do that now?',
        description: 'Message shown to the user when they need to login'
    },
    transcriptEditorTranscriptsSectionLabel: {
        id:'transcriptEditorTranscriptsSectionLabel',
        defaultMessage: 'Transcripts',
        description: 'Label for the "Transcripts" section of the video edit page',
    },
    uiEdit: {
        id: 'uiEdit',
        defaultMessage: 'Edit',
        description: 'label on a button to edit something.',
    },
    uiDone: {
        id: 'uiDone',
        defaultMessage: 'Done',
        description: 'label on a button to complete something.',
    },
    uiDefaultAnswerCorrectToggle: {
        id: 'annotationEditorQuestionCorrectSwitchLabel', /// I used this id to get the previous translations
        defaultMessage: 'Correct',
        description: 'Default label for the correct part of an answer toggle',
    },
    uiDefaultAnswerIncorrectToggle: {
        id: 'annotationEditorQuestionWrongSwitchLabel', /// I used this id to get the previous translations
        defaultMessage: 'Incorrect',
        description: 'Default label for the incorrect part of an answer toggle',
    },
    uiDefaultToggleLabel: {
        id: 'uiDefaultToggleLabel',
        defaultMessage: 'Correct/Incorrect Toggle',
        description: 'Default label for the incorrect part of an answer toggle',
    },
    uiToggleStatus: {
        id: 'uiToggleStatus',
        defaultMessage: '{type} set to {status}',
        description: 'Default label for the incorrect part of an answer toggle',
    },
    feedbackToggleOnState: {
        id:'feedbackToggleOnState',
        defaultMessage: 'Show',
        description: 'Label for the toggle to show the feedback fields',
    },
    feedbackToggleOffState: {
        id:'feedbackToggleOffState',
        defaultMessage: 'Hidden',
        description: 'Label for the toggle to show the feedback fields',
    },
    searchButtonLabel: {
        id:'searchButtonLabel',
        defaultMessage: 'Search',
        description: 'Search button (accessible caption for screen readers)',
    },
    clearSearchQueryFromSearchBar: {
        id:'clearSearchQuery',
        defaultMessage: 'Clear search query',
        description: 'Clear icon in search bar to clear search query.',
    },
    hamburgerMenuAriaLabel: {
        id:'hamburgerMenuAriaLabel',
        defaultMessage: 'Hamburger menu ',
        description: 'Aria label text for hamburger menu button which is shown on smaller screens.',
    },
    uiNavEducatorsDropdownLabel: {
        id:'uiNavEducatorsDropdownLabel',
        defaultMessage: 'Educators',
        description: 'Text for educators dropdown in navbar.',
    },
    uiNavEducatorsDropdownExplore: {
        id:'uiNavEducatorsDropdownExplore',
        defaultMessage: 'Explore',
        description: 'Text for explore item of educators dropdown in navbar.',
    },
    uiNavEducatorsDropdownMyClasses: {
        id:'uiNavEducatorsDropdownMyClasses',
        defaultMessage: 'My classes',
        description: 'Text for my classes item of educators dropdown in navbar.',
    },
    uiNavEducatorsDropdownMyContent: {
        id:'uiNavEducatorsDropdownMyContent',
        defaultMessage: 'My content',
        description: 'Text for my content item of educators dropdown in navbar.',
    },
    uiNavEducatorsDropdownCreate: {
        id:'uiNavEducatorsDropdownCreate',
        defaultMessage: 'Create',
        description: 'Text for create item of educators dropdown in navbar.',
    },
    uiNavEducatorsDropdownExploreContent: {
        id:'uiNavEducatorsDropdownExploreContent',
        defaultMessage: 'Explore content',
        description: 'Text for explore content item of educators dropdown in navbar.',
    },
    uiNavEducatorsDropdownCreateClass: {
        id:'uiNavEducatorsDropdownCreateClass',
        defaultMessage: 'Create a class',
        description: 'Text for create a class item of educators dropdown in navbar.',
    },
    uiNavEducatorsDropdownCreateContent: {
        id:'uiNavEducatorsDropdownCreateContent',
        defaultMessage: 'Create content',
        description: 'Text for create content item of educators dropdown in navbar.',
    },
    uiNavLearnersDropdownLabel: {
        id:'uiNavLearnersDropdownLabel',
        defaultMessage: 'Learners',
        description: 'Text for learners dropdown in navbar.',
    },
    uiNavLearnersDropdownMyClasses: {
        id:'uiNavLearnersDropdownMyClasses',
        defaultMessage: 'My classes',
        description: 'Text for my classes item of learners dropdown in navbar.',
    },
    uiNavLearnersDropdownMyContent: {
        id:'uiNavLearnersDropdownMyContent',
        defaultMessage: 'My content',
        description: 'Text for my content item of learners dropdown in navbar.',
    },
    uiNavLearnersDropdownJoinClass: {
        id:'uiNavLearnersDropdownJoinClass',
        defaultMessage: 'Join class',
        description: 'Text for join class item of learners dropdown in navbar.',
    },
    uiNavLearnersDropdownCreateContent: {
        id:'uiNavLearnersDropdownCreateContent',
        defaultMessage: 'Create content',
        description: 'Text for create content item of learners dropdown in navbar.',
    },
    uiNavLearnersDropdownJoinAClass: {
        id:'uiNavLearnersDropdownJoinAClass',
        defaultMessage: 'Join a class',
        description: 'Text for join a class item of learners dropdown in navbar.',
    },
    freeAccountCreateMessage: {
        id:'freeAccountCreateMessage',
        defaultMessage: 'create a free account!',
        description: 'Text used for the "sign up" link in the anonymousUserWarning',
    },
    educatorResourcesFreeAccountCreateHeading: {
        id:'educatorResourcesFreeAccountCreateHeading',
        defaultMessage: 'Sign up to access free educator resources!',
        description: 'Text used for the "sign up" modal heading in the free educator locked resources',
    },
    educatorResourcesFreeAccountCreateMessage: {
        id:'educatorResourcesFreeAccountCreateMessage',
        defaultMessage: 'This teaching content is intended for educators. Please log in or sign up for a free educator account to access it.',
        description: 'Text used for the "sign up" modal messages in the free educator locked resources',
    },
    kebabMenuArialLabel: {
        id:'kebabMenuArialLabel',
        defaultMessage: 'Kebab menu button',
        description: 'Screen reader label for the kebab menu button',
    },
    feedbackModalTitle: {
        id:'feedbackModalTitle',
        defaultMessage: 'Feedback',
        description: 'Title of the feedback modal',
    },
    feedbackButtonDefaultLabel: {
        id:'feedbackButtonLabel',
        defaultMessage: 'Give feedback',
        description: 'Label for the asset feedback button',
    },
    feedbackButtonSubmittedLabel: {
        id:'feedbackButtonSubmittedLabel',
        defaultMessage: 'Feedback submitted',
        description: 'Label for the asset feedback button when feedback has been submitted',
    },
    feedbackModalYesBtnLabel: {
        id:'feedbackModalYesBtnLabel',
        defaultMessage: 'Yes',
        description: 'Yes button in the feedback modal',
    },
    feedbackModalSomewhatBtnLabel: {
        id:'feedbackModalSomewhatBtnLabel',
        defaultMessage: 'Somewhat',
        description: 'Somewhat button in the feedback modal',
    },
    feedbackModalNoBtnLabel: {
        id:'feedbackModalNoBtnLabel',
        defaultMessage: 'No',
        description: 'No button in the feedback modal',
    },
    feedbackModalSubmitBtnLabel: {
        id:'feedbackModalSubmitBtnLabel',
        defaultMessage: 'Submit',
        description: 'Submit button in the feedback modal',
    },
    feedbackModalTellUsMoreLabel: {
        id:'feedbackModalTellUsMoreLabel',
        defaultMessage: 'Tell us more',
        description: 'Label for the text area in the feedback modal',
    },
    feedbackModalCoreConceptsLabel: {
        id:'feedbackModalCoreConceptsLabel',
        defaultMessage: 'core concepts',
        description: 'Label for the core concepts text used in the question of feedback modal',
    },
    feedbackModalIsContentUsefulQuestion: {
        id:'feedbackModalIsContentUsefulQuestion',
        defaultMessage: 'Do you find this content useful?',
        description: 'Question in the feedback modal',
    },
    feedbackModalIsContentUsefulToCoreConceptsQuestion: {
        id:'feedbackModalIsContentUsefulToCoreConceptsQuestion',
        defaultMessage: 'Do you find this content useful for the {coreConceptsHeading} listed on this page?',
        description: 'Question in the feedback modal',
    },
    feedbackModalSubjectGradeQuestion: {
        id:'feedbackModalSubjectGradeQuestion',
        defaultMessage: 'What subject & grade would you use it for?',
        description: 'Question in the feedback modal',
    },
    feedbackModalCurriculumQuestion: {
        id:'feedbackModalCurriculumQuestion',
        defaultMessage: 'Where does this content fit in the curriculum?',
        description: 'Question in the feedback modal',
    },
    feedbackModalSubjectDropdownPlaceholder: {
        id:'feedbackModalSubjectDropdownPlaceholder',
        defaultMessage: 'Subject',
        description: 'Placeholder for the subject dropdown in the feedback modal',
    },
    feedbackModalSubjectDropdownAria: {
        id:'feedbackModalSubjectDropdownAria',
        defaultMessage: 'Subject Dropdown',
        description: 'Aria label for the subject dropdown in the feedback modal',
    },
    feedbackModalGradeDropdownPlaceholder: {
        id:'feedbackModalGradeDropdownPlaceholder',
        defaultMessage: 'Grade',
        description: 'Placeholder for the grade dropdown in the feedback modal',
    },
    feedbackModalGradeDropdownAria: {
        id:'feedbackModalGradeDropdownAria',
        defaultMessage: 'Grade Dropdown',
        description: 'Aria label for the grade dropdown in the feedback modal',
    },
    feedbackModalPODropdownPlaceholder: {
        id:'feedbackModalPODropdownPlaceholder',
        defaultMessage: 'Select performance objectives',
        description: 'Label for the performance objectives dropdown',
    },
    feedbackModalPODropdownSearchPlaceholder: {
        id:'feedbackModalPODropdownSearchPlaceholder',
        defaultMessage: 'Search',
        description: 'Label for inline search within performance expectations dropdown',
    },
    feedbackModalWhy: {
        id:'feedbackModalWhy',
        defaultMessage: 'Why?',
        description: 'Label for the why question in the feedback modal',
    },
    feedbackModalWhyPlaceholder: {
        id:'feedbackModalWhyPlaceholder',
        defaultMessage: 'Select one or more',
        description: 'Placeholder for the why question in the feedback modal',
    },
    feedbackModalPleaseTellUsMoreLabel: {
        id:'feedbackModalPleaseTellUsMoreLabel',
        defaultMessage: 'Please tell us more',
        description: 'Label for the text area in the feedback modal',
    },
    feedbackModalPleaseTellUsMorOptionalLabel: {
        id:'feedbackModalPleaseTellUsMorOptionalLabel',
        defaultMessage: 'Please tell us more (optional)',
        description: 'Label for the text area in useful content section of the feedback modal',
    },
    feedbackModalErrorCurriculumDetails: {
        id:'feedbackModalErrorCurriculumDetails',
        defaultMessage: 'Error fetching curriculum details',
        description: 'Error message for when the curriculum details cannot be fetched',
    },
    feedbackModalErrorModules: {
        id:'feedbackModalErrorModules',
        defaultMessage: 'Error fetching modules',
        description: 'Error message for when the modules cannot be fetched',
    },
    feedbackModalErrorFeedback: {
        id:'feedbackModalErrorFeedback',
        defaultMessage: 'Error fetching feedback',
        description: 'Error message for when the feedback cannot be fetched',
    },
    feedbackModalAlreadySubmitted: {
        id:'feedbackModalAlreadySubmitted',
        defaultMessage: 'You have already submitted feedback for this content.',
        description: 'Feedback already submitted message',
    },
    feedbackModalResubmitBtnLabel: {
        id:'feedbackModalResubmitBtnLabel',
        defaultMessage: 'Resubmit',
        description: 'Resubmit button in the feedback modal',
    },
    feedbackModalSelectedPOs: {
        id:'feedbackModalSelectedPOs',
        defaultMessage: '{count} selected',
        description: 'Label for the selected performance objectives in the feedback modal',
    },
    navigationMenuAriaLabel: {
        id:'navigationMenuAriaLabel',
        defaultMessage: 'Navigation menu',
        description: 'Text for adding aria label to navigation menu ',
    },
});

export default messages;
