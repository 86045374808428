// tslint:disable
/**
 * LXBackend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime';
/**
 * 
 * @export
 * @interface CareerSubject
 */
export interface CareerSubject {
    /**
     * 
     * @type {number}
     * @memberof CareerSubject
     */
    readonly id?: number;
    /**
     * 
     * @type {string}
     * @memberof CareerSubject
     */
    name: string;
}

export function CareerSubjectFromJSON(json: any): CareerSubject {
    return {
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': json['name'],
    };
}

export function CareerSubjectToJSON(value?: CareerSubject): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'name': value.name,
    };
}


