// tslint:disable
/**
 * LXBackend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime';
import {
    CareerProfile,
    CareerProfileFromJSON,
    CareerProfileToJSON,
    UserAward,
    UserAwardFromJSON,
    UserAwardToJSON,
    UserEducation,
    UserEducationFromJSON,
    UserEducationToJSON,
    UserMentorshipProfile,
    UserMentorshipProfileFromJSON,
    UserMentorshipProfileToJSON,
    UserProfession,
    UserProfessionFromJSON,
    UserProfessionToJSON,
    UserSocialLink,
    UserSocialLinkFromJSON,
    UserSocialLinkToJSON,
} from './';

/**
 * 
 * @export
 * @interface Profile
 */
export interface Profile {
    /**
     * 
     * @type {Array<UserAward>}
     * @memberof Profile
     */
    awards?: Array<UserAward>;
    /**
     * 
     * @type {string}
     * @memberof Profile
     */
    bio: string;
    /**
     * 
     * @type {string}
     * @memberof Profile
     */
    city?: string;
    /**
     * 
     * @type {string}
     * @memberof Profile
     */
    country?: string;
    /**
     * 
     * @type {Date}
     * @memberof Profile
     */
    dateOfBirth?: Date;
    /**
     * 
     * @type {boolean}
     * @memberof Profile
     */
    detailsVisible?: boolean;
    /**
     * 
     * @type {Array<UserEducation>}
     * @memberof Profile
     */
    education?: Array<UserEducation>;
    /**
     * 
     * @type {boolean}
     * @memberof Profile
     */
    experienceVisible?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Profile
     */
    fullName: string;
    /**
     * 
     * @type {string}
     * @memberof Profile
     */
    gender?: string;
    /**
     * 
     * @type {string}
     * @memberof Profile
     */
    institution?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof Profile
     */
    subjects?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof Profile
     */
    areaOfStudy?: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof Profile
     */
    interestsVisible?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Profile
     */
    jobTitle?: string;
    /**
     * 
     * @type {string}
     * @memberof Profile
     */
    levelOfEducation?: string;
    /**
     * 
     * @type {UserMentorshipProfile}
     * @memberof Profile
     */
    mentorship?: UserMentorshipProfile;
    /**
     * 
     * @type {boolean}
     * @memberof Profile
     */
    directMessagesEnabled?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Profile
     */
    includeInSearchResults?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Profile
     */
    onboardingStatus?: ProfileOnboardingStatusEnum;
    /**
     * 
     * @type {Array<UserProfession>}
     * @memberof Profile
     */
    profession?: Array<UserProfession>;
    /**
     * 
     * @type {string}
     * @memberof Profile
     */
    profileVisibility?: ProfileProfileVisibilityEnum;
    /**
     * 
     * @type {string}
     * @memberof Profile
     */
    publicAvatarUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof Profile
     */
    role: ProfileRoleEnum;
    /**
     * 
     * @type {string}
     * @memberof Profile
     */
    schoolLevel?: string;
    /**
     * 
     * @type {string}
     * @memberof Profile
     */
    schoolName?: string;
    /**
     * 
     * @type {Array<UserSocialLink>}
     * @memberof Profile
     */
    socialLinks?: Array<UserSocialLink>;
    /**
     * 
     * @type {boolean}
     * @memberof Profile
     */
    socialLinksVisible?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Profile
     */
    username: string;
    /**
     * 
     * @type {CareerProfile}
     * @memberof Profile
     */
    careerProfile?: CareerProfile;
}

export function ProfileFromJSON(json: any): Profile {
    return {
        'awards': !exists(json, 'awards') ? undefined : (json['awards'] as Array<any>).map(UserAwardFromJSON),
        'bio': json['bio'],
        'city': !exists(json, 'city') ? undefined : json['city'],
        'country': !exists(json, 'country') ? undefined : json['country'],
        'dateOfBirth': !exists(json, 'date_of_birth') ? undefined : new Date(json['date_of_birth']),
        'detailsVisible': !exists(json, 'details_visible') ? undefined : json['details_visible'],
        'education': !exists(json, 'education') ? undefined : (json['education'] as Array<any>).map(UserEducationFromJSON),
        'experienceVisible': !exists(json, 'experience_visible') ? undefined : json['experience_visible'],
        'fullName': json['full_name'],
        'gender': !exists(json, 'gender') ? undefined : json['gender'],
        'institution': !exists(json, 'institution') ? undefined : json['institution'],
        'subjects': !exists(json, 'subjects') ? undefined : json['subjects'],
        'areaOfStudy': !exists(json, 'area_of_study') ? undefined : json['area_of_study'],
        'interestsVisible': !exists(json, 'interests_visible') ? undefined : json['interests_visible'],
        'jobTitle': !exists(json, 'job_title') ? undefined : json['job_title'],
        'levelOfEducation': !exists(json, 'level_of_education') ? undefined : json['level_of_education'],
        'mentorship': !exists(json, 'mentorship') ? undefined : UserMentorshipProfileFromJSON(json['mentorship']),
        'directMessagesEnabled': !exists(json, 'direct_messages_enabled') ? undefined : json['direct_messages_enabled'],
        'includeInSearchResults': !exists(json, 'include_in_search_results') ? undefined : json['include_in_search_results'],
        'onboardingStatus': !exists(json, 'onboarding_status') ? undefined : json['onboarding_status'],
        'profession': !exists(json, 'profession') ? undefined : (json['profession'] as Array<any>).map(UserProfessionFromJSON),
        'profileVisibility': !exists(json, 'profile_visibility') ? undefined : json['profile_visibility'],
        'publicAvatarUrl': !exists(json, 'public_avatar_url') ? undefined : json['public_avatar_url'],
        'role': json['role'],
        'schoolLevel': !exists(json, 'school_level') ? undefined : json['school_level'],
        'schoolName': !exists(json, 'school_name') ? undefined : json['school_name'],
        'socialLinks': !exists(json, 'social_links') ? undefined : (json['social_links'] as Array<any>).map(UserSocialLinkFromJSON),
        'socialLinksVisible': !exists(json, 'social_links_visible') ? undefined : json['social_links_visible'],
        'username': json['username'],
        'careerProfile': !exists(json, 'career_profile') ? undefined : CareerProfileFromJSON(json['career_profile']),
    };
}

export function ProfileToJSON(value?: Profile): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'awards': value.awards === undefined ? undefined : (value.awards as Array<any>).map(UserAwardToJSON),
        'bio': value.bio,
        'city': value.city,
        'country': value.country,
        'date_of_birth': value.dateOfBirth === undefined ? undefined : value.dateOfBirth.toISOString().substr(0,10),
        'details_visible': value.detailsVisible,
        'education': value.education === undefined ? undefined : (value.education as Array<any>).map(UserEducationToJSON),
        'experience_visible': value.experienceVisible,
        'full_name': value.fullName,
        'gender': value.gender,
        'institution': value.institution,
        'subjects': value.subjects,
        'area_of_study': value.areaOfStudy,
        'interests_visible': value.interestsVisible,
        'job_title': value.jobTitle,
        'level_of_education': value.levelOfEducation,
        'mentorship': UserMentorshipProfileToJSON(value.mentorship),
        'direct_messages_enabled': value.directMessagesEnabled,
        'include_in_search_results': value.includeInSearchResults,
        'onboarding_status': value.onboardingStatus,
        'profession': value.profession === undefined ? undefined : (value.profession as Array<any>).map(UserProfessionToJSON),
        'profile_visibility': value.profileVisibility,
        'public_avatar_url': value.publicAvatarUrl,
        'role': value.role,
        'school_level': value.schoolLevel,
        'school_name': value.schoolName,
        'social_links': value.socialLinks === undefined ? undefined : (value.socialLinks as Array<any>).map(UserSocialLinkToJSON),
        'social_links_visible': value.socialLinksVisible,
        'username': value.username,
        'career_profile': CareerProfileToJSON(value.careerProfile),
    };
}

/**
* @export
* @enum {string}
*/
export enum ProfileOnboardingStatusEnum {
    StepOne = 'step_one',
    Completed = 'completed'
}
/**
* @export
* @enum {string}
*/
export enum ProfileProfileVisibilityEnum {
    Everyone = 'everyone',
    LabxchangeUsers = 'labxchange_users',
    NoOne = 'no_one'
}
/**
* @export
* @enum {string}
*/
export enum ProfileRoleEnum {
    Educator = 'educator',
    Learner = 'learner'
}


