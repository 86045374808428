import React, { useState } from 'react';
import { Icon } from '../../../../elements';
import { useLayoutSize, WrappedMessage } from '../../../../utils';
import messages from '../../../../career-explorer/displayMessages';
import classNames from 'classnames';
import PulseCard from '../../PulseCard';

interface CareerPronunciationProps {
    pronunciation: string;
    pronunciationAudio?: string;
    hasNotVideoOrImage?: boolean;
}

const CareerPronunciation: React.FC<CareerPronunciationProps> = ({
 pronunciation,
 pronunciationAudio,
 hasNotVideoOrImage=false,
}) => {
    const [isAudioPlaying, setIsAudioPlaying] = useState(false);
    const isMobile = ['small', 'mobile'].includes(useLayoutSize());

    const toggleAudio = () => {
        if (pronunciationAudio) {
            const audio = new Audio(pronunciationAudio);
            if (!isAudioPlaying) {
                audio.play();
                setIsAudioPlaying(true);
                audio.onended = () => setIsAudioPlaying(false);
            }
        }
    };

    if (!pronunciation) return;

    return (
        <PulseCard
        className={classNames('pronunciation-container')}
        onClick={toggleAudio}
        >
            <button className='pronunciation' data-testid='pronunciation-button'>
                <div className='pronunciation-icon'>
                <Icon name={'volume-up'} fill={'white'} zoom='2em' />
                </div>
                <div className='pronunciation-description'>
                <span className='pronunciation-region'>
                    <WrappedMessage message={messages.careerPronunciationAmericanText} />
                </span>
                <span className='pronunciation-text'>{pronunciation}</span>
                </div>
            </button>
        </PulseCard>
    );
};

export default CareerPronunciation;
