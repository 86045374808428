import update from 'immutability-helper';

import * as LoginActions from 'auth/actions';
import { LoginStatusModel, notLoggedInStatus } from './models';
import { getInitialLoginState } from './utils';

const initialState: LoginStatusModel = getInitialLoginState();

/**
 * Reducer that maintains the state of the user (is the user logged in, etc.)
 */
export function loginStateReducer(state = initialState, action: LoginActions.ActionTypes): LoginStatusModel {
    switch (action.type) {
        case LoginActions.Types.LOGIN_SUCCESS:
            return update(state, {
                loginErrorMessage: {$set: null},
                name: {$set: action.name},
                token: {$set: action.token},
                email: {$set: action.email},
                username: {$set: action.username},
                avatarUrl: {$set: action.avatarUrl},
                permissions: {$set: action.permissions},
                onboardingStatus: {$set: action.onboardingStatus},
                dateOfBirth: {$set: action.dateOfBirth},
                role: {$set: action.role},
                cookieConsent: {$set: action.cookieConsent},
                hasVerifiedEmail: {$set: action.hasVerifiedEmail},
                needsAuthMigration: {$set: action.needsAuthMigration},
                hasClass: {$set: action.hasClass},
                curriculumGroup: {$set: action.curriculumGroup},
                careerProfile: {$set: action.careerProfile},
            });
        case LoginActions.Types.LOGIN_FAILURE:
            return update(state, {
                loginErrorMessage: {$set: action.errorMessage},
                email: {$set: ''},
                name: {$set: ''},
                token: {$set: ''},
                username: {$set: ''},
                hasVerifiedEmail: {$set: false},
                needsAuthMigration: {$set: false},
                hasClass: {$set: false},
            });
        case LoginActions.Types.LOGOUT_SUCCESS:
            return notLoggedInStatus;
        default:
            return state;
    }
}
