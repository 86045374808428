import React from 'react';
import Skeleton from 'react-loading-skeleton';

import ModuleAssetCardSkeleton from './ModuleSlabs/ModuleAssetCard/ModuleAssetCardSkeleton';

export const CurriculumModulePageSkeleton = () => (
  <div
    className='curriculum-module-page-skeleton'
    data-testid='curriculum-module-page-skeleton'
  >
    <div className='cmp-header-skeleton'>
      <Skeleton height={30} width={200} />
      <Skeleton height={45} width='100%' />
    </div>
    <div className='cmp-pill-buttons-skeleton'>
      {Array.from({ length: 5 }).map((_, index) => (
        <Skeleton key={index} height={30} width={150} />
      ))}
      <Skeleton height={30} width={30} circle />
    </div>
    <div className='cmp-slab-skeleton'>
      <Skeleton className='cmp-slab-skeleton-heading' />
      <Skeleton className='cmp-slab-skeleton-subheading' />
      <div className='cmp-slab-skeleton-cards'>
        {Array.from({ length: 4 }).map(() => (
          <ModuleAssetCardSkeleton />
        ))}
      </div>
    </div>
  </div>
);

export default CurriculumModulePageSkeleton;
