import * as React from 'react';
import { Link } from 'react-router-dom';
import { intl } from 'i18n';

import {
    ItemResponse,
    ItemMetadataTypeEnum,
    PathwayDetail,
    ChildItem,
    OrganizationSummaryOrganizationTypeEnum,
    ItemMetadata,
    Module,
} from 'labxchange-client';
import { getItemTypeMeta } from 'items/models';
import { ItemIcon } from 'library/components';
import { UserAvatar } from 'user/components/UserAvatar';
import { WrappedMessage } from 'utils';
import { Icon, Button, FeedbackModalModulePage } from 'ui/components';
import { ROUTES } from 'global/constants';
import uiMessages from 'ui/components/displayMessages';

import messages from './displayMessages';
import classNames from 'classnames';

export interface PathwayCardProps {
    pathway: ItemResponse;
    pathwayDetails?: PathwayDetail;
    onClone(pathwayId: string): void;
    onView(pathwayId: string): void;
    pathwayIndex?: number;
    showExploreButton?: boolean;
    onClickExploreButton?: (pathway: ItemMetadata) => void;
    onClickPathwayImage?: (pathway: ItemMetadata) => void;
    onClickPathwayItem?: (item: ItemMetadata, pathwayId: string) => void;
    showFeedbackButton?: boolean;
    curriculumSlug?: string;
    module?: Module;
    feedbackSubmitted?: boolean;
    onFeedbackSubmit?: (pathwayId: string) => void;
}

interface PathwayCardState {
    showFeedbackModal: boolean;
}

const ITEMS_TO_RENDER = 3;

export class PathwayCard extends React.PureComponent<PathwayCardProps, PathwayCardState> {
    constructor(props: PathwayCardProps) {
        super(props);
        this.state = {
            showFeedbackModal: false,
        };
    }

    private renderItemTypeInfo(item: ChildItem) {
        if (true) {
            return (
                <div className='media-info'>
                    <ItemIcon itemType={item.item.metadata.type} zoom='14'/>
                    <span>
                        <WrappedMessage
                            message={getItemTypeMeta(item.item.metadata.type).name}
                            values={{count: item.item.metadata.itemCount || 0}}
                        />
                    </span>
                </div>
            );
        }
    }

    private renderItem(item: ChildItem, index: number) {
        const pathwayId = this.props.pathway.metadata.id;
        const itemId = item.item.metadata.id;
        const relId = item.item.metadata.relId;

        const itemContent = (
            <>
                <span className='item-title'>
                {item.item.metadata.title}
                </span>
                <div className='media-info-wrapper'>
                    {this.renderItemTypeInfo(item)}
                </div>
            </>
        );

        if (this.props.onClickPathwayItem) {
            return (
                <div
                    key={index}
                    className='pathway-content-row'
                    onClick={() => this.props.onClickPathwayItem?.(item.item.metadata, pathwayId)}
                >
                    {itemContent}
                </div>
            );
        }

        return (
            <Link
                to={ROUTES.Library.PATHWAY_ITEM_SLUG(pathwayId, itemId, relId)}
                key={index}
                className='pathway-content-row'
            >
                {itemContent}
            </Link>
        );
    }

    private renderEmptyItem(index: number) {
        return (
            <div key={index} className='pathway-content-row hidden'>
                <span className='item-title'>
                    Filler text
                </span>
                <div className='media-info-wrapper'>
                    <div className='media-info'>
                        <ItemIcon itemType={ItemMetadataTypeEnum.Simulation} zoom='14'/>
                        <span>
                            <WrappedMessage
                                message={getItemTypeMeta(ItemMetadataTypeEnum.Simulation).name}
                            />
                        </span>
                </div>
                </div>
            </div>
        );
    }

    private renderItemsCards(items: ChildItem[]) {
        const itemsToRender = items.slice(0, ITEMS_TO_RENDER);
        const emptyItemsCount = ITEMS_TO_RENDER - itemsToRender.length;
        const finalItems = [...itemsToRender, ...Array(emptyItemsCount).fill(null)];

        return finalItems.map((item, index) => {
            if (item) {
                return this.renderItem(item, index);
            } else {
                return this.renderEmptyItem(index);
            }
        });
    }

    private getMoreCount(totalItems: number): number {
        const itemsNotRendered = totalItems - ITEMS_TO_RENDER;
        return itemsNotRendered > 0 ? itemsNotRendered : 0;
    }

    private handleFeedbackClick = (e: React.MouseEvent) => {
        e.stopPropagation();
        e.preventDefault();
        this.setState({ showFeedbackModal: true });
    }

    private handleModalClick = (e: React.MouseEvent) => {
        e.stopPropagation();
        e.preventDefault();
    }

    private handleFeedbackSubmit = () => {
        if (this.props.onFeedbackSubmit) {
            this.props.onFeedbackSubmit(this.props.pathway.metadata.id);
        }
    }

    private renderFeedbackButtonModal = () => {
        if (!this.props.curriculumSlug || !this.props.module) return null;

        const pathway = this.props.pathway;
        const IsFeedbackSubmitted = this.props.feedbackSubmitted || pathway.userAttributes.hasUserSubmittedFeedback;
        return (
            <div key={pathway.metadata.id} className={classNames('module-asset-feedback-btn',
                { 'feedback-submitted': IsFeedbackSubmitted }
            )}>
            <button className='unstyled' onClick={this.handleFeedbackClick} >
                <Icon name={IsFeedbackSubmitted ? 'check' : 'thumbs-up-fab'} zoom='20' />
                <span>
                    {intl.formatMessage(IsFeedbackSubmitted ? uiMessages.feedbackButtonSubmittedLabel : uiMessages.feedbackButtonDefaultLabel)}
                </span>
            </button>
            {this.state.showFeedbackModal &&
                <div className='feedback-modal-wrapper' onClick={this.handleModalClick}>
                <FeedbackModalModulePage
                    assetId={pathway.metadata.id}
                    curriculumSlug={this.props.curriculumSlug}
                    onClose={() => this.setState({ showFeedbackModal: false })}
                    onSubmit={this.handleFeedbackSubmit}
                    module={this.props.module.id}
                    coreConceptsHeading={this.props.module.learningObjectivesHeading.toLowerCase()}
                />
                </div>
            }
            </div>
        );
    }

    public render() {
        const pathway = this.props.pathway.metadata;
        let sourceOrg = null;
        let orgIsPartner = false;
        let orgUrl = '#';
        const moreCount = this.props.pathwayDetails ?
            this.getMoreCount(this.props.pathwayDetails.items.length) : 0;

        if (pathway.source && pathway.source.sourceOrganizations) {
            sourceOrg = pathway.source.sourceOrganizations[0].organization;
            if (
                sourceOrg.organizationType ===
                  OrganizationSummaryOrganizationTypeEnum.SPONSOR ||
                sourceOrg.organizationType ===
                  OrganizationSummaryOrganizationTypeEnum.PARTNER
            ) {
                orgUrl = ROUTES.Organizations.PROFILE_SLUG(sourceOrg.slug);
                orgIsPartner = true;
            } else if (sourceOrg.url) {
                orgUrl = sourceOrg.url;
                orgIsPartner = false;
            }
        }

        const providerLink = (content: any) => {
            if (orgIsPartner) {
                return (
                    <Link to={orgUrl} className='item-source-provider-url' data-testid='partner-link'>
                        {content}
                    </Link>
                );
            } else {
                return (
                    <a
                        href={orgUrl}
                        target='_blank'
                        rel='noopener noreferrer'
                        className='item-source-provider-url'
                        data-testid='external-link'
                    >
                        {content}
                    </a>
                );
            }
        };

        const leftSectionContent = (
            <div className='left-section-content'>
                <div className='pathway-title'>
                    <h3>{pathway.title}</h3>
                </div>
                {/* Disabling linter for next line because the div is used to stop event propagation */}
                {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
                <div className='provider-info' onClick={(e) => e.stopPropagation()}>
                    {sourceOrg ?
                        providerLink(
                            <>
                                <img
                                    className='pathway-note-avatar rounded-circle'
                                    src={sourceOrg.logoUrl}
                                    style={{backgroundColor: sourceOrg.brandColor}}
                                    alt='Provider logo'
                                />
                                <h4>{sourceOrg.name}</h4>
                            </>
                        )
                        : pathway.authors.length > 0 && pathway.authors[0].username ?
                            <div className='item-source-provider-url'>
                                <UserAvatar
                                    username={pathway.authors[0].username}
                                    diameter='36px'
                                />
                                <h4>{pathway.authors[0].fullName}</h4>
                            </div>
                        : null
                    }
                </div>
            </div>
        );

        return (
            <div className={classNames('pathway-card', { 'positive-margin':this.props.pathwayIndex === 0 })}>
                {this.props.onClickPathwayImage ?
                    <div
                        className='left-section'
                        style={{ backgroundImage: `url('${pathway.imageUrl}')` }}
                        onClick={() => this.props.onClickPathwayImage?.(pathway)}
                        data-testid='pathway-image'
                    >
                        {leftSectionContent}
                    </div>
                    :
                    <Link
                        to={ROUTES.Library.PATHWAY_SLUG(pathway.id)}
                        className='left-section'
                        style={{ backgroundImage: `url('${pathway.imageUrl}')` }}
                        data-testid='pathway-image'
                    >
                        {leftSectionContent}
                    </Link>
                }
                {this.props.pathwayDetails ?
                    <div className='right-section'>
                        <div className='right-section-content'>
                            <h4><WrappedMessage message={messages.pathwayCardsCovers}/></h4>
                            {this.renderItemsCards(this.props.pathwayDetails.items)}
                            <div className={moreCount > 0 ? 'more-count' : 'more-count transparent'}>
                                <span>+{moreCount} more</span>
                            </div>
                            <div className={classNames('action-buttons',
                                { 'feedback-button': this.props.showFeedbackButton }
                            )}>
                                {this.props.showExploreButton ?
                                    <Button
                                        className='explore-button'
                                        btnStyle='primary'
                                        label={messages.pathwayCardExploreButton}
                                        onClick={() => {
                                            if (this.props.onClickExploreButton) {
                                                this.props.onClickExploreButton(pathway);
                                            }
                                        }}
                                    />
                                    :
                                    <>
                                        <Button
                                            className='clone-button'
                                            btnStyle='outline'
                                            label={messages.pathwayEducatorCloneEditButton}
                                            onClick={() => this.props.onClone(pathway.id)}
                                        />
                                        <Button
                                            className='view-button'
                                            btnStyle='primary'
                                            label={messages.pathwayEducatorViewButton}
                                            onClick={() => this.props.onView(pathway.id)}
                                        />
                                    </>
                                }
                                {this.props.showFeedbackButton && this.renderFeedbackButtonModal()}
                            </div>
                        </div>
                    </div>
                : null}
            </div>
        );
    }
}
