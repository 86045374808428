// tslint:disable
/**
 * LXBackend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime';
import {
    SubSkill,
    SubSkillFromJSON,
    SubSkillToJSON,
} from './';

/**
 * 
 * @export
 * @interface Skill
 */
export interface Skill {
    /**
     * 
     * @type {number}
     * @memberof Skill
     */
    readonly id?: number;
    /**
     * The title of the parent skill.
     * @type {string}
     * @memberof Skill
     */
    title: string;
    /**
     * Description of the parent skill.
     * @type {string}
     * @memberof Skill
     */
    description: string;
    /**
     * Long Description of the parent skill.
     * @type {string}
     * @memberof Skill
     */
    longDescription?: string;
    /**
     * A URL link to an asset (pathway) related to the skill.
     * @type {string}
     * @memberof Skill
     */
    assetLink?: string;
    /**
     * 
     * @type {Array<SubSkill>}
     * @memberof Skill
     */
    subskills: Array<SubSkill>;
}

export function SkillFromJSON(json: any): Skill {
    return {
        'id': !exists(json, 'id') ? undefined : json['id'],
        'title': json['title'],
        'description': json['description'],
        'longDescription': !exists(json, 'long_description') ? undefined : json['long_description'],
        'assetLink': !exists(json, 'asset_link') ? undefined : json['asset_link'],
        'subskills': (json['subskills'] as Array<any>).map(SubSkillFromJSON),
    };
}

export function SkillToJSON(value?: Skill): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'title': value.title,
        'description': value.description,
        'long_description': value.longDescription,
        'asset_link': value.assetLink,
        'subskills': (value.subskills as Array<any>).map(SubSkillToJSON),
    };
}


