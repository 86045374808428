import React, { Suspense } from 'react';

const VALID_COUNTRY_CODES = ['in', 'ke', 'lr', 'ng', 'za', 'us'] as const;

export type CountryMapSymbol = typeof VALID_COUNTRY_CODES[number];

export interface CountryMapProps {
    countryName: string;
    countryCode: CountryMapSymbol;
    fill?: string; // Fill color for the icon; defaults to 'currentColor'
}

/**
 * An inline SVG of the given country's map.
 * Use as `<CountryMap name='us' fill='#484848'/>`
 */

export const CountryMap: React.FC<CountryMapProps> = (props) => {
    const { countryCode, countryName, fill } = props;
    const isValidCountryCode = VALID_COUNTRY_CODES.includes(countryCode as CountryMapSymbol);
    if (!isValidCountryCode) {
        // tslint:disable: no-console
        console.error(`SVG not found for country code '${countryCode}'`);
    }

    const code = isValidCountryCode ? countryCode : 'blank';

    const MapIcon = React.lazy(() =>
        import(`./country-map-svgs/${code}.svg?react`)
    );

    return (
        <Suspense fallback={<div></div>}>
            <MapIcon fill={fill || 'currentColor'} data-testid={`MapIcon-${code}`}>
                <title>{countryName}'s map</title>
            </MapIcon>
        </Suspense>
    );
};
