// tslint:disable
/**
 * LXBackend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime';
/**
 * 
 * @export
 * @interface CareerAppliedFilter
 */
export interface CareerAppliedFilter {
    /**
     * 
     * @type {string}
     * @memberof CareerAppliedFilter
     */
    filterOn: CareerAppliedFilterFilterOnEnum;
    /**
     * 
     * @type {Array<number>}
     * @memberof CareerAppliedFilter
     */
    filterValues: Array<number>;
}

export function CareerAppliedFilterFromJSON(json: any): CareerAppliedFilter {
    return {
        'filterOn': json['filter_on'],
        'filterValues': json['filter_values'],
    };
}

export function CareerAppliedFilterToJSON(value?: CareerAppliedFilter): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'filter_on': value.filterOn,
        'filter_values': value.filterValues,
    };
}

/**
* @export
* @enum {string}
*/
export enum CareerAppliedFilterFilterOnEnum {
    MinimumQualification = 'MinimumQualification'
}


