import React, {useEffect, useState} from 'react';
import OnboardingPageLayout from './OnboardingPageLayout/OnboardingPageLayout';
import { intl } from 'i18n';
import messages from './displayMessages';
import CheckboxCard from '../CheckboxCard/CheckboxCard';
import { ImpactOption, InspirationOption } from 'labxchange-client';

export interface DriverStepProps {
    currentStep: number;
    selectedImpacts?: ImpactOption[];
    selectedInspirations?: InspirationOption[];
    onButtonClick: (nextStep: number) => void;
    impactOptions?: ImpactOption[];
    toggleImpacts?: (option: ImpactOption) => void;
    inspirationOptions?: InspirationOption[];
    toggleInspirations?: (option: InspirationOption) => void;
}

export const DriverAndImpactStep: React.FC<DriverStepProps> = ({
    currentStep,
    selectedImpacts,
    selectedInspirations,
    onButtonClick,
    impactOptions,
    toggleImpacts,
    inspirationOptions,
    toggleInspirations,
}) => {
    const renderOptions = impactOptions || inspirationOptions;
    const selectedOptions = selectedImpacts || selectedInspirations;
    const isDriverScreen = !impactOptions;
    const title = isDriverScreen ? intl.formatMessage(messages.careerDriverOnboardingStepTitle)
        : intl.formatMessage(messages.careerImpactOnboardingStepTitle);
    const description = isDriverScreen ? intl.formatMessage(messages.careerDriverOnboardingStepDescription)
        : intl.formatMessage(messages.careerImpactOnboardingStepDescription);

    const toggleOption = (option: any) => {

        if (toggleImpacts) {
            toggleImpacts(option);
        }

        if (toggleInspirations) {
            toggleInspirations(option);
        }
    };

    return (
        <OnboardingPageLayout
            title={title}
            description={description}
            currentStep={currentStep}
            onButtonClick={onButtonClick}
            showContinueAction={selectedOptions && selectedOptions.length > 0}
        >
            {renderOptions?.map((option, i) =>
                <CheckboxCard
                    key={option.id}
                    title={option.title}
                    description={option.description}
                    checked={selectedOptions !== undefined && selectedOptions.includes(option.id)}
                    toggleCheckbox={() => toggleOption(option.id)}
                />
            )}
        </OnboardingPageLayout>
    );
};

export default DriverAndImpactStep;
