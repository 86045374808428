import * as React from 'react';
import Skeleton from 'react-loading-skeleton';

export class PathwayCardSkeleton extends React.PureComponent {
    public render() {
        return (
            <div className='pathway-card-skeleton' data-testid='pathway-card-skeleton'>
                <div className='left-section-skeleton'>
                  <div className='pathway-title-skeleton'>
                    <Skeleton height={43} />
                  </div>
                  <div className='provider-info-skeleton'>
                    <Skeleton circle={true} width={44} height={44} />
                    <Skeleton className='provider-name' width={268} height={36} />
                  </div>
                </div>
                <div className='right-section-skeleton'>
                  <div className='right-sec-content-skeleton'>
                    <Skeleton width={268} height={26} />
                    <Skeleton className='row-item-skeleton' height={52} />
                    <Skeleton className='row-item-skeleton' height={52} />
                    <Skeleton className='row-item-skeleton' height={52} />
                    <div className='more-info-skeleton'>
                      <Skeleton width={107} height={24} />
                    </div>
                    <div className='action-buttons-skeleton'>
                      <Skeleton className='first-button' height={52} />
                      <Skeleton height={52} />
                    </div>
                  </div>
                </div>
            </div>
        );
    }
}
