import React, { memo, useMemo } from 'react';

import { ModuleSlab, SectionSlab, ItemMetadata, Module } from 'labxchange-client';
import { PathwaysCarousel } from 'home/components/TeachPage';

import { convertSlabToFeaturedItems } from './utils';

interface PathwaysSlabProps {
  heading: string;
  slab: SectionSlab | ModuleSlab;
  showExploreButton?: boolean;
  subHeading?: string;
  onClickExploreButton?: (pathway: ItemMetadata) => void;
  onClickPathwayImage?: (pathway: ItemMetadata) => void;
  onClickPathwayItem?: (item: ItemMetadata, pathwayId: string) => void;
  showFeedbackButton?: boolean;
  curriculumSlug?: string;
  module?: Module;
}

export const PathwaysSlab: React.FC<PathwaysSlabProps> = memo((props) => {
  return (
    <div className='pathways-container section pathway-section scroll-section'>
      <h1 className='slab-title'>{props.heading}</h1>
      {props.subHeading && <span className='slab-subheading'>{props.subHeading}</span>}
      <PathwaysCarousel
        // Need to memoize the pathways data to prevent unnecessary re-renders
        pathways={useMemo(() => convertSlabToFeaturedItems(props.slab), [props.slab])}
        showExploreButton={props.showExploreButton}
        onClickExploreButton={props.onClickExploreButton}
        onClickPathwayImage={props.onClickPathwayImage}
        onClickPathwayItem={props.onClickPathwayItem}
        showFeedbackButton={props.showFeedbackButton}
        curriculumSlug={props.curriculumSlug}
        module={props.module}
      />
    </div>
  );
});

export default memo(PathwaysSlab);
