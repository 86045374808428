// tslint:disable
/**
 * LXBackend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime';
/**
 * 
 * @export
 * @interface CareerQualification
 */
export interface CareerQualification {
    /**
     * 
     * @type {number}
     * @memberof CareerQualification
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof CareerQualification
     */
    qualificationName: string;
    /**
     * 
     * @type {number}
     * @memberof CareerQualification
     */
    qualificationRank: number;
}

export function CareerQualificationFromJSON(json: any): CareerQualification {
    return {
        'id': json['id'],
        'qualificationName': json['qualification_name'],
        'qualificationRank': json['qualification_rank'],
    };
}

export function CareerQualificationToJSON(value?: CareerQualification): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'id': value.id,
        'qualification_name': value.qualificationName,
        'qualification_rank': value.qualificationRank,
    };
}


