import * as React from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useLocation } from 'react-router-dom';
import { useAnalytics } from 'use-analytics';

import { PageData } from 'analytics';
import { analyticsInstance } from './analytics';
import { EVENT_NAMES } from './constants';

// Interval between calls to save the time spend on page
// in milliseconds.
const TIME_SPENT_TRACKING_INTERVAL = 30 * 1000;

type Props = {
    children?: React.ReactNode;
};

/**
 * AnalyticsGateway
 *
 * This is a passthrough component that allows measuring and
 * reporting page metrics back to the backend.
 */
export const AnalyticsGateway = ({ children }: Props) => {
    const location = useLocation();
    const analytics = useAnalytics();

    // Unique `page visit id`, used to update the analytics
    // row with the time spent on page.
    const [pageId, setPageId] = React.useState(uuidv4());
    // Previous page URL, stored to compare with current page and
    // detect if the user navigated to a new page.
    const [previousLocation, setPreviousLocation] = React.useState(
        window.location.toString()
    );
    // Events are fired on page changes, but not on the first load.
    // This is used to fix that edge case.
    const [firstPageLoad, setFirstPageLoad] = React.useState(true);
    // Last total time sent - used to avoid unecessary
    // requests when the time hasn't moved.
    const [lastTimeSent, setLastTimeSent] = React.useState<number|undefined>(undefined);

    /**
     * Timer implementation, used to track time spent on each page.
     *
     * This timer uses two state variables to track time:
     * 1. `total`: total amount of time elapsed since the timer was reset.
     * 2. `tsStart`: amount of time elapsed since the timer was started.
     *
     * The timer is started and paused depending on user actions:
     * 1. If the user switches away for the tab, the timer is stopped and
     *    any amount stored on `tsStart` is added to `total`. When
     *    switching back, it'll start the timer again with `tsStart` starting
     *    from 0.
     * 2. When a user navigates to a different page, the timer is stopped,
     *    the time spent on page is sent to the backend. Then the times is
     *    reset and starts tracking time for the new page.
     *
     * Time tracking events are also send to the backend every
     * `TIME_SPENT_TRACKING_INTERVAL` milliseconds, so that we can
     * track user time even if they close the browser (enabling us to
     * track the last page visited).
     *
     * Note: If this timer implementation needs to be used elsewhere, it can be
     * abstracted into a custom react hook.
     *
     */
    const [total, setTotal] = React.useState(0);
    const [tsStart, setTsStart] = React.useState<undefined|number>(Date.now());

    /**
     * Start tracking time if a timer isn't already running.
     */
    const startTimer = () => {
        if (tsStart === undefined) {
            setTsStart(Date.now());
        }
    };

    /**
     * Stop timer, and add elapsed time to total, then reset counter.
     */
    const stopTimer = () => {
        if (tsStart !== undefined) {
            setTotal(total + (Date.now() - tsStart));
            setTsStart(undefined);
        }
    };

    /**
     * Callback to send total time spent events to the warehouse
     * and session_active event to GA4. Events are only sent if
     * the time spent on page has changed (i.e. user was not on
     * another tab since last update events were sent)
     *
     * This also sums time on `tsStart` if there's any
     * timer currently running.
     */
    const sendTimeSpentEvent = (pageData: PageData = {}) => {
        // Total time spent will be stored in the
        // variable plus the value of any currently
        // running timer
        let totalTime = total;
        if (tsStart) {
            totalTime += Date.now() - tsStart;
        }
        // Don't send request if the timer still
        // has the same value as in last request.
        if (totalTime === lastTimeSent) {
            return;
        }
        // If URL exists in page data, pick that.
        const url = pageData?.url || window.location.toString();
        analytics.page({
            eventData: {
                uuid: pageId,
                // Seconds spent on page, truncated for simplicity.
                number: Math.trunc(totalTime/1000),
            },
            path: new URL(url).pathname,
            url,
        });

        // According to https://support.google.com/analytics/answer/9964640#sessions
        // Google Analytics 4 session metrics are derived from the session_start event, an automatically
        // collected event. The duration of a session is based on the time span between the first and last
        // event in the session.
        // We send these heartbeat events to Google Analytics so it can track the time spent on pages.
        // If a user visit a page, spends time reading or watching it but does not visit further pages in
        // that tab, the time spent may not get tracked without these heartbeats.
        if (typeof (window as any).gtag !== 'undefined') {
            (window as any).gtag('event', 'session_active');
        }

        setLastTimeSent(totalTime);
    };

    /**
     * Page listeners to trigger timer and tracking events.
     */
    React.useEffect(() => {
        // Attach analytics plugin that watches tab activity
        // and starts/stops timer as needed.
        const removeTabHidden = analytics.on('tabHidden', stopTimer);
        const removeTabVisible = analytics.on('tabVisible', startTimer);

        // Sent time spent event to backend periodically.
        const heartbeatIntervalId = setInterval(
            () => sendTimeSpentEvent(), TIME_SPENT_TRACKING_INTERVAL
        );

        // Cleanup listeners once component is unmounted
        return () => {
            removeTabHidden();
            removeTabVisible();
            clearTimeout(heartbeatIntervalId);
        };
    }, [total, tsStart, stopTimer, startTimer]);

    /**
     * Listener waiting for page change events.
     */
    React.useEffect(() => {
        const currentLocation = window.location.toString();
        const source = new URLSearchParams(location.search).get('source') || '';
        const previousUrl = previousLocation === currentLocation ? '' : previousLocation;

        // If this is the first page load, send `page viewed`
        // event immediately.
        if (firstPageLoad) {
            setFirstPageLoad(false);
            analytics.page({
                eventData: {
                    uuid: pageId,
                    number: 0,
                    previousUrl,
                },
                url: currentLocation,
            });

            // Check if the URL contains multiple `source` query strings
            // adding it here to make sure to send it only once on first page load
            if (source.includes('source')) {
                analyticsInstance.track(
                    EVENT_NAMES.QueryStringSourceDuplication,
                    {
                        url: currentLocation,
                        source,
                        previousUrl,
                    }
                );
            }
        }

        // If the page changed, then send time
        // spent on page event.
        if (currentLocation !== previousLocation) {
            // Send updated time spent event.
            // This uses the page state from analytics library to use the
            // properties of the last page event instead of this one, since this
            // is the last ping event that requires us access to previous event
            sendTimeSpentEvent(analytics.getState('page').last.properties);

            // Reset timer and other time-tracking attributes.
            const newPageUuid = uuidv4();
            setTotal(0);
            setTsStart(Date.now());
            setPreviousLocation(currentLocation);
            setPageId(newPageUuid);
            setLastTimeSent(undefined);

            // Send new page visit event.
            // Only triggered when switching pages, not on
            // the first page load.
            analytics.page({
                eventData: {
                    uuid: newPageUuid,
                    number: 0,
                    previousUrl: previousLocation,
                },
                url: currentLocation,
            });
        }
    }, [location, total, tsStart, previousLocation]);

    // Return empty component, this shouldn't render anything
    return null;
};
