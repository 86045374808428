import React from 'react';
import classNames from 'classnames';
import Skeleton from 'react-loading-skeleton';
import { useLayoutSize } from 'utils';

const CareerDetailsSkeleton = () => {
    const isMobile = ['small', 'mobile'].includes(useLayoutSize());
    const isMobileOrTablet = ['small', 'mobile', 'medium'].includes(useLayoutSize());

    return (
        <div className='career-details-home' data-testid='career-details-skeleton'>
            <header className='career-header visible'>
                <div className='header-content-container'>
                <h1 className='header-content-container__title'>
                    <Skeleton width={250} height={30} />
                </h1>
                <nav className='header-content-container__nav'>
                    <ul className='header-content-container__nav-list'>
                    {Array(isMobile ? 3 : 7).fill(7).map((_, index) => (
                        <li key={index} className='header-content-container__nav-item'>
                        <Skeleton width={100} height={20} />
                        </li>
                    ))}
                    </ul>
                </nav>
                </div>
            </header>
            <div className='career-details-section'>
                <div className='sub-section' id='introduction'>
                <div className='career-intro'>
                    <h2>
                        <Skeleton width={200} height={25} />
                    </h2>
                    <div className={classNames({'career-introduction-desktop': !isMobileOrTablet })}>
                        <div className='career-introduction text-content' style={{display: 'block'}}>
                            <p>
                                <Skeleton count={3} className='mb-1' />
                            </p>
                            <div className={classNames('pronunciation-container')}>
                                <Skeleton height={70} />
                            </div>
                        </div>
                        </div>
                        <div className='alternative-names'>
                        <h4 className='alternative-names-heading'>
                            <Skeleton width={150} height={20} />
                        </h4>
                        <p>
                            <Skeleton width={isMobile ? 300 : 700} height={20} />
                        </p>
                    </div>
                </div>
                </div>
                <div className='full-width-subsection full-width-subsection--overview' id='overview'>
                <div className='career-overview-section'>
                    <h2>
                    <Skeleton width={150} height={25} />
                    </h2>
                    <div className='career-overview-container'>
                    {Array(isMobile ? 1 : 6).fill(6).map((_, index) => (
                        <div key={index} className='pulse-card-container'>
                            <div className='pill'>
                                <div className='pill-content'>
                                    <div className='pill-info'>
                                        <div className='pill-title'>
                                            <div className='pill-info-subtitle'>
                                                <Skeleton width={120} height={20} className='mb-1' />
                                            </div>
                                            <div className='pill-info-title'>
                                                <Skeleton width={80} height={20} className='mb-1' />
                                            </div>
                                        </div>
                                        <div>
                                            <Skeleton width={40} height={40} />
                                        </div>
                                    </div>
                                    <div className='pill-info-description'>
                                        <Skeleton width={200} height={15} count={2} className='mb-1' />
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                    </div>
                </div>
                </div>
            </div>
        </div>
    );
};

export default CareerDetailsSkeleton;
