import React, {useRef, useState, useEffect} from 'react';
import { ROUTES } from '../../../global/constants';
import { useHistory } from 'react-router-dom';
import { Icon } from '../../../elements';
import { useColor } from '../../ThemeProvider';
import classNames from 'classnames';
import { WrappedMessage } from '../../../utils';
import messages from '../../displayMessages';
import { CareerSearchResult } from 'labxchange-client';


interface CareerCardProps {
    career: CareerSearchResult;
    onClick?: () => void;  // Optional click handler
    showImage?: boolean;
}

const CareerCard: React.FC<CareerCardProps> = ({
 career,
 showImage,
}) => {
    const [isTruncated, setIsTruncated] = useState(false);
    const textRef = useRef<HTMLParagraphElement>(null);
    const { selectedColor } = useColor();
    const history = useHistory();
    const onClickCard = () => {
        if (career.isPublished) {
            history.push(ROUTES.CareerExplorer.CAREER_DETAILS_SLUG(career.slug));
        }
    };
    useEffect(() => {
        if (textRef.current) {
            const { scrollHeight, clientHeight } = textRef.current;
            if (scrollHeight > clientHeight) {
                setIsTruncated(true);
            }
        }
    }, []);

    return (
        <div
            className={classNames('career-card', {'career-card__list-view': showImage, [selectedColor.class]: !showImage})}
            onClick={onClickCard}
            data-testid='career-card'
        >
            {showImage &&
              <img
                className='career-card__career-image'
                alt='Career Image'
                src={career.icon || '/assets/images/career-explorer/career-card-view.svg'}
              />
            }
            {!showImage &&
              <div className={`career-card__icon-section ${selectedColor.class}`} data-testid='career-card__icon-section'>
                <Icon name='arrow-right' fill={selectedColor.iconColorFill}/>
              </div>
            }
            <div className='career-card__content' >
                <h2 className='career-card__content__title'>{career.title}</h2>
                <div className='truncate-wrapper'>
                    <p className='career-card__content__subtitle truncate-text' ref={textRef}>
                        {career.description}
                    </p>
                    {isTruncated && (
                        <span className='learn-more' onClick={onClickCard}>
                            ...
                            <span className='learn-more-text'>
                                <WrappedMessage message={messages.careerDescriptionLearnMore} /></span>
                        </span>
                    )}
                </div>


                <div className='career-card__content__more-info'>
                    <span className='career-card__content__more-info__pill'>
                        {career.minimumQualification.qualificationName}
                    </span>
                    <span className='career-card__content__more-info__pill'>
                        ${((career.entryLevelSalary || 0) + (career.seniorLevelSalary || 0)) /2}
                    </span>
                </div>
            </div>
        </div>
);
};

export default CareerCard;
