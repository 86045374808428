import React, { useState, useEffect } from 'react';
import ReactCountryFlag from 'react-country-flag';
import Select, { ValueType } from 'react-select';
import { intl } from 'i18n';
import { useHistory, Link } from 'react-router-dom';
import classNames from 'classnames';

import { CurriculumsApi } from 'global/api';
import { CurriculumDetail } from 'labxchange-client';
import { ROUTES } from 'global/constants';
import { Button, Spinner } from 'ui/components';
import { createSubjectGradeOptions } from 'curriculum/utils';
import { SelectDropdownOption } from 'curriculum/types';
import { analyticsInstance } from 'tracking';
import { EVENT_NAMES } from 'tracking/constants';

import messages from '../displayMessages';

interface CurriculumHeroSectionProps {
  curriculumSlug: string;
  imageSrc: string;
  countryCode?: string;
}

export const CurriculumHeroSection = ({
  curriculumSlug, imageSrc, countryCode
}: CurriculumHeroSectionProps) => {
  const history = useHistory();
  const [curriculum, setCurriculum] = useState<CurriculumDetail>();
  const [selectedSubject, setSelectedSubject] = useState<string>('');
  const [selectedGrade, setSelectedGrade] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const country = (curriculum?.country as any)?.code || countryCode;

  useEffect(() => {
    const fetchCurriculumData = async () => {
      if (!curriculumSlug) return;
      setLoading(true);
      try {
        const response: CurriculumDetail = await CurriculumsApi.getDetail({ id: curriculumSlug });
        const firstSubject = response.subjects?.[0];
        setCurriculum(response);
        setSelectedSubject(firstSubject.slug || '');
        setSelectedGrade(firstSubject.grades?.[0].slug || '');
      } catch (error) {
        setLoading(false);
        setCurriculum(undefined);
      }
      setLoading(false);
    };

    fetchCurriculumData();
  }, [curriculumSlug]);

  const {
    subjectOptArray: subjectOptions,
    gradeOptArray: gradeOptions
  } = createSubjectGradeOptions(curriculum?.subjects || [], selectedSubject);
  const subjectDefaultValue = subjectOptions.find(option => option.value === selectedSubject);
  const gradeDefaultValue = gradeOptions.find(option => option.value === selectedGrade);
  const shouldRenderDropdowns = subjectOptions.length > 0 && gradeOptions.length > 0;

  const onSubjectChange = (selectedOption: ValueType<SelectDropdownOption>) => {
    if (selectedOption) {
      const selectedSubjectValue = (selectedOption as SelectDropdownOption).value;
      setSelectedSubject(selectedSubjectValue);

      const availableGradeValue = curriculum?.subjects.find((s) => s.slug === selectedSubjectValue)?.grades?.[0].slug;
      setSelectedGrade(availableGradeValue as string);
    }
  };

  const onGradeChange = (selectedOption: ValueType<SelectDropdownOption>) => {
    if (selectedOption) {
      const selectedGradeValue = (selectedOption as SelectDropdownOption).value;
      setSelectedGrade(selectedGradeValue);
    }
  };

  const handleFindResourcesClick = () => {
    analyticsInstance.track(EVENT_NAMES.CurriculumHeroFindResourcesButtonClicked, {
      button_text: intl.formatMessage(messages.curriculumHeroFindResourcesButton),
      selected_grade: selectedGrade,
      selected_subject: selectedSubject,
      url: window.location.toString(),
      curriculum: curriculumSlug,
      country,
    });

    history.push({
      pathname: ROUTES.Curriculum.DETAIL_SLUG(curriculumSlug),
      search: selectedSubject && selectedGrade ?
        `?subject=${selectedSubject}&grade=${selectedGrade}` : ''
    });
  };

  return (
    <div className='curriculum-hero-section-home'>
        {loading ? (
            <div
              data-testid='spinner-wrapper'
              className='spinner-wrapper'
            >
              <Spinner />
            </div>
          ) :
          <>
            <div className='curriculum-details'>
              <ReactCountryFlag
                countryCode={country}
                style={{width: '68px', height: '100%', border: '2px solid #FFF', borderRadius: '5px'}}
                svg
              />
              <h1 className='curriculum-heading'>
                {intl.formatMessage(messages.curriculumHeroHeading, { curriculumName: curriculumSlug.toUpperCase() })}
              </h1>
              <span className='curriculum-subheading'>
                {intl.formatMessage(messages.curriculumHeroSubHeading)}
              </span>
              {shouldRenderDropdowns &&
                <div className='curriculum-dropdowns'>
                  <Select
                    tabIndex='0'
                    className='select-dropdown'
                    classNamePrefix='select'
                    options={subjectOptions}
                    onChange={onSubjectChange}
                    value={subjectDefaultValue}
                    isSearchable={false}
                    placeholder={intl.formatMessage(messages.curriculumHeroSubjectDropdownPlaceholder)}
                    aria-label={intl.formatMessage(messages.curriculumHeroSubjectDropdownAria)}
                  />
                  <Select
                    tabIndex='0'
                    className='select-dropdown'
                    classNamePrefix='select'
                    options={gradeOptions}
                    onChange={onGradeChange}
                    value={gradeDefaultValue}
                    isSearchable={false}
                    placeholder={intl.formatMessage(messages.curriculumHeroGradeDropdownPlaceholder)}
                    aria-label={intl.formatMessage(messages.curriculumHeroGradeDropdownAria)}
                  />
                </div>
              }
              <div className={classNames('find-resources-btn', { 'more-margin': !shouldRenderDropdowns })}>
                <Button
                  label={messages.curriculumHeroFindResourcesButton}
                  icon='arrow-right-thin'
                  iconPosition='right'
                  onClick={handleFindResourcesClick}
                />
              </div>
              <Link
                className='explore-curricula-link'
                to={ROUTES.Curriculum.HOME}
                onClick={() => analyticsInstance.track(EVENT_NAMES.CurriculumHeroExploreAllCurriculaButtonClicked, {
                  button_text: intl.formatMessage(messages.curriculumHeroExploreCurriculaLink),
                  url: window.location.toString(),
                  curriculum: curriculumSlug,
                  country,
                })}
              >
                {intl.formatMessage(messages.curriculumHeroExploreCurriculaLink)}
              </Link>
            </div>
            <div className='curriculum-image'>
              <img src={imageSrc} alt='Curriculum Hero' width={568} height={480} />
            </div>
          </>
        }
    </div>
  );
};

export default CurriculumHeroSection;
