// tslint:disable
/**
 * LXBackend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime';
/**
 * 
 * @export
 * @interface Qualification
 */
export interface Qualification {
    /**
     * 
     * @type {number}
     * @memberof Qualification
     */
    readonly id?: number;
    /**
     * 
     * @type {string}
     * @memberof Qualification
     */
    qualificationName: string;
    /**
     * This will be used to filter the qualifications in OpenSearch.
     * @type {number}
     * @memberof Qualification
     */
    qualificationRank?: number;
}

export function QualificationFromJSON(json: any): Qualification {
    return {
        'id': !exists(json, 'id') ? undefined : json['id'],
        'qualificationName': json['qualification_name'],
        'qualificationRank': !exists(json, 'qualification_rank') ? undefined : json['qualification_rank'],
    };
}

export function QualificationToJSON(value?: Qualification): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'qualification_name': value.qualificationName,
        'qualification_rank': value.qualificationRank,
    };
}


