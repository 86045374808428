import { defineMessages } from 'react-intl';

const messages = defineMessages({
    curriculumsPageHeaderTitle: {
        id:'curriculumsPageHeaderTitle',
        defaultMessage: 'Browse by curriculum',
        description: 'Header title for the curriculums list page',
    },
    curriculumsPageHeaderPill: {
        id:'curriculumsPageHeaderPill',
        defaultMessage: 'New!',
        description: 'Text for the header pill message',
    },
    curriculumsPageLoadingError: {
        id:'curriculumsPageLoadingError',
        defaultMessage: 'Encountered an error while loading curriculums',
        description: 'Error message when loading curriculums fails',
    },
    curriculumsPageSectionTitle: {
        id:'curriculumsPageSectionTitle',
        defaultMessage: 'Currently available curricula',
        description: 'Section title for listed curriculums on the page',
    },
    curriculumsPageSectionParagraph: {
        id:'curriculumsPageSectionParagraph',
        defaultMessage: 'Explore a diverse array of resources meticulously designed to meet the standards and objectives of your region\'s specific educational framework.',
        description: 'Section title for listed curriculums on the page',
    },
    curriculumsPageCardButtonText: {
        id:'curriculumsPageCardButtonText',
        defaultMessage: 'Explore curriculum',
        description: 'Button text for the curriculum card on curriclums list page',
    },
    curriculumsPageComingSoonText: {
        id:'curriculumsPageComingSoonText',
        defaultMessage: 'Coming soon!',
        description: 'Text for the coming soon section title on the curriculums page',
    },
    curriculumsPageRequestTitle: {
        id:'curriculumsPageRequestTitle',
        defaultMessage: 'Don\'t see your curriculum?',
        description: 'Text for the request curriculum section title on the curriculums page',
    },
    curriculumsPageRequestParagraphText: {
        id:'curriculumsPageRequestParagraphText',
        defaultMessage: 'Share your feedback and submit a request to add your country\'s curriculum to LabXchange.',
        description: 'Text for the request curriculum paragraph on the curriculums page',
    },
    curriculumsPageRequestButtonText: {
        id:'curriculumsPageRequestButtonText',
        defaultMessage: 'Request your curriculum',
        description: 'Text for the request curriculum button on the curriculums page',
    },
    curriculumModuleOverviewLink: {
        id:'curriculumModuleOverviewLink',
        defaultMessage: 'Overview',
        description: 'Link text for multiple curriculum learning objectives parent',
    },
    curriculumPageTitle: {
        id:'curriculumPageTitle',
        defaultMessage: '{name} - Curriculum overview',
        description: 'Heading for curriculum overview page',
    },
    tagContentPageHeaderTitle: {
        id:'tagContentPageHeaderTitle',
        defaultMessage: 'Curriculum Tagging',
        description: 'Header title for the tag content page',
    },
    tagContentTableTagBtnLabel: {
        id:'tagContentTableTagBtnLabel',
        defaultMessage: 'Tag',
        description: 'Label for the tag button in the tag content table',
    },
    tagContentTableReTagBtnLabel: {
        id:'tagContentTableReTagBtnLabel',
        defaultMessage: 'Re-tag',
        description: 'Label for the re-tag button in the tag content table',
    },
    tagContentTableTaggedStatusLabel: {
        id:'tagContentTableTaggedStatusLabel',
        defaultMessage: 'Tagged',
        description: 'Label for the tagged status in the tag content table',
    },
    tagContentTableNotTaggedStatusLabel: {
        id:'tagContentTableNotTaggedStatusLabel',
        defaultMessage: 'Not tagged',
        description: 'Label for the not tagged status in the tag content table',
    },
    tagContentSelectCurriculumAndSubject: {
        id:'tagContentSelectCurriculumAndSubject',
        defaultMessage: 'Please select a curriculum and subject.',
        description: 'Message when no curriculum and subject is selected',
    },
    tagContentNoItemsFound: {
        id:'tagContentNoItemsFound',
        defaultMessage: 'No items found for this curriculum and subject.',
        description: 'Message when no items are found for the selected curriculum and subject',
    },
    tagContentTableAssetTitle: {
        id:'tagContentTableAssetTitle',
        defaultMessage: 'Title',
        description: 'Table header for asset title',
    },
    tagContentTableTagContent: {
        id:'tagContentTableTagContent',
        defaultMessage: 'Tag Content',
        description: 'Table header for tag content',
    },
    tagContentTableStatus: {
        id:'tagContentTableStatus',
        defaultMessage: 'Status',
        description: 'Table header for status',
    },
    tagContentModalTitle: {
        id:'tagContentFeedbackModalTitle',
        defaultMessage: 'Tag content',
        description: 'Title for the feedback modal',
    },
    tagContentModalAssetLabel: {
        id:'tagContentModalAssetLabel',
        defaultMessage: 'Content',
        description: 'Label for the asset in the tag content modal',
    },
    tagContentModalCurriculumLabel: {
        id:'tagContentModalCurriculumLabel',
        defaultMessage: 'Curriculum',
        description: 'Label for the curriculum in the tag content modal',
    },
    tagContentCurriculumDropdownPlaceholder: {
        id:'tagContentCurriculumDropdownPlaceholder',
        defaultMessage: 'Select curriculum',
        description: 'Label for the curriculum dropdown',
    },
    tagContentCurriculumDropdownAria: {
        id:'tagContentCurriculumDropdownAria',
        defaultMessage: 'Curriculum Dropdown',
        description: 'Aria label for the curriculum dropdown',
    },
    tagContentModalSubjectLabel: {
        id:'tagContentModalSubjectLabel',
        defaultMessage: 'Subject',
        description: 'Label for the subject in the tag content modal',
    },
    tagContentSubjectDropdownPlaceholder: {
        id:'tagContentSubjectDropdownPlaceholder',
        defaultMessage: 'Select subject',
        description: 'Label for the subject dropdown',
    },
    tagContentSubjectDropdownAria: {
        id:'tagContentSubjectDropdownAria',
        defaultMessage: 'Subject Dropdown',
        description: 'Aria label for the subject dropdown',
    },
    tagContentModalIsRelevantQuestion: {
        id:'tagContentModalIsRelevantQuestion',
        defaultMessage: 'Is this content relevant?',
        description: 'Question for the tag content modal',
    },
    tagContentModalYesBtnLabel: {
        id:'tagContentModalYesBtnLabel',
        defaultMessage: 'Yes',
        description: 'Label for the yes button in the tag content modal',
    },
    tagContentModalNoBtnLabel: {
        id:'tagContentModalNoBtnLabel',
        defaultMessage: 'No',
        description: 'Label for the no button in the tag content modal',
    },
    tagContentModalCurriculumQuestion: {
        id:'tagContentModalCurriculumQuestion',
        defaultMessage: 'Where does this content fit in the curriculum?',
        description: 'Question in the tag content modal',
    },
    tagContentModalPODropdownPlaceholder: {
        id:'tagContentModalPODropdownPlaceholder',
        defaultMessage: 'Select performance objectives',
        description: 'Label for the performance objectives dropdown',
    },
    tagContentModalPODropdownSearchPlaceholder: {
        id:'tagContentModalPODropdownSearchPlaceholder',
        defaultMessage: 'Search',
        description: 'Label for inline search within performance expectations dropdown',
    },
    tagContentModalSubmitBtnLabel: {
        id:'tagContentModalSubmitBtnLabel',
        defaultMessage: 'Submit',
        description: 'Label for the submit button in the tag content modal',
    },
    tagContentModalTellUsMoreOptionalLabel: {
        id:'tagContentModalTellUsMoreOptionalLabel',
        defaultMessage: 'Please tell us more (optional)',
        description: 'Label for the optional text area in the tag content modal',
    },
    tagContentModalTellUsMoreLabel: {
        id:'tagContentModalTellUsMoreLabel',
        defaultMessage: 'Please tell us more',
        description: 'Label for the optional text area in the tag content modal',
    },
    tagContentPageTotalAssets: {
        id:'tagContentPageTotalAssets',
        defaultMessage: 'Total content: {count}',
        description: 'Total assets count on the tag content page',
    },
    curriclumVersionsPageTitle: {
        id:'curriclumVersionsPageTitle',
        defaultMessage: 'Version Comparison: {moduleTitle}',
        description: 'Page title for the curriculum versions page',
    },
    curriclumVersionsDataLoadingError: {
        id:'curriclumVersionsDataLoadingError',
        defaultMessage: 'Unable to load versions data for this module',
        description: 'Error message for curriculum versions page',
    },
    curriclumVersionsPageFetchButton: {
        id:'curriclumVersionsPageFetchButton',
        defaultMessage: 'Fetch Versions',
        description: 'Button text to fetch the versions data',
    },
    curriclumVersionsPageNoItems: {
        id:'curriclumVersionsPageNoItems',
        defaultMessage: 'No items',
        description: 'Text to show in slabs when no items are available for the slab',
    },
    curriclumVersionsFirstVersionPlaceholder: {
        id:'curriclumVersionsFirstVersionPlaceholder',
        defaultMessage: 'Enter first version name',
        description: 'Placeholder for the text input for first version name',
    },
    curriclumVersionsSecondVersionPlaceholder: {
        id:'curriclumVersionsSecondVersionPlaceholder',
        defaultMessage: 'Enter second version name',
        description: 'Placeholder for the text input for second version name',
    },
    searchBarPlainDefaultPlaceholder: {
        id:'searchBarPlainDefaultPlaceholder',
        defaultMessage: 'Search something...',
        description: 'Default placeholder for the search bar',
    },
    curriculumDetailPageHeadline: {
        id:'curriculumDetailPageHeadline',
        defaultMessage: 'Find free interactive resources tailored to align with your next lesson.',
        description: 'Headline for the curriculum detail page',
    },
    curriculumDetailPageDefaultModuleHeading: {
        id:'curriculumDetailPageDefaultModuleHeading',
        defaultMessage: 'Module',
        description: 'Heading for default modules on the curriculum detail page',
    },
    curriculumDetailPageCAPSModuleHeading: {
        id:'curriculumDetailPageCAPSModuleHeading',
        defaultMessage: 'Module',
        description: 'Heading for CAPS modules on the curriculum detail page',
    },
    curriculumDetailPageNGSSModuleHeading: {
        id:'curriculumDetailPageNGSSModuleHeading',
        defaultMessage: 'Topic',
        description: 'Heading for NGSS modules on the curriculum detail page',
    },
    curriculumDetailPageNCERTModuleHeading: {
        id:'curriculumDetailPageNCERTModuleHeading',
        defaultMessage: 'Unit',
        description: 'Heading for NCERT modules on the curriculum detail page',
    },
    curriculumDetailPageErrorCurriculum: {
        id:'curriculumDetailPageErrorCurriculum',
        defaultMessage: 'Unable to load data for the provided curriculum.',
        description: 'Error message for curriculum detail page',
    },
    curriculumDetailPageErrorModules: {
        id:'curriculumDetailPageErrorModules',
        defaultMessage: 'Unable to load modules for the provided curriculum.',
        description: 'Error message for curriculum detail page',
    },
    curriculumDetailPageNoModules: {
        id: 'curriculumDetailPageNoModules',
        defaultMessage: 'No modules available for the selected options.',
        description: 'Error message for curriculum detail page',
    },
    curriculumDetailPageSelectSubjectGrade: {
        id: 'curriculumDetailPageSelectSubjectGrade',
        defaultMessage: 'Please select a subject and grade to view modules.',
        description: 'Error message for curriculum detail page',
    },
    curriculumSidebarSearchPlaceholder: {
        id: 'curriculumDetailPageSearchPlaceholder',
        defaultMessage: 'Search a topic...',
        description: 'Placeholder for search bar on curriculum detail page',
    },
    curriculumModulePageModuleError: {
        id: 'curriculumModulePageModuleError',
        defaultMessage: 'Unable to load data for the provided module.',
        description: 'Error message for curriculum module page',
    },
    curriculumSidebarCurriculumError: {
        id: 'curriculumModulePageCurriculumError',
        defaultMessage: 'Unable to load data for the provided curriculum.',
        description: 'Error message for curriculum module page',
    },
    curriculumEmptySearchResultsHeading: {
        id: 'curriculumEmptySearchResultsHeading',
        defaultMessage: 'Sorry, no results found',
        description: 'Empty search results message',
    },
    curriculumEmptySearchResultsSubHeading: {
        id: 'curriculumEmptySearchResultsSubHeading',
        defaultMessage: 'Try using different keywords.',
        description: 'Empty search results message',
    },
    curriculumEmptySearchResultsImageAlt: {
        id: 'curriculumEmptySearchResultsImageAlt',
        defaultMessage: 'Empty search results',
        description: 'Alt text for the empty search results image',
    },
    allPillButtonFilter: {
        id: 'allPillButtonFilter',
        defaultMessage: 'All',
        description: 'Filter option for all filters',
    },
    allPillButtonFilterAria: {
        id: 'allPillButtonFilterAria',
        defaultMessage: 'Show all content',
        description: 'Aria label for the all filter button',
    },
    pillButtonFilterAria: {
        id: 'pillButtonFilterAria',
        defaultMessage: 'Filter by {filter}',
        description: 'Aria label for the pill button filter',
    },
    interactivesSlabSubheading: {
        id: 'interactivesSlabSubheading',
        defaultMessage: 'Engaging lab simulations and animations designed to illustrate scientific phenomena effectively.',
        description: 'Subheading for the interactives slab',
    },
    videosSlabSubheading: {
        id: 'videosSlabSubheading',
        defaultMessage: 'High-quality videos, with annotations for added clarity, to support in-class teaching or independent student learning.',
        description: 'Subheading for the videos slab',
    },
    readingsSlabSubheading: {
        id: 'readingsSlabSubheading',
        defaultMessage: 'Informative texts designed to deepen understanding and complement your lessons.',
        description: 'Subheading for the readings slab',
    },
    questionsSlabSubheading: {
        id: 'questionsSlabSubheading',
        defaultMessage: 'Engaging questions and curated question sets to reinforce learning and assess student understanding.',
        description: 'Subheading for the questions slab',
    },
    pathwaysSlabSubheading: {
        id: 'pathwaysSlabSubheading',
        defaultMessage: 'Expertly curated learning pathways to streamline your lesson planning and guide student learning.',
        description: 'Subheading for the pathways slab',
    },
    completeLessonsSlabSubheading: {
        id: 'completeLessonsSlabSubheading',
        defaultMessage: 'Fully prepared lesson plans with all the materials you need to deliver engaging lessons.',
        description: 'Subheading for the complete lessons slab',
    },
    caseStudiesSlabSubheading: {
        id: 'caseStudiesSlabSubheading',
        defaultMessage: 'Real-world scenarios and examples to foster critical thinking and application of concepts.',
        description: 'Subheading for the case studies slab',
    },
    imagesSlabSubheading: {
        id: 'imagesSlabSubheading',
        defaultMessage: 'High-quality visuals to illustrate key concepts and enhance your teaching materials.',
        description: 'Subheading for the images slab',
    },
    closelyRelatedSlabHeading: {
        id: 'closelyRelatedSlabHeading',
        defaultMessage: 'Closely related topics',
        description: 'Heading for the closely related slab',
    },
    closelyRelatedSlabSubheading: {
        id: 'closelyRelatedSlabSubheading',
        defaultMessage: 'Explore topics similar to this one',
        description: 'Subheading for the closely related slab',
    },
    pillButtonFiltersToggleAria: {
        id: 'pillButtonFiltersToggleAria',
        defaultMessage: 'Show/hide all available filters',
        description: 'Aria label for pill button filters toggle',
    },
    curriculumSidebarToggle: {
        id: 'curriculumSidebarToggle',
        defaultMessage: 'Curriculum',
        description: 'Button toggle label for curriculum sidebar',
    },
    curriculumDetailSubjectDropdownPlaceholder: {
        id:'curriculumDetailSubjectDropdownPlaceholder',
        defaultMessage: 'Subject',
        description: 'Placeholder for the subject dropdown on the curriculum detail page',
    },
    curriculumDetailSubjectDropdownAria: {
        id:'curriculumDetailSubjectDropdownAria',
        defaultMessage: 'Subject Dropdown',
        description: 'Aria label for the subject dropdown on the curriculum detail page',
    },
    curriculumDetailGradeDropdownPlaceholder: {
        id:'curriculumDetailGradeDropdownPlaceholder',
        defaultMessage: 'Grade',
        description: 'Placeholder for the grade dropdown on the curriculum detail page',
    },
    curriculumDetailGradeDropdownAria: {
        id:'curriculumDetailGradeDropdownAria',
        defaultMessage: 'Grade Dropdown',
        description: 'Aria label for the grade dropdown on the curriculum detail page',
    },
    curriculumSidebarSubjectDropdownPlaceholder: {
        id:'curriculumSidebarSubjectDropdownPlaceholder',
        defaultMessage: 'Subject',
        description: 'Placeholder for the subject dropdown on the curriculum module page',
    },
    curriculumSidebarSubjectDropdownAria: {
        id:'curriculumSidebarSubjectDropdownAria',
        defaultMessage: 'Subject Dropdown',
        description: 'Aria label for the subject dropdown on the curriculum module page',
    },
    curriculumSidebarGradeDropdownPlaceholder: {
        id:'curriculumSidebarGradeDropdownPlaceholder',
        defaultMessage: 'Grade',
        description: 'Placeholder for the grade dropdown on the curriculum module page',
    },
    curriculumSidebarGradeDropdownAria: {
        id:'curriculumSidebarGradeDropdownAria',
        defaultMessage: 'Grade Dropdown',
        description: 'Aria label for the grade dropdown on the curriculum module page',
    },
});

export default messages;
